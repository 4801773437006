<script setup>

import { ref } from 'vue';

const props = defineProps({
    successCallback:{
        type: Function,
        required: true
    },
});

const newGroup = ref(null);
const parent_group = ref(null);

import { 
    createServerGroup,
} from "../../../api/services/OnboardingService.js";

const createGroup = async () => {
    const response = await createServerGroup(newGroup.value, parent_group.value.model);

    if(response.data.success == true) {
        props.successCallback(response.data.group_id)
    }
};

</script>


<template>
    <p-modal id="add_new_group">
        <div class="pa-panel pa-panel_xl">
            <div class="pa-panel-hd">
                <h2 class="pa-hdg pa-hdg_3 header-text">
                    Create New Group
                </h2>
            </div>
            <div class="pa-panel-bd">
                <ul class="pa-vList pa-vList_x6">
                    <li>
                        <div class="pa-field group-input">
                            <div class="pa-field-hd">
                                <label for="name" class="pa-label">Group Name *</label>
                            </div>
                            <div class="pa-field-bd">
                                <input
                                    class="pa-input"
                                    v-model="newGroup"
                                    id="name"
                                    type="text"
                                    style="display: flex; width: 60%;"
                                />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="pa-field">
                            <div class="pa-field-hd">
                                <label for="name" class="pa-label">Select Parent Group</label>
                            </div>
                            <div class="pa-field-bd" style="max-width: 372px; border: 1px solid #ddd; border-radius: 1px;padding-bottom: 20px;padding-left: 10px;padding-right: 10px; text-align: left;">
                                <p-tree
                                    ref="parent_group"
                                    url="/util/monitoring_tree?only_groups=True"
                                    :select_nodes="true"
                                ></p-tree>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="pa-panel-ft">
                <button type="button" class="pa-btn pa-btn_naked"  v-p-dialog-close target="add_new_group">Cancel</button>
                <button type="button" class="pa-btn" @click="createGroup">Create Group</button>
            </div>
        </div>
    </p-modal>
</template>



<style lang="scss" scoped>

</style>