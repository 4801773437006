<template>
    <span>{{ formattedTime }}</span>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment-timezone';
    import { mapStores } from "pinia";
    import { useUserStore } from "../../stores/userStore";

    const Time = Vue.extend({
        props: {
            isoString: String,
            unixTimestamp: Number,
            fromNow: Boolean,
            format: {
                type: String,
                'default': 'M/D/YYYY, h:mm:ss A z',
            },
        },

        computed: {
            ...mapStores(useUserStore),

            formattedTime() {
                let tz = this.userStore.timezone;
                moment.tz.setDefault(tz);

                let time = null;
                if (this.isoString) {
                    time = moment(this.isoString);
                } else if (this.unixTimestamp) {
                    time = moment.unix(this.unixTimestamp);
                } else {
                    return '';
                }

                if (this.fromNow) {
                    return time.fromNow(true);
                }

                return time.format(this.format);
            },
        },

        methods: {
        },
    });

    export default Time;
</script>
