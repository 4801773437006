<style lang="scss">
#onboarding-action-plan {
    width: 850px;
    margin: 0 auto;

    .onboarding-book-icon {
        border-radius: 2px;
        border: solid 1px #d5d8df;
        background-color: #f2f3f5;
    }

    .infra-type-logo-wrapper {
        border: 1px solid #b8bec9;
        height: 96px;
        width: 96px;
        border-radius: 2px;

        img, svg {
            width: 85px;
            height: 85px;
        }
    }
}
</style>

<template>
    <div id="onboarding-action-plan">

        <p-button
            @click="goToRoute('index')"
            variant="link"
            class="pa-txt_sm pa-pt-20"
        >
            <p-icon icon="chevron-left" color="blue-500" class="pa-mr-4"></p-icon>
            Back to onboarding task list
        </p-button>

        <p-card class="pa-p-40 pa-txt_sm pa-my-8">

            <h2 class="pa-txt_medium pa-txt_24 pa-pb-12">
                Action Plan
            </h2>

            <h3 class="pa-text-grey-800 pa-txt_16 pa-pb-16">
                We’ll guide you through the checklist below to get your monitoring up and running. Select an infrastructure type to configure and let’s get started!
            </h3>

            <h3 class="pa-text-grey-800 pa-txt_16 pa-pb-16">
                You can always access your onboarding progress, next tasks and other resources later from the <p-icon class="onboarding-book-icon pa-px-4" color="grey-800" icon="onboarding-book" size="xxxxl" middle></p-icon> icon within the navigation bar
            </h3>

            <div :key="infraType.title" v-for="infraType in selectedInfrastructureTypes">
                <p-divider></p-divider>
                
                <p-row class="pa-py-16 pa-pl-24 pa-pr-64" align-start>
                    <p-column cols="2">
                        <div class="infra-type-logo-wrapper pa-flex pa-p-8 pa-align-center pa-justify-center">
                            <img
                                v-if="infraType.logoType === 'image'"
                                :src="`/static/images/${infraType.logo}`"
                            />
                            <p-icon
                                v-else
                                :icon="infraType.logo"
                                no-base-class
                            >
                            </p-icon>
                        </div>
                    </p-column>
                    <p-column cols="10" class="pa-pl-24">
                        <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                            {{ infraType.title }}
                        </h4>
                        <p class="pa-txt_medium pa-text-grey-800 pa-mb-16">
                            {{ infraType.description }}
                        </p>
                        <div>
                            <p-button @click="goToRoute(infraType.id)">
                                Configure
                            </p-button>
                        </div>
                    </p-column>
                </p-row>

            </div>

            <p-divider></p-divider>

        </p-card>

    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        goToRoute: {
            type: Function,
            default: () => {},
        },
        selectedInfrastructureTypes: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({}),
    methods: {
        checkForSelectedInfra() {
            if (!this.selectedInfrastructureTypes || this.selectedInfrastructureTypes.length === 0) {
                this.goToRoute("index");
            }
        },
    },
    mounted() {
        this.checkForSelectedInfra();
    },
});
</script>
