<script setup>
import { ref } from 'vue';

const deleteInstances = ref(false);

const setDeleteInstances = (val) => {
    return deleteInstances.value = val;
};

const props = defineProps({
    singleSelectName: {
        type: String,
        default: "",
    },
});

defineExpose({ setDeleteInstances });

</script>


<template>
    <p-modal id="confirm_delete">
        <div class="pa-panel">
            <div class="pa-panel-hd">
                <h2 v-if="singleSelectName" class="pa-hdg pa-hdg_3" slot="header">Delete Integration {{ singleSelectName }}</h2>
                <h2 v-else class="pa-hdg pa-hdg_3" slot="header">Delete Integration(s)</h2>
            </div>
            <div class="pa-panel-bd" style="text-align: center;">
                <label class="pa-option">
                    <input type="checkbox" v-model="deleteInstances" class="pa-option-input" />
                    <span class="pa-option-icon"></span>
                    <span class="pa-option-txt">
                        Delete instances along with the integration?
                    </span>
                </label>
            </div>
            <div class="pa-panel-ft">
                <ul class="pa-split">
                    <li></li>
                    <li>
                        <button class="pa-btn pa-btn_cancel" type="button" @click="$emit('cancel-delete-modal')">Cancel</button>
                        <button @click="$emit('delete-infra-configs', deleteInstances)" class="pa-btn" value="Confirm">Confirm</button>
                    </li>
                </ul>
            </div>
        </div>
    </p-modal>
</template>

