import { fitLabel } from "./g6";

export class Node {
    constructor(data) {
        this.data = data;
        this.size = 40;
        this.radius = 20;
    }

    get isMonitored() {
        return Boolean(this.data.server_id);
    }

    get isUnidentifiable() {
        return (!this.data.host || this.data.host == "???") && !this.data.ip;
    }

    get isIdentifiable() {
        return !this.isUnidentifiable;
    }

    get hasIcon() {
        return Boolean(this.data.icon);
    }

    get iconPath() {
        if (!this.hasIcon) {
            return null;
        }

        return `/static/assets/newux/media/svgs/${this.data.icon}.svg`;
    }

    get iconCfg() {
        return {
            show: this.hasIcon,
            x: -((this.size / 2) / 2),
            y: -((this.size / 2) / 2),
            height: this.size / 2,
            width: this.size / 2,
            img: this.iconPath,
            opacity: this.opacity,
        };
    }

    get textIcon() {
        if (this.data.is_stacked_node) {
            return `+${this.data.weight}`;
        }
        else if (this.isUnidentifiable) {
            return "?";
        }
    }

    get textIconCfg() {
        return {
            text: this.textIcon,
            fill: '#394449',
            fontSize: this.size / 2,
            fontWeight: "bold",
            textAlign: 'center',
            textBaseline: 'middle',
            opacity: this.opacity,
            y: 1.5,
        };
    }

    get stroke() {
        if (this.data.has_incident) {
            return "#D43527";
        }

        return "#394449";
    }

    get fill() {
        return this.data.bgColor ? this.data.bgColor : "#FFFFFF";
    }

    get lineWidth() {
        if (this.data.has_incident) {
            return 3;
        }

        return 2;
    }

    get label() {
        if (this.hideLabel) {
            return "";
        }

        if (this.isUnidentifiable) {
            return "unidentifiable";
        }

        if (this.data.indegree === 0) {
            let label = this.data.host.split('.')[0];

            if (this.data.ip !== "") {
                label += `\n(${this.data.ip})`;
            }

            return fitLabel(label, 100, 12);
        }
        else if (this.data.is_target) {
            let label = this.data.host;
            if (this.data.ip !== "") {
                label += `\n(${this.data.ip})`;
            }
            return fitLabel(label, 100, 6);
        }
        else if (this.data.ip !== "") {
            return this.data.ip;
        } else if (this.data.host !== "") {
            return fitLabel(this.data.host, 100, 12);
        }

        return "";
    }

    get labelCfg() {
        return {
            position: "bottom",
            style: {
                opacity: this.opacity,
                background: {
                    fill: 'rgba(255, 0, 0, 0.0)',
                    stroke: 'rgba(255, 0, 0, 0.0)',
                    padding: [15, 2, 3, 2],
                },
            },
        };
    }

    get hasCustomStates() {
        return this.data.customStates && this.data.customStates.length;
    }

    get isNotSearched() {
        return this.hasCustomStates && this.data.customStates.includes("!searched");
    }

    get hideLabel() {
        return this.hasCustomStates && this.data.customStates.includes("!label");
    }

    get opacity() {
        return this.isNotSearched ? .18 : 1;
    }

    get style() {
        return {
            size: this.size,
            radius: this.radius,
            fill: this.fill,
            stroke: this.stroke,
            lineWidth: this.lineWidth,
            opacity: this.opacity,
        };
    }
}

export class Edge {
    constructor(data) {
        this.data = data;
        this.radius = 20;
    }

    get labelCfg() {
        return {
            refY: -10,
        };
    }

    get stroke() {

        if (this.data.has_incident ||
            (this.data.loss > 0 && this.data.target_unidentifiable === false)) {
            return "#D43527";
        }

        return "#B8B8B8";
    }

    get lineDash() {
        if (this.data.has_incident) {
            return [4];
        }

        return undefined;
    }

    get lineWidth() {
        if (this.data.has_incident || this.data.loss > 0) {
            return 2;
        }

        return 1;
    }

    get hasCustomStates() {
        return this.data.customStates && this.data.customStates.length;
    }

    get isNotSearched() {
        return this.hasCustomStates && this.data.customStates.includes("opaque");
    }

    get opacity() {
        return this.isNotSearched ? .18 : 1;
    }

    get style() {
        return {
            lineWidth: this.lineWidth,
            stroke: this.stroke,
            lineDash: this.lineDash,
            radius: this.radius,
            lineAppendWidth: 25,
            opacity: this.opacity,
        };
    }
}

