<style scoped lang="scss">
@import "~styles/core/helpers/_variables.scss";

#third-party-path {
    width: 900px;
    margin: 0 auto;

}

.instance-table-header {
    margin: 13px 10px;
}

.instance-table-checkbox-pl-10 {
    padding-left: 10px !important;
}

.pa-alert-red > .pa-alert-wrapper .pa-icon.onsight-error {
    color: var(--p-blue-500);
    fill: var(--p-blue-500,);
}
</style>

<template>
    <p-onboarding-path
        id="third-party-path"
        :title="computedTitle"
        :logo="infrastructureType.logo"
    >

        <p-onboarding-steps
            @on-step-back="handleBackStep"
            @on-step-change="handleStepChange"
            :step-names="['Discovery Details', 'Instances']"
            ref="onboardingStepsComp"
        >

            <p-onboarding-step class="pa-px-24 pa-pt-16">
                <template v-if="stepOneDataLoaded">
                    <p-alert
                        v-if="hasErrorFromOnsight"
                        class="extended-alert pa-mb-16"
                        variant="red"
                        full-width
                        icon="critical-outline"
                        icon-position="left"
                        icon-size="lg"
                        bold
                    >
                        <p-flex>
                            <span v-if="errorFromOnsight.message && errorFromOnsight.message != ''">
                                {{ errorFromOnsight.message }} -
                            </span>
                             <p-tooltip2 :width="340" class="alert-tooltip" :hover="false">
                                <span slot="trigger" class="tooltip-trigger-link">
                                    <p-link>
                                        <span class="pa-px-4">Full Details</span>
                                        <p-icon
                                            icon="question-circle-outline"
                                            class="onsight-error"
                                            size="xsm"
                                        ></p-icon>
                                    </p-link>
                                </span>
                                {{ errorFromOnsight.raw_result }}
                            </p-tooltip2>
                        </p-flex>
                    </p-alert>
                    <p-field-group class="pa-pt-10">
                        <div>
                            <label class="pa-label pa-pb-6">Name</label>
                        </div>
                        <p-input
                            v-model="configName"
                            :placeholder="configNamePlaceholder"
                            class="pa-input_xxxxl"
                            name="config-name"
                            type="text"
                        />
                    </p-field-group>
                    <p-field-group class="pa-py-10">
                        <div>
                            <label class="pa-label">Onsight</label>
                        </div>
                        <p-select
                            :options="onsightOptions"
                            :model.sync="onsightSelection"
                            defaulttext="Select Onsight"

                            class="metric-select pa-mr-12 pa-mt-6"
                            searchable
                            :list-width="350"
                            wrap-lables
                        >
                        </p-select>
                    </p-field-group>

                    <p-field-group
                        label="Instance Group"
                        slot-class="pa-border-solid"
                        v-if="treeReady"
                        slot-style="height: 300px; overflow-y: auto; border-radius: 2px;"
                    >
                        <a
                            v-if="canAddGroup"
                            @click="openNewGroup"
                            slot="header-actions"
                            href="#"
                            class="pa-link pa-txt_xs"
                        >
                            Create new group
                        </a>
                        <p-tree
                            ref="server_group_tree"
                            id="check-location"
                            url='/util/monitoring_tree?only_groups=true&include_compound_services=true&selectable_root=0'
                            :model.sync="groupSelection"
                            :preselected="preselectedGroup"
                            :multiselect="false"
                            :deferred="false"
                            select_nodes
                            required="required"
                            show-search
                        >
                        </p-tree>
                    </p-field-group>

                    <div v-if="metadataReturned">
                        <p-field-group
                            v-for="(metadata, index) in integration_metadata"
                            :name="metadata.name"
                            :label="computedLabel(metadata)"
                            class="pa-py-12"
                            :error="configErrors[metadata.name]"
                        >
                            <textarea
                                v-if="inputIsTextArea(metadata.name)"
                                ref="textInput"
                                v-model="integration_metadata[index].value"
                                class="pa-input pa-input_textarea"
                                style="height: 100px;"
                                :disabled="formDisabled"
                            >
                            </textarea>
                            <p-input
                                v-if="computedInputType(metadata.type) == 'text'"
                                v-model="integration_metadata[index].value"
                                :placeholder="computedPlaceholder(metadata.name)"
                                :name="metadata.name"
                                type="text"
                                :disabled="formDisabled"
                            >
                            </p-input>
                            <p-masked-input
                                v-else
                                class="password-field"
                                v-model="integration_metadata[index].value"
                                placeholder="Password"
                                :has-value-saved="infrastructureType.saved_config_data ? true : false"
                                ref="passwordRef"
                            ></p-masked-input>
                        </p-field-group>
                    </div>
                </template>
            </p-onboarding-step>

            <p-onboarding-step class="pa-px-24 pa-pt-16">
                <template v-if="discoveryId == 0 || currentStep == 2">
                    <p-alert
                        class="pa-mb-16"
                        variant="blue"
                        full-width
                        icon="information-outline"
                        icon-position="left"
                        icon-size="lg"
                        bold
                    >
                        <p v-if="discoveryId == 0" class="pa-m-0 pa-py-8">
                            Performing instance discovery. This could take up to 5 minutes.
                        </p>
                        <p v-if="currentStep == 2" class="pa-m-0 pa-py-8">
                            Applying selections.
                        </p>
                    </p-alert>
                    <p-flex align-center justify-center class="pa-my-56">
                        <p-loading-spinner-2 style="height: 150px; width: 150px;"></p-loading-spinner-2>
                    </p-flex>
                </template>
                <template v-else>
                    <h6 class="instance-table-header">Here's a list of discovered instances. Select the ones you'd like to monitor</h6>
                    <div v-if="currentStep == 1">
                        <p-table2
                            ref="discovered_instances_table"
                            id="discovered_instances_table"
                            source="server"
                            :data-url="`/onboarding/get_third_party_discovered_instances_table_data?discovery_id=${discoveryId}`"
                            paging
                            info
                            filtering
                            :page-length="10"
                            :column-renderers="{0: 'checkbox', 4: 'vue', 5: 'vue'}"
                            :on-paginate-callback="paginateCallback"
                            :hide-default-search="true"
                            empty-message="No Instances Found"
                            class="pa-pt-8 pa-mx-8"
                        >
                            <table slot="table" class="pa-table" id="discovered-instances-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <label class="pa-option">
                                                <input class="pa-table-check-all pa-option-input" type="checkbox" />
                                                <span class="pa-option-icon"></span>
                                            </label>
                                        </th>
                                        <th>Name</th>
                                        <th>Parent</th>
                                        <th>Type</th>
                                        <th>Attributes</th>
                                        <th>Tags</th>
                                    </tr>
                                </thead>
                            </table>
                        </p-table2>
                    </div>
                </template>
            </p-onboarding-step>
        </p-onboarding-steps>

        <p-divider class="pa-mt-56"></p-divider>

        <ul class="pa-split pa-p-8">
            <li class="pa-pl-24">
                <p-checkbox
                    class="pa-pt-6 pa-pb-8"
                    :model.sync="selectEntireResult"
                    label="Select entire result"
                    v-if="discoveryId > 0 && this.currentStep === 1"
                    :show-unselect-all="showUnselectAll"
                >
                </p-checkbox>
            </li>
            <li>
                <p-button
                    v-if="currentStep < 2"
                    :disabled="primaryButtonDisabled"
                    @click="handlePrimaryButtonClick"
                >
                    {{ primaryButton }}
                </p-button>
            </li>
        </ul>
        <p-create-group :successCallback="successNewGroupCallback"></p-create-group>
    </p-onboarding-path>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { 
    getIntegrationDetails,
    getIntegrationConfigMetadata, 
    submitIntegrationConfigData,
    getDiscoveredInstances,
    getDiscoveryId,
    submitThirdPartySelections,
    getThirdPartyAsyncTaskStatus,
} from "../../api/services/OnboardingService.js";

export default Vue.extend({
    data: () => ({
        currentStep: 0,
        onsightOptions: [],
        onsightSelection: null,
        groupSelection: [],
        groupOptions: [],
        integration_metadata: [],
        instance_query: null,
        instanceData: [],
        disable_all: false,
        instanceHeaders: ['Name', 'Parent', 'Type', 'Attributes', 'Tags'],
        selectedInstances: [],
        unselectedInstances: [],
        selectEntireResult: false,
        discoveryId: 0,
        integrationConfigId: null,
        agentCommandId: null,
        totalUnfilteredCount: 0,
        asyncTaskId: null,
        asyncTaskComplete: false,
        configName: null,
        configId: null,
        canAddGroup: false,
        preselectedGroup: [],
        treeReady: false,
        stepOneDataLoaded: false,
        configErrors: {},
        errorFromOnsight: {},
        hasErrorFromOnsight: false,
    }),
    props: {
        infrastructureType: Object,
    },
    events: {
        'checkbox-update-added-removed'(checked, unchecked) {
            if (this.selectEntireResult == true) {
                this.unselectedInstances = [
                    ...this.unselectedInstances,
                    ...(unchecked.filter(v => !this.unselectedInstances.includes(v)))
                ];
                for(let i=0; i<this.unselectedInstances.length; i++) {
                    if(checked.includes(this.unselectedInstances[i])) {
                        this.unselectedInstances.splice(i, 1);
                        i = i - 1; // our list just lost an index
                    }
                }
            } else {
                this.selectedInstances = [
                    ...this.selectedInstances,
                    ...(checked.filter(v => !this.selectedInstances.includes(v)))
                ];
                for(let i=0; i<this.selectedInstances.length; i++) {
                    if(unchecked.includes(this.selectedInstances[i])) {
                        this.selectedInstances.splice(i, 1);
                        i = i - 1; // our list just lost an index
                    }
                }
            }
        },
    },
    watch: {
        selectEntireResult(newVal, oldVal) {
            this.selectedInstances = [];
            this.unselectedInstances = [];
            if(this.selectEntireResult) {
                this.$refs.discovered_instances_table.selectAll();
            } else {
                this.$refs.discovered_instances_table.unSelectAll();
            }
        },
    },
    computed: {
        computedTitle() {
            if (this.infrastructureType.title.trim().includes(" ")) {
                let title_split = this.infrastructureType.title.split(" ");
                if(title_split[1].toLowerCase() == "integration") {
                    return title_split[0] + ' Integration';
                }
            }
            return this.infrastructureType.title + ' Integration';
        },
        inputIsTextArea() {
            return (name) => {
                const text_inputs = ["text","encrypted"];
                if (text_inputs.includes(name.toLowerCase())) {
                    return true;
                }
                return false;
            };

        },
        computedInputType() {
            return (type) => {
                if (type.toLowerCase() == "masked") {
                    return "password";
                }
                return "text";
            }
        },
        computedLabel() {
            return (metadata) => {
                let name = metadata.name;
                if (name.toLowerCase()=="ip") {
                    name = 'IP';
                    if (metadata.required) {
                        return 'IP*';
                    }
                    return "IP";
                }
                if (metadata.required) {
                    return name + '*';
                }
                return name.charAt(0).toUpperCase() + name.slice(1);;
            }
        },
        metadataReturned() {
            return this.integration_metadata.length>0;
        },
        configNamePlaceholder() {
            return `New ${this.computedTitle}`;
        },
        computedPlaceholder() {
            return (name) => {
                let placeholder = name;
                if (placeholder.toLowerCase()=="ip") {
                    return "Enter " + 'IP';
                }
                placeholder = _.startCase(_.toLower(placeholder));
                return "Enter " + placeholder;
            }
        },
        formDisabled() {
            return this.disable_all;
        },
        primaryButtonDisabled() {
            if (this.currentStep === 0) {
                if (this.onsightSelection && this.groupSelection.length > 0) {
                    return false;
                }
                return true;
            }
            if (this.currentStep === 1) {
                if (this.selectedInstances.length > 0 ||
                    (this.selectEntireResult == true && this.unselectedInstances.length != this.totalUnfilteredCount))
                {
                    return false;
                }
            }
            return this.disable_all;
        },
        primaryButton() {
            if (this.currentStep === 1) {
                return "Finish";
            }
            return "Next";
        },
        hasSelectedOnSight() {
            return parseInt(this.location) < 0;
        },
        showUnselectAll() {
            return this.selectEntireResult &&
                    this.selectedInstancesCount != this.totalUnfilteredCount;
        },
        selectedInstancesCount() {
            let count = ""

            if(this.selectEntireResult == true) {
                count = this.totalUnfilteredCount - this.unselectedInstances.length;
            } else {
                count = this.selectedInstances.length;
            }
            if (!count || count == "") {
                return count;
            }

            return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
    methods: {
        handleStepChange() {
            this.currentStep++;
        },
        handleBackStep() {
            this.currentStep = 0;
            this.disable_all = false;
        },
        changeStep(nextStep) {
            this.eventHub.$emit("change-step", { nextStep });
        },
        handlePrimaryButtonClick() {
            if (this.currentStep === 0) {
                if (this.metadataReturned) {
                    this.submitConfigData();
                } else {
                    this.getIntegrationConfigMetadata(this.onsightSelection);
                }
            } else {
                this.submitSelections();
            }
        },
        openNewGroup() {
            this.$broadcast('dialog:open', 'add_new_group');
        },
        closeNewGroup() {
            this.$broadcast('dialog:close', 'add_new_group');
        },
        successNewGroupCallback(group_id) {
            const serverGroup = `grp-${group_id}`;
            this.$refs.server_group_tree.tree.reload();
            this.preselectedGroup = [...[serverGroup]];
            this.closeNewGroup();
        },
        paginateCallback() {
            if(this.selectEntireResult) {
                this.$refs.discovered_instances_table.selectAll();
            }
           this.selectPaginatedCheckboxes();
        },
        openNewGroup() {
            this.$broadcast('dialog:open', 'add_new_group');
        },
        closeNewGroup() {
            this.$broadcast('dialog:close', 'add_new_group');
        },
        selectPaginatedCheckboxes() {
            if (this.$refs.discovered_instances_table) {
                const { dataTable } = this.$refs.discovered_instances_table;
                const nodes = dataTable.column(0).nodes();
                Array.from(nodes).forEach((nodeEl) => {
                    const checkboxEl = nodeEl.querySelector(".pa-table-row-checkbox");
                    if (checkboxEl && this.selectEntireResult && this.unselectedInstances.includes(checkboxEl.value)) {
                        checkboxEl.checked = false;
                        nodeEl.parentElement.classList.remove("selected");
                    }
                    else if (checkboxEl && !this.selectEntireResult && this.selectedInstances.includes(checkboxEl.value)) {
                        checkboxEl.checked = true;
                        nodeEl.parentElement.classList.add("selected");
                    }
                });
            }
        },
        async getIntegrationDetails() {
            const response = await getIntegrationDetails({
                textkey: this.infrastructureType.textkey,
            });
            if (response.data.success) {
                this.onsightOptions = response.data.appliances;
                this.groupOptions = response.data.groups;
                this.canAddGroup = response.data.acls.can_add_group;
                this.stepOneDataLoaded = true;
            } else {
                console.log(response);
            }
        },
        async getIntegrationConfigMetadata(onsight_id) {
            const response = await getIntegrationConfigMetadata({
                integration_id: this.onsightSelection,
            });
            if (response.data.success) {
                this.integration_metadata = response.data.metadata.configuration_metadata;
            } else {
                console.log(response);
            }
        },
        async submitConfigData(rediscover=false) {
            const data = {
                params: this.integration_metadata,
                integration_id: this.onsightSelection,//This is correct.
                config_name: this.configName ? this.configName : this.configNamePlaceholder,
                server_group_id: rediscover ? this.preselectedGroup[0] : this.groupSelection[0],
                config_id: this.configId
            }
            const response = await submitIntegrationConfigData({
                submit_data: data,
            });
            if (response.data.success) {
                this.disable_all = true;
                this.changeStep(1);
                this.integrationConfigId = response.data.id;
                this.agentCommandId = response.data.cmd_id;
                this.getDiscoveryId();
                this.stepOneDataLoaded = true;
                
            } else if (response.data.errors){
                this.configErrors = response.data.errors;
            } else {
                window.app.toastManager.showToast({
                    text: 'There was an error while attempting to save integration instance. Please try again later or contact support.',
                    type: 'error',
                    icon: 'alert-circle'
                });  
            }
        },

        async getDiscoveryId() {
            this.hasErrorFromOnsight = false;
            this.errorFromOnsight = {};

            const response = await getDiscoveryId({
                integration_config_id: this.integrationConfigId,
                agent_command_id: this.agentCommandId,
            });

            if (response.data.success) {
                if(response.data.failed_on_onsight) {
                    this.errorFromOnsight = {
                        message: response.data.message,
                        raw_result: response.data.raw_result,
                    };
                    const url = new URL(window.location.href);
                    url.searchParams.set('config_id', this.integrationConfigId);
                    window.history.pushState(null, '', url.toString());
                    this.hasErrorFromOnsight = true;
                    this.$refs.onboardingStepsComp.decrementStep();
                    return;
                } else {
                    this.discoveryId = response.data.discovery_id;
                    this.totalUnfilteredCount = response.data.discovered_instance_count;
                }
            } else {
                window.app.toastManager.showToast({
                    text: 'There was an error while discovery. Please try again later or contact support.',
                    type: 'error',
                    icon: 'alert-circle'
                });
                return;
            }

            if(this.discoveryId == 0 && this.currentStep == 1) {
                setTimeout(() => {
                    this.getDiscoveryId()
                }, 15000);
            }
        },
        async submitSelections(rediscover=false) {
            const data = {
                discovery_id: this.discoveryId,
                select_all: this.selectEntireResult,
                selected: this.selectedInstances,
                unselected: this.unselectedInstances,
            };
            const response = await submitThirdPartySelections({
                submit_data: data,
            });
            if (response.data.success) {
                this.disable_all = true;
                this.currentStep++;
                this.selectEntireResult = false;
                this.selectedInstances = [];
                this.asyncTaskId = response.data.async_task_id;
                this.getAsyncTaskStatus();
            } else {
                window.app.toastManager.showToast({
                    text: 'There was an error while attempting to save integration instance. Please try again later or contact support.',
                    type: 'error',
                    icon: 'alert-circle'
                });
            }
        },
        async getAsyncTaskStatus() {
            const response = await getThirdPartyAsyncTaskStatus({
                async_task_id: this.asyncTaskId,
            });

            if (response.data.success) {
                if(response.data.async_task_status == "complete") {
                    window.location = `/report/ListThirdPartyInstances/${this.infrastructureType.record_id}`;
                }

                if(response.data.status == "error" || response.data.async_task_status == "error") {
                    window.app.toastManager.showToast({
                        text: 'There was an error while attempting to save integration instance. Please try again later or contact support.',
                        type: 'error',
                        icon: 'alert-circle'
                    });

                    return;
                }
            }

            if(this.currentStep == 2 && !this.asyncTaskComplete) {
                setTimeout(() => {
                    this.getAsyncTaskStatus()
                }, 15000);
            }
        },
    },
    mounted() {
        if(this.infrastructureType.saved_config_data) {
            const savedData = this.infrastructureType.saved_config_data;
            this.configName = savedData.name;
            this.configId = savedData.config_id
            this.onsightSelection = savedData.appliance_id;
            this.preselectedGroup = [`grp-${savedData.server_group_id}`];
            this.integration_metadata = savedData.configuration_metadata;
        }
        this.$nextTick(() => {
            this.treeReady = true;
        });
        if(this.infrastructureType.rediscover) {
            this.submitConfigData(true);
        }
        this.getIntegrationDetails();
    },
});
</script>
