<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    #reset-password-body {
        margin-top: 40px;
    }
</style>

<template>
    <div id="reset-password">
        <div class="auth-header">
            <div>
              <img src="/static/images/Fortinet.png" style="width: 300px;" />
              <br />
              <img src="/static/images/FortiMonitor-dark.png" class="logo" style="width: 60px; align: center; margin-top: 25px;">
            </div>
            <template v-if="newUserInvite">
                <h2 class="title pa-mt-24">Welcome to {{brand_name}}</h2>
                <p class="pa-txt_lg pa-mt-12">One last thing - please create a password</p>
            </template>
            <template v-else>
                <h2 class="title pa-mt-24">Reset Password</h2>
                <p class="pa-txt_lg pa-mt-12">Please enter a new password. Your password must be at least 12 characters in length and must contain at least one non-letter character.</p>
            </template>
        </div>

        <div id="reset-password-body">
            <form ref="form" action="/resetPassword" method="POST">
                <input v-if="newUserInvite" type="hidden" name="new_user_invite" value="1" />

                <p-field-group
                    name="new_password"
                    label="Password"
                    class="pa-field_error-icon"
                    :error="errors.new_password || ''">
                    <p-input
                        v-model="password1"
                        name="new_password"
                        type="password"
                        theme="naked">
                    </p-input>
                </p-field-group>

                <p-field-group
                    name="confirm_new_password"
                    label="Confirm Password"
                    class="pa-field_error-icon pa-mt-40"
                    :error="errors.confirm_new_password || ''">
                    <p-input
                        v-model="password2"
                        name="confirm_new_password"
                        type="password"
                        theme="naked">
                    </p-input>
                </p-field-group>

                <input name="email_address" type="hidden" :value="iFields.email_address" />
                <input name="verify_code" type="hidden" :value="iFields.verify_code" />
            </form>

            <button @click="submit"
                :disabled="!isFilled"
                class="pa-btn pa-btn_block pa-btn_blue submit-button pa-mt-32">
                <span class="pa-txt_xxl">{{ newUserInvite ? 'Complete' : 'Reset' }}</span>
            </button>

            <p v-if="!newUserInvite" class="pa-txt pa-txt_sm pa-mt-8">
                <a @click="back" class="pa-clickable">&lt; Back to login</a>
            </p>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    export default Vue.extend({
        props: {
            brand_name: String,
            brand_textkey: String,
            newUserInvite: Boolean,
            errors: {
                type: Object,
                'default': () => Object(),
            },
            fields: {
                type: Object,
                'default': () => Object(),
            },
        },

        data() {
            return {
                // Form inputs
                iFields: _.cloneDeep(this.fields),
                password1: '',
                password2: '',
            };
        },

        computed: {
            isFilled() {
                return this.password1 && this.password1 === this.password2;
            },
        },

        methods: {
            submit() {
                this.$refs.form.submit();
            },

            back() {
                this.eventHub.$emit('auth:switchType', 'login');
            },
        },
    });
</script>
