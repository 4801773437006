<style lang="scss">
#onboarding-2 {
    & > .pa-card2:first-child:not(#onboarding-catalog) {
        width: 850px;
        margin: 0 auto;
    }
}

.catalog-wrapper {
    height: calc(100vh - 52px);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<template>
    <article id="onboarding-2" :class="onCatalogPage ? 'catalog-wrapper' : ''">
        <component :is="routedComponent" v-bind="componentProps">
        </component>
    </article>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import {
    BASE_ROUTE,
    NOT_FOUND_ROUTE,
    PATHNAME_PREFIX,
    SAVE_STATUSES,
    IGNORED_SAVE_STATUS_PATHS,
} from "../shared/constants/onboarding";
import { listen, push } from "../shared/utils/history";
import getUrlParams from '../shared/utils/getUrlParams';

import OnboardingCatalog from "./OnboardingCatalog.vue";
import OnboardingActionPlan from "./OnboardingActionPlan.vue";

const AgentPath = () => import(
    /* webpackChunkName: "AgentPath" */
    './paths/AgentPath.vue'
);
const AwsPath = () => import(
    /* webpackChunkName: "AwsPath" */
    './paths/AwsPath.vue'
);
const AzurePath = () => import(
    /* webpackChunkName: "AzurePath" */
    './paths/AzurePath.vue'
);
const GCPPath = () => import(
    /* webpackChunkName: "GCPPath" */
    './paths/GCPPath.vue'
);
const MerakiPath = () => import(
    /* webpackChunkName: "MerakiPath" */
    './paths/MerakiPath.vue'
);
const VMWarePath = () => import(
    /* webpackChunkName: "VMWarePath" */
    './paths/VMWarePath.vue'
);
const NetflowPath = () => import(
    /* webpackChunkName: "NetflowPath" */
    './paths/NetflowPath.vue'
);
const NetworkDevicePath = () => import(
    /* webpackChunkName: "NetworkDevicePath" */
    './paths/NetworkDevicePath.vue'
);
const BrowserSyntheticPath = () => import(
    /* webpackChunkName: "BrowserSyntheticPath" */
    './paths/BrowserSyntheticPath.vue'
);
const WebsitePath = () => import(
    /* webpackChunkName: "WebsitePath" */
    './paths/WebsitePath.vue'
);
const NotFoundPath = () => import(
    /* webpackChunkName: "NotFoundPath" */
    './paths/NotFoundPath.vue'
);
const OnSightPath = () => import(
    /* webpackChunkName: "OnSightPath" */
    './paths/OnSightPath.vue'
);
const FabricPath = () => import(
    /* webpackChunkName: "FabricPath" */
    './paths/FabricPath.vue'
);

const getPath = () => {
    const path = window.location.pathname.replace(PATHNAME_PREFIX, "");

    if (path === "") {
        return BASE_ROUTE;
    }

    return path;
};

export default Vue.extend({
    components: {
        "p-onboarding-action-plan": OnboardingActionPlan,
        "p-onboarding-catalog": OnboardingCatalog,

        "p-agent-path": AgentPath,
        "p-aws-path": AwsPath,
        "p-gcp-path": GCPPath,
        "p-azure-path": AzurePath,
        "p-meraki-path": MerakiPath,
        "p-vmware-path": VMWarePath,
        "p-netflow-path": NetflowPath,
        "p-network-device-path": NetworkDevicePath,
        "p-browser-synthetic-path": BrowserSyntheticPath,
        "p-website-path": WebsitePath,
        "p-not-found-path": NotFoundPath,
        "p-onsight-path": OnSightPath,
        "p-fabric-path": FabricPath,
    },
    props: {
        pageData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        currentRoute: getPath(),
        selectedInfrastructureType: null,
        selectedInfrastructureTypes: [],
        customerKey: null,
        isNewUser: false,
        showFMAgent: false,
        hidePanoptaAgentEntries: false,
        catalogConfig: {},
        urlParams: null,
        onboardingConfig: {},
        onboardingSetup: {},
        thirdPartySetup: {},
        integration_metadata: null,
        saveStatus: SAVE_STATUSES.IDLE,
        hasHardwareOnsight: false,
        hasFabricOnsight: false,
        aggregatorUrl:null,
    }),
    events: {
        "mark-path-completed"() {
            this.markPathCompleted();
        },
    },
    watch: {
        currentRoute() {
            this.handleSaveStatus();
        },
    },
    computed: {
        onCatalogPage() {
            return this.routedComponent === "p-onboarding-catalog";
        },
        routes() {
            let discovered_routes = Object.values({ ...this.onboardingSetup, ...this.thirdPartySetup}).reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.id]: curr.component,
                }),
            {});
            return discovered_routes;
        },
        infrastructureType() {
            return Object.values({ ...this.onboardingSetup, ...this.thirdPartySetup}).find(
                (type) => type.id === this.currentRoute
            );
        },
        sharedPathProps() {
            let sharedPathProps = {
                "customer-key": this.customerKey,
                "infrastructure-type": this.infrastructureType,
                "is-new-user": this.isNewUser,
                "show-fm-agent": this.showFMAgent,
                "hide-panopta-agent-entries": this.hidePanoptaAgentEntries,
                "mark-onboarding-completed": this.markOnboardingCompleted,
                "mark-path-completed": this.markPathCompleted,
                "url-params": this.urlParams,
                "set-save-status": this.setSaveStatus,
                "has-hardware-onsight": this.hasHardwareOnsight,
                "has-ha-fmg": this.hasHAFortiManager,
                "instance-data": this.instanceData,
                "aggregator-url": this.aggregatorUrl,
            };

            return sharedPathProps
        },
        componentProps() {
            let componentProps = {
                "index": {
                    "go-to-route": this.goToRoute,
                    "infrastructure-types": this.onboardingSetup,
                    "third-party-types": this.thirdPartySetup,
                    "catalog-config": this.catalogConfig,
                    "is-new-user": this.isNewUser,
                    "show-fm-agent": this.showFMAgent,
                    "hide-panopta-agent-entries": this.hidePanoptaAgentEntries,
                    "on-submit": this.handleCatalogSubmit,
                },
                "action-plan": {
                    "go-to-route": this.goToRoute,
                    "selected-infrastructure-types": this.selectedInfrastructureTypes,
                },
                "onsight": this.sharedPathProps,
                "aws": this.sharedPathProps,
                "azure": this.sharedPathProps,
                "meraki": this.sharedPathProps,
                "gcp": this.sharedPathProps,
                "linux-fm": this.sharedPathProps,
                "mac-dem-agent": this.sharedPathProps,
                "linux-panopta": this.sharedPathProps,
                "unix-virtual": this.sharedPathProps,
                "windows-server-panopta": this.sharedPathProps,
                "windows-server-fm": this.sharedPathProps,
                "windows-endpoint-panopta": this.sharedPathProps,
                "windows-endpoint-fm": this.sharedPathProps,
                "unix-physical": this.sharedPathProps,
                "docker": this.sharedPathProps,
                "kubernetes": this.sharedPathProps,
                "vmware": this.sharedPathProps,
                "network-device-basic": this.sharedPathProps,
                "network-device-advanced": this.sharedPathProps,
                "fabric": {...this.sharedPathProps,
                    "has-fabric-onsight": this.hasFabricOnsight,
                    "has-ha-onsight-fabric": this.hasHAOnsightFabric,
                },
                "aggregator-url":this.aggregatorUrl,
                "synthetic": this.sharedPathProps,
                "website": this.sharedPathProps,
                "onsight-netflow": this.sharedPathProps,
                "not-found": {},
            };

            if (this.infrastructureType.component == "p-third-party-path") {
                componentProps[this.infrastructureType.id] = this.sharedPathProps;
            }

            return componentProps[this.currentRoute];
        },
        routedComponent() {
            return this.routes[this.currentRoute] || this.routes[NOT_FOUND_ROUTE];
        },
    },
    methods: {
        handleSaveStatus() {
            if (this.currentRoute === "index" || this.currentRoute === "") {
                this.setSaveStatus(SAVE_STATUSES.IDLE);
            } else {
                this.setSaveStatus(SAVE_STATUSES.UNSAVED);
            }
        },
        handleCatalogSubmit(data) {
            this.selectedInfrastructureTypes = [...data.selectedInfrastructureTypes];
            if (this.isNewUser) {
                this.setInitialOnboardingConfig(data);
                this.goToRoute("action-plan");
            } else {
                if (data["integration_metadata"]) {
                    this.integration_metadata = data["integration_metadata"];
                }
                this.goToRoute(data.selectedInfrastructureTypes[0].id);
            }
        },
        setInitialOnboardingConfig(data) {
            const selectedInfrastructure = data.selectedInfrastructureTypes.map(
                (infra) => infra.id
            );
            const config = {
                selectedInfrastructure: [...new Set(selectedInfrastructure)],
                completedInfrastructure: [],
                displayOnboardingTodoDrawer: false,
            };

            if (!this.onboardingConfig || _.isEmpty(this.onboardingConfig)) {
                this.setOnboardingConfig(config);
            }
        },
        setOnboardingConfig(config) {
            const data = { config: JSON.stringify(config) };

            $.ajax({
                url: "/onboarding/v2/updateOnboardingConfig",
                method: "POST",
                context: this,
                data,
            });
        },
        markPathCompleted() {
            const missingInfrastructureType = (
                !this.infrastructureType ||
                !this.infrastructureType.id
            );

            if (missingInfrastructureType) {
                console.error("Cannot mark path completed, missing infra type.", this.infrastructureType);

                return;
            }

            this.setSaveStatus(SAVE_STATUSES.SAVED);

            let config = { ...this.onboardingConfig };

            if (
                Boolean(config.completedInfrastructure) &&
                Array.isArray(config.completedInfrastructure)
            ) {
                if (!config.completedInfrastructure.includes(this.infrastructureType.id)) {
                    config.completedInfrastructure.push(this.infrastructureType.id);
                }
            } else {
                config = {
                    ...config,
                    completedInfrastructure: [this.infrastructureType.id],
                };
            }

            const prevCompleted = this.onboardingConfig.completedInfrastructure ? this.onboardingConfig.completedInfrastructure.length : 0;
            const currCompleted = config.completedInfrastructure.length;
            // Display on first completion
            let displayDrawer = prevCompleted !== currCompleted && currCompleted === 1;

            if (!this.isNewUser) {
                displayDrawer = false;
            }

            config.displayOnboardingTodoDrawer = displayDrawer;

            this.setOnboardingConfig(config);
        },
        markOnboardingCompleted() {
            $.ajax({
                url: "/onboarding/markCompleted",
                method: "GET",
                context: this,
            });
        },
        goToRoute(route) {
            push(route);
        },
        initData() {
            this.customerKey = this.pageData.customerKey;
            this.showFMAgent = this.pageData.showFMAgent;
            this.instanceData = this.pageData.instanceData;
            this.hidePanoptaAgentEntries = this.pageData.hidePanoptaAgentEntries;
            this.isNewUser = this.pageData.isNewUser;
            this.catalogConfig = {...this.pageData.catalogConfig};
            this.onboardingConfig = {...this.pageData.onboardingConfig};
            this.onboardingSetup = {...this.pageData.onboardingSetup};
            this.thirdPartySetup = {...this.pageData.thirdPartySetup};
            this.hasHardwareOnsight = this.pageData.has_hardware_onsight;
            this.hasFabricOnsight = this.pageData.has_fabric_6x_onsight;
            this.hasHAFortiManager = this.pageData.has_fabric_ha_fmg;
            this.hasHAOnsightFabric = this.pageData.has_fabric_ha_onsight;
            this.aggregatorUrl = this.pageData.aggregator_url;
        },
        setupRouteListening() {
            listen((route, previousRoute) => {
                this.currentRoute = route.split("?")[0];
            });

            // Listens for browsers back and forth buttons
            window.addEventListener("popstate", this.onPopState);
            // Listens for navigating away or window closing
            // window.addEventListener("beforeunload", this.handleBeforeUnload);
        },
        onPopState() {
            this.currentRoute = getPath();
        },
        setSaveStatus(status) {
            this.saveStatus = status;
        },
        handleBeforeUnload(e) {
            if (
                this.saveStatus === SAVE_STATUSES.UNSAVED &&
                !IGNORED_SAVE_STATUS_PATHS.includes(this.currentRoute)
            ) {
                const message = 'Your changes have not been saved. If you leave before saving, your changes will be lost.';

                let event = e || window.event;

                // For IE and Firefox
                if (event) {
                    event.returnValue = message;
                }

                // For Safari
                return message;
            }

            return undefined;
        },
        removeListeners() {
            window.removeEventListener("beforeunload", this.handleBeforeUnload);
            window.removeEventListener("popstate", this.onPopState);
        },
        getUrlParams() {
            const urlParams = getUrlParams(window.location.search);
            this.urlParams = {...urlParams};
        },
    },
    beforeDestroy() {
        this.removeListeners();
    },
    created() {
        this.handleSaveStatus();
        this.setupRouteListening();
        this.getUrlParams();
        this.initData();
    },
});
</script>
