import Vue from 'vue';
const AcknowledgeDrawer = () => import(
    /* webpackChunkName: "AcknowledgeDrawer" */
    './shared/components/AcknowledgeDrawer.vue'
);
import Action from './shared/components/Action.vue';
import AnimatedCheckIcon from './shared/components/AnimatedCheckIcon.vue';
import AnimatedErrorIcon from './shared/components/AnimatedErrorIcon.vue';
import AlertItemsTableCell from './shared/components/AlertItemsTableCell.vue';
import ApplicationAvailabilityStatusColumn from './shared/components/ApplicationAvailabilityStatusColumn.vue';
import Expando from './shared/components/Expando.vue';
import ExpandoX from './shared/components/Expando-X.vue';
import Accordion from './shared/components/Accordion.vue';
import DynamicTitle from './shared/components/DynamicTitle.vue';
import Alert from './shared/components/Alert.vue';
import ApplicationList from './shared/components/ApplicationList.vue';
import AppLink from './shared/components/AppLink.vue';
import AttributeKeyValue from './shared/components/AttributeKeyValue.vue';
import AttributeList from './shared/components/AttributeList.vue';
import AttributeListItem from './shared/components/AttributeListItem.vue';
import Avatar from './shared/components/Avatar.vue';
import Badge from './shared/components/Badge.vue';
import Banner from './shared/components/Banner.vue';
import Breadcrumb from './shared/components/Breadcrumb.vue';
import BreadcrumbItem from './shared/components/BreadcrumbItem.vue';
import Button from './shared/components/Button.vue';
import ButtonGroup from './shared/components/ButtonGroup.vue';
import Card from './shared/components/Card.vue';
import Checkbox from './shared/components/Checkbox.vue';
import Circle from './shared/components/Circle.vue';
import Column from './shared/components/Column.vue';
import ColorPicker from './shared/components/ColorPicker.vue';
import Concat from './shared/components/Concat.vue';
import ConditionPicker from './shared/components/ConditionPicker.vue';
import EditCountermeasure from "./shared/components/CountermeasureEdit.vue";
import CountermeasureOutput from './shared/components/CountermeasureOutput.vue';
const CountermeasureDetailsDrawer = () => import(
    /* webpackChunkName: "CountermeasureDetailsDrawer" */
    './shared/components/CountermeasureDetailsDrawer.vue'
);
const CountermeasureHistoryDrawer = () => import(
    /* webpackChunkName: "CountermeasureHistoryDrawer" */
    './shared/components/CountermeasureHistoryDrawer.vue'
);
import CopyScript from './shared/components/CopyScript.vue';
import CustomTableSearch from './shared/components/CustomTableSearch.vue';
import DataPoint from './shared/components/DataPoint.vue';
import DataPointButton from './shared/components/DataPointButton.vue';
import DataPointInfo from './shared/components/DataPointInfo.vue';
import DescriptionList from './shared/components/DescriptionList.vue';
import DescriptionListItem from './shared/components/DescriptionListItem.vue';
const DetailsDrawer = () => import(
    /* webpackChunkName: "DetailsDrawer" */
    './shared/components/DetailsDrawer.vue'
);
import Divider from './shared/components/Divider.vue';
import Drawer from './shared/components/Drawer.vue';
import DrawerActiveMaintList from './shared/components/DrawerActiveMaintList.vue';
import DrawerActiveMaintListItem from './shared/components/DrawerActiveMaintListItem.vue';
import DrawerActiveIncidentList from './shared/components/DrawerActiveIncidentList.vue';
import DrawerActiveIncidentListItem from './shared/components/DrawerActiveIncidentListItem.vue';
import DrawerActiveIncidentListItemAdvanced from './shared/components/DrawerActiveIncidentListItemAdvanced.vue';
import DrawerDeviceHealthList from './shared/components/DrawerDeviceHealthList.vue';
import DrawerGraphList from './shared/components/DrawerGraphList.vue';
import DrawerScheduledMaintList from './shared/components/DrawerScheduledMaintList.vue';
import DrawerScheduledMaintListItem from './shared/components/DrawerScheduledMaintListItem.vue';
import DualListbox from './shared/components/DualListbox.vue';
import EditText from './shared/components/EditText.vue';
import FieldGroup from './shared/components/FieldGroup.vue';
import FieldRow from './shared/components/FieldRow.vue';
import Filter from './shared/components/Filter.vue';
import FilterBuilder from './shared/components/FilterBuilder.vue';
import FilterSingle from './shared/components/FilterSingle.vue';
import FilterMenu from './shared/components/FilterMenu.vue';
import Flex from './shared/components/Flex.vue';
import Flyout from './shared/components/Flyout.vue';
import FlyoutMenu from './shared/components/FlyoutMenu.vue';
import Gauge from './shared/components/Gauge.vue';
import WidgetGeomap from './shared/components/WidgetGeomap.vue';
const Geomap = () => import(
    /* webpackChunkName: "Geomap" */
    './shared/components/Geomap.vue'
);
const GeomapAgentPopup = () => import(
    /* webpackChunkName: "GeomapAgentPopup" */
    './shared/components/GeomapAgentPopup.vue'
);
const GeomapAgentClusterPopup = () => import(
    /* webpackChunkName: "GeomapAgentClusterPopup" */
    './shared/components/GeomapAgentClusterPopup.vue'
);
import GroupedMenu from './shared/components/GroupedMenu.vue';
import GraphComponent from './shared/components/Graph.vue';
import GraphContainerComponent from './shared/components/GraphContainer.vue';
import GraphUnitSelect from './shared/components/GraphUnitSelect.vue';
import Header from './shared/components/Header.vue';
import HeaderNav from './shared/components/HeaderNav.vue';
import Hint from './shared/components/Hint.vue';
import Icon from './shared/components/Icon.vue';
import IncidentDetailsGraphComponent from './shared/components/IncidentDetailsGraph.vue';
import IncidentLead from './shared/components/incident_pane/IncidentLead.vue';
import Input from './shared/components/Input.vue';
import InstallAgentLinkMenu from './shared/components/InstallAgentLinkMenu.vue';
import InstanceIcon from './shared/components/InstanceIcon.vue';
import IntegrationJSONFields from './integrations/shared/IntegrationJSONFields.vue';
import IntegrationSidebar from './integrations/shared/Sidebar.vue';
import LabelDivider from './shared/components/LabelDivider.vue';
import Link from './shared/components/Link.vue';
import ListSelectbox from './shared/components/ListSelectbox.vue';
import ListToggle from './shared/components/ListToggle.vue';
import LoadingSpinner from './shared/components/LoadingSpinner.vue';
import LoadingSpinner2 from './shared/components/LoadingSpinner2.vue';
import LogicalOperatorPicker from './shared/components/LogicalOperatorPicker.vue';
import Markdown from './shared/components/Markdown.vue';
import MaskedInput from './shared/components/MaskedInput.vue';
import MaterialMenu from './shared/components/MaterialMenu.vue';
import Menu from './shared/components/Menu.vue';
import MetricText from './shared/components/MetricText.vue';
import Modal from './shared/components/Modal.vue';
import NavMenu from './shared/components/NavMenu.vue';
import NodeMap from './shared/components/NodeMap.vue';
import NumberInput from './shared/components/NumberInput.vue';
import PageHeader from './shared/components/PageHeader.vue';
import Panel from './shared/components/Panel.vue';
import Panels from './shared/components/Panels.vue';
import PortalTarget from './shared/components/PortalTarget.vue';
import PortSelectionDrawer from './shared/components/PortSelectionDrawer.vue';
import PrimaryMenu from './shared/components/PrimaryMenu.vue';
import ProgressBar from './shared/components/ProgressBar.vue';
import Prompt from './shared/components/Prompt.vue';
import PromptDrawer from './shared/components/PromptDrawer.vue';
import RadioMenu from './shared/components/RadioBar.vue';
import RadioOptions from './shared/components/RadioOptions.vue';
import Row from './shared/components/Row.vue';
import Search from './shared/components/Search.vue';
import SecondaryMenu from './shared/components/SecondaryMenu.vue';
import Section from './shared/components/Section.vue';
import SelectMenu from './shared/components/SelectMenu.vue';
import ServiceSelectionDrawer from './shared/components/ServiceSelectionDrawer.vue';
import SeverityBadge from './shared/components/SeverityBadge.vue';
import SideBar from './shared/components/SideBar.vue';
import CloudDiscoveryServersDrawer from './shared/components/CloudDiscoveryServersDrawer.vue';
import StatusIcon from './shared/components/StatusIcon.vue';
import StatusTable from './shared/components/StatusTable.vue';
import SubAccountInfo from './shared/components/SubAccountInfo.vue';
import SubMenu from './shared/components/SubMenu.vue';
import Switch from './shared/components/Switch.vue';
import SyntheticRealtime from './shared/components/SyntheticRealtime.vue';
import SyntheticChecksWaterfall from './shared/components/SyntheticChecksWaterfall.vue';
import SyntheticResults from './shared/components/SyntheticResults.vue';
import SyntheticScreenshots from './shared/components/SyntheticScreenshots.vue';
import SyntheticsTable from './shared/components/SyntheticsTable.vue';
import Tab from "./shared/components/Tab.vue";
import TabList from "./shared/components/TabList.vue";
import TabPanel from "./shared/components/TabPanel.vue";
import TabPanels from "./shared/components/TabPanels.vue";
import Tabs from "./shared/components/Tabs.vue";
import Table2 from './shared/components/Table2.vue';
import Table from './shared/components/Table.vue';
import TableCell from './shared/components/TableCell.vue';
import TableHead from './shared/components/TableHead.vue';
import Tag from './shared/components/Tag.vue';
import TagList from './shared/components/TagList.vue';
import TagsInput from './shared/components/TagsInput.vue';
import Text from './shared/components/Text.vue';
import TemplateList from './shared/components/TemplateList.vue';
import TemplateListItem from './shared/components/TemplateListItem.vue';
import Threshold from './shared/components/Threshold.vue';
import TimeRangeFilter from './shared/components/TimeRangeFilter.vue';
import TipCard from './shared/components/TipCard.vue';
import Toast from './shared/components/Toast.vue';
import Tooltip from './shared/components/Tooltip.vue';
import Tooltip2 from './shared/components/Tooltip2.vue';
import InternalAdminIcon from './shared/components/InternalAdminIcon.vue';
import Traceroute from './shared/components/Traceroute.vue';
import Tree from './shared/components/Tree.vue';
import Tree2 from './shared/components/Tree2.vue';
import TypeAheadSelect from './shared/components/TypeAheadSelect.vue';
import UIFilter from './shared/components/UIFilter.vue';
import UpDown from './shared/components/UpDown.vue';
import VueTable from './shared/components/VueTable.vue';
import Wizard from './shared/components/Wizard.vue';
import WizardPane from './shared/components/WizardPane.vue';
import WizardTree from './shared/components/WizardTree.vue';
import DatetimePicker from './shared/components/DatetimePicker.vue';
import Heatmap from './shared/components/Heatmap.vue';
import Time from './shared/components/Time.vue';
import MetricModule from './shared/components/MetricModule.vue';
import StatBar from './shared/components/StatBar.vue';
import Chip from './shared/components/Chip.vue';
import ChipList from './shared/components/ChipList.vue';
import Timescale from './shared/components/Timescale.vue';
import HTMLEditor from './shared/components/HTMLEditor.vue';
import ToggleChip from './shared/components/ToggleChip.vue';
import ToggleChipList from './shared/components/ToggleChipList.vue';
import TooltipButtonGroup from './shared/components/TooltipButtonGroup.vue';
import SNMPCustomRule from './instance_details/SNMPCustomRule.vue';
import VueTablesPagination from './shared/components/VueTablesPagination.vue';

import Auth from './auth/Auth.vue';

import SideNav from './navigation/side_nav/SideNav.vue';
import SecondaryNav from './navigation/secondary_nav/SecondaryNav.vue';

import DashboardSwitcher from './dashboards/DashboardSwitcher.vue';
import DashboardPropertiesDrawer from './dashboards/DashboardPropertiesDrawer.vue';

import ApplyRule from './templates/components/ApplyRuleBlock.vue';

import MonitoringCatalog2 from './catalog/MonitoringCatalog2.vue';
import MonitoringCatalog from './catalog/MonitoringCatalog.vue';
import MonitoringCatalogSdWan from './catalog/MonitoringCatalogSdWan.vue';

// Applications
const ApplicationStatusFlyout = () => import(
    /* webpackChunkName: "ApplicationStatusFlyout" */
    './applications/components/ApplicationStatusFlyout.vue'
);

// New Onboarding
import CreateGroup from './onboarding/paths/components/CreateGroup.vue';
import Splash from './onboarding/Splash.vue';
import OnboardingActionPlan from './onboarding/OnboardingActionPlan.vue';
import OnboardingPath from './onboarding/paths/OnboardingPath.vue';
import OnboardingSteps from './onboarding/OnboardingSteps.vue';
import OnboardingStep from './onboarding/OnboardingStep.vue';
import OnSightInstallation from './onboarding/OnSightInstallation.vue';
import ThirdPartyPath from './onboarding/paths/ThirdPartyPath.vue';

// Incident Overview
import QuickAddMultiSelect from './incident/components/QuickAddMultiSelect.vue';
import IncidentTable from './incident/components/IncidentTable.vue';

// Incident Details
import AcknowledgeDrawerUpcomingAlertTableCol from './outage/AcknowledgeDrawerUpcomingAlertTableCol.vue';
const AlertGroupDrawer = () => import(
    /* webpackChunkName: "AlertGroupDrawer" */
    './outage/AlertGroupDrawer.vue'
);

// Config
import CreateServerTemplate from './config/CreateServerTemplate.vue';

// Instance Details
const ExtendMaintenanceModal = () => import(
    /* webpackChunkName: "ExtendMaintenanceModal" */
    './instance_details/ExtendMaintenanceModal.vue'
);
import InstancePerformance from './instance_details/InstancePerformance.vue';
import InstancePerformanceGraphs from './instance_details/InstancePerformanceGraphs.vue';
import InstanceNetworkPortGraphs from './instance_details/InstanceNetworkPortGraphs.vue';
import ServerTabTableCol from './instance_details/ServerTabTableCol.vue';

/* Monitoring Policy */
import PolicyWorkflowHeader from './monitoring_policy/PolicyWorkflowHeader.vue';

/* report */
import AddSdWanChecks from './report/components/AddSdWanChecks.vue';
import DeleteInfraModal from './report/components/DeleteInfraModal.vue';
const EditServerGroup = () => import(
    /* webpackChunkName: "EditServerGroup" */
    './report/EditServerGroup.vue'
);
const ListInstancesDrawer = () => import(
    /* webpackChunkName: "ListInstancesDrawer" */
    './report/ListInstancesDrawer.vue'
);
import Maintenance from './report/Maintenance.vue';

/* Users */
import EditUser from './users/EditUser.vue';
import EditRotatingScheduleHeader from './users/EditRotatingScheduleHeader.vue';
import SwitchCustomer from './users/SwitchCustomer.vue';

import RoleActivities from "./role/components/RoleActivities.vue";

import adminIcon from './shared/directives/adminIcon';
import dialogClose from './shared/directives/dialogClose';
import dialogOpen from './shared/directives/dialogOpen';
import drawerOpen from './shared/directives/drawerOpen';
import drawerClose from './shared/directives/drawerClose';
import filterableElement from './shared/directives/filterableElement';
import flyoutOpen from './shared/directives/flyoutOpen';
import flyoutTrigger from './shared/directives/flyoutTrigger.js';
import hoverText from './shared/directives/hoverText';
import isFocused from './shared/directives/isFocused';
import onFocusout from './shared/directives/onFocusout';
import promptOpen from './shared/directives/promptOpen';

import rootVueMixin from './shared/mixins/rootVueMixin';

import WizardManager from './shared/utils/wizardManager';
import TreeManager from './shared/utils/treeManager';
import DatetimeManager from './shared/utils/datetimeManager';
import createScrollLocker from './shared/utils/scrollLocker';

import LocalStorageManager from './shared/utils/localStorageManager';
import GraphManager from './shared/utils/graphManager.js';
import ToastManager from './shared/utils/toastManager';

import hopscotchMixin from './shared/mixins/hopscotchMixin.js';

import { bindGlobalClickListeners } from './global/listeners.js';

import { pinia, PiniaPlugin } from "./pinia";
import { router, RouterPlugin } from "./router/router";

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from "@sentry/tracing";

import hashObject from 'object-hash';

const LOADING_CLASS = 'pa-loading';

import PortalVue from 'portal-vue';

import eventHub from './eventHub';

export default class App {
    constructor() {
        this.WizardManager = WizardManager;
        this.TreeManager = TreeManager;
        this.DatetimeManager = DatetimeManager;
        this.hopscotchMixin = hopscotchMixin;
        this.scrollLocker = createScrollLocker();

        // We only need one global instance of each of these managers
        // So we instantiate them right away
        this.toastManager = new ToastManager();
        this.localStorageManager = new LocalStorageManager();
        this.graphManager = new GraphManager();

        this.eventHub = eventHub;

        this.enable();
        this.init();
        this.setupGlobal();

        if (window.sentry_dsn && window.sentry_dsn.length) {
            let environment = 'testing';
            if (['my.panopta.com', 'fortimonitor.forticloud.com'].includes(window.location.hostname)) {
                environment = 'production';
            } else if (window.sentry_environment) {
                environment = window.sentry_environment;
            }
            const settings = {
                dsn: window.sentry_dsn,
                integrations: [],
                environment: environment,
                release: window.sentry_release || null,
                ignoreUrls: [/\/static\/libs\//],
                beforeSend: this.sentryBeforeSend.bind(this),
            };
            // Setup Tracing
            if (window.sentry_traces_rate) {
                settings.integrations.push(
                    new BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        tracePropagationTargets: [
                            'localhost',
                            'my.panopta.com',
                            'fortimonitor.forticloud.com',
                            /^\//,
                        ],
                    })
                );
                settings.tracesSampleRate = window.sentry_traces_rate;
            }
            // Setup Replays
            if (window.sentry_replay_rate || window.sentry_error_replay_rate) {
                settings.integrations.push(new Sentry.Replay());
                // This sets the sample rate to be 10%. You may want this to be 100% while
                // in development and sample at a lower rate in production
                settings.replaysSessionSampleRate = window.sentry_replay_rate || 0.0;
                // If the entire session is not sampled, use the below sample rate to sample
                // sessions when an error occurs.
                settings.replaysOnErrorSampleRate = window.sentry_error_replay_rate || 0.0;
            }
            Sentry.init({
                Vue,
                ...settings,
            });
            this.Sentry = Sentry;
            if (window.sentry_user) {
                Sentry.setUser(window.sentry_user);
            }
            if (typeof window.sentry_tags === 'object') {
                Sentry.setTags(window.sentry_tags);
            }
        }
    }

    enable() {
        window.addEventListener('load', () => {
            document.documentElement.classList.remove(LOADING_CLASS);
        });
    }

    createRootVue(config) {
        this.rootVue = new Vue(Object.assign(config, {
            router,
            pinia,
            mixins: [rootVueMixin],
        }));
    }

    createVue(config) {
        return new Vue(config);
    }

    createComponent(name, config) {
        return Vue.component(name, Vue.extend(config));
    }

    sentryBeforeSend(event) {
        if (!event.exception) {
            return event;
        }

        // Calculate the hash of this exception
        const hash = hashObject(event.exception);

        // Grab our cached exceptions
        const cache = this.localStorageManager.getObject('sentryCache.exceptions', {});
        let lastRefresh = cache.lastRefresh || null;
        let seen = cache.seen || [];

        // Reset the cache if it's been some time
        const refreshTimeout = 60 * 60 * 1000;
        const now = Number(new Date);
        if (!lastRefresh || (now - lastRefresh) > refreshTimeout) {
            lastRefresh = now;
            seen = [];
        }

        // Figure out if this is new
        let sendException = false;
        if (!seen.includes(hash)) {
            seen.push(hash);
            sendException = true;
        }

        // Update the cache
        this.localStorageManager.setObject('sentryCache.exceptions', {
            lastRefresh: lastRefresh,
            seen: seen,
        });

        // Send the exception or drop it
        if (sendException) {
            return event;
        }

        return null;
    }

    init() {
        Vue.component('p-acknowledge-drawer', AcknowledgeDrawer);
        Vue.component('p-incident-lead', IncidentLead);
        Vue.component('p-action', Action);
        Vue.component('p-animated-check-icon', AnimatedCheckIcon);
        Vue.component('p-animated-error-icon', AnimatedErrorIcon);
        Vue.component('p-alert-items-table-cell', AlertItemsTableCell);
        Vue.component('p-application-availability-status-column', ApplicationAvailabilityStatusColumn);
        Vue.component('p-expando', Expando);
        Vue.component('p-expando-x', ExpandoX);
        Vue.component('p-accordion', Accordion);
        Vue.component('p-dynamic-title', DynamicTitle);
        Vue.component('p-alert', Alert);
        Vue.component('p-application-list', ApplicationList);
        Vue.component('p-app-link', AppLink);
        Vue.component('p-attribute-key-value', AttributeKeyValue);
        Vue.component('p-attribute-list', AttributeList);
        Vue.component('p-attribute-list-item', AttributeListItem);
        Vue.component('p-avatar', Avatar);
        Vue.component('p-badge', Badge);
        Vue.component('p-banner', Banner);
        Vue.component('p-breadcrumb', Breadcrumb);
        Vue.component('p-breadcrumb-item', BreadcrumbItem);
        Vue.component('p-button', Button);
        Vue.component('p-button-group', ButtonGroup);
        Vue.component('p-card', Card);
        Vue.component('p-circle', Circle);
        Vue.component('p-checkbox', Checkbox);
        Vue.component('p-color-picker', ColorPicker);
        Vue.component('p-column', Column);
        Vue.component('p-concat', Concat);
        Vue.component('p-condition-picker', ConditionPicker);
        Vue.component('p-copy-script', CopyScript);
        Vue.component('p-countermeasure-output', CountermeasureOutput);
        Vue.component('p-countermeasure-details-drawer', CountermeasureDetailsDrawer);
        Vue.component('p-countermeasure-history-drawer', CountermeasureHistoryDrawer);
        Vue.component('p-custom-table-search', CustomTableSearch);
        Vue.component('p-data-point', DataPoint);
        Vue.component('p-data-point-btn', DataPointButton);
        Vue.component('p-data-point-info', DataPointInfo);
        Vue.component('p-description-list', DescriptionList);
        Vue.component('p-description-list-item', DescriptionListItem);
        Vue.component('p-details-drawer', DetailsDrawer);
        Vue.component('p-divider', Divider);
        Vue.component('p-label-divider', LabelDivider);
        Vue.component('p-drawer', Drawer);
        Vue.component('p-drawer-active-incident-list', DrawerActiveIncidentList);
        Vue.component('p-drawer-active-incident-list-item', DrawerActiveIncidentListItem);
        Vue.component('p-drawer-device-health-list', DrawerDeviceHealthList);
        Vue.component('p-drawer-graph-list', DrawerGraphList);
        Vue.component('p-drawer-active-incident-list-item-advanced', DrawerActiveIncidentListItemAdvanced);
        Vue.component('p-drawer-active-maint-list', DrawerActiveMaintList);
        Vue.component('p-drawer-active-maint-list-item', DrawerActiveMaintListItem);
        Vue.component('p-drawer-scheduled-maint-list', DrawerScheduledMaintList);
        Vue.component('p-drawer-scheduled-maint-list-item', DrawerScheduledMaintListItem);
        Vue.component('p-section', Section);
        Vue.component('p-dual-listbox', DualListbox);
        Vue.component('p-edittext', EditText);
        Vue.component('p-edit-countermeasure', EditCountermeasure);
        Vue.component('p-field-group', FieldGroup);
        Vue.component('p-field-row', FieldRow);
        Vue.component('p-filter', Filter);
        Vue.component('p-filter-builder', FilterBuilder);
        Vue.component('p-filter-single', FilterSingle);
        Vue.component('p-filter-menu', FilterMenu);
        Vue.component('p-flyout', Flyout);
        Vue.component('p-flyout-menu', FlyoutMenu);
        Vue.component('p-flex', Flex);
        Vue.component('p-gauge', Gauge);
        Vue.component('p-widget-geomap', WidgetGeomap);
        Vue.component('p-geomap', Geomap);
        Vue.component('p-geomap-agent-popup', GeomapAgentPopup);
        Vue.component('p-geomap-agent-cluster-popup', GeomapAgentClusterPopup);
        Vue.component('p-graph-unit-select', GraphUnitSelect);
        Vue.component('p-grouped-menu', GroupedMenu);
        Vue.component('p-header', Header);
        Vue.component('p-header-nav', HeaderNav);
        Vue.component('p-hint', Hint);
        Vue.component('p-html-editor', HTMLEditor);
        Vue.component('p-icon', Icon);
        Vue.component('p-incident-details-graph', IncidentDetailsGraphComponent);
        Vue.component('p-input', Input);
        Vue.component('p-install-agent-link-menu', InstallAgentLinkMenu);
        Vue.component('p-instance-icon', InstanceIcon);
        Vue.component('p-integration-json', IntegrationJSONFields);
        Vue.component('p-integration-sidebar', IntegrationSidebar);
        Vue.component('p-link', Link);
        Vue.component('p-list-selectbox', ListSelectbox);
        Vue.component('p-list-toggle', ListToggle);
        Vue.component('p-loading-spinner', LoadingSpinner);
        Vue.component('p-loading-spinner-2', LoadingSpinner2);
        Vue.component('p-logical-operator-picker', LogicalOperatorPicker);
        Vue.component('p-markdown', Markdown);
        Vue.component('p-masked-input', MaskedInput);
        Vue.component('p-material-menu', MaterialMenu);
        Vue.component('p-menu', Menu);
        Vue.component('p-metric-text', MetricText);
        Vue.component('p-modal', Modal);
        Vue.component('p-nav-menu', NavMenu);
        Vue.component('p-node-map', NodeMap);
        Vue.component('p-number-input', NumberInput);
        Vue.component('p-page-header', PageHeader);
        Vue.component('p-panel', Panel);
        Vue.component('p-panels', Panels);
        Vue.component('p-portal-target', PortalTarget);
        Vue.component('p-port-selection-drawer', PortSelectionDrawer);
        Vue.component('p-primary-menu', PrimaryMenu);
        Vue.component('p-progress-bar', ProgressBar);
        Vue.component('p-prompt', Prompt);
        Vue.component('p-prompt-drawer', PromptDrawer);
        Vue.component('p-quick-add-multi-select', QuickAddMultiSelect);
        Vue.component('p-radiomenu', RadioMenu);
        Vue.component('p-radio-options', RadioOptions);
        Vue.component('p-row', Row);
        Vue.component('p-search', Search);
        Vue.component('p-secondary-menu', SecondaryMenu);
        Vue.component('p-select', SelectMenu);
        Vue.component('p-service-selection-drawer', ServiceSelectionDrawer);
        Vue.component('p-severity-badge', SeverityBadge);
        Vue.component('p-side-bar', SideBar);
        Vue.component('p-cloud-discovery-servers-drawer', CloudDiscoveryServersDrawer);
        Vue.component('p-sub-menu', SubMenu);
        Vue.component('p-switch', Switch);
        Vue.component('p-synthetic-realtime', SyntheticRealtime);
        Vue.component('p-synthetic-checks-waterfall', SyntheticChecksWaterfall);
        Vue.component('p-synthetic-results', SyntheticResults);
        Vue.component('p-synthetic-screenshots', SyntheticScreenshots);
        Vue.component('p-synthetics-table', SyntheticsTable);
        Vue.component('p-tab', Tab);
        Vue.component('p-tab-list', TabList);
        Vue.component('p-tab-panel', TabPanel);
        Vue.component('p-tab-panels', TabPanels);
        Vue.component('p-tabs', Tabs);
        Vue.component('p-table2', Table2);
        Vue.component('p-table', Table);
        Vue.component('p-table-cell', TableCell);
        Vue.component('p-table-head', TableHead);
        Vue.component('p-tag', Tag);
        Vue.component('p-tag-list', TagList);
        Vue.component('p-tags-input', TagsInput);
        Vue.component('p-text', Text);
        Vue.component('p-template-list', TemplateList);
        Vue.component('p-template-list-item', TemplateListItem);
        Vue.component('p-timerange-filter', TimeRangeFilter);
        Vue.component('p-tip-card', TipCard);
        Vue.component('p-threshold', Threshold);
        Vue.component('p-toast', Toast);
        Vue.component('p-tooltip', Tooltip);
        Vue.component('p-tooltip2', Tooltip2);
        Vue.component('p-internaladminicon', InternalAdminIcon);
        Vue.component('p-traceroute', Traceroute);
        Vue.component('p-tree', Tree);
        Vue.component('p-tree-2', Tree2);
        Vue.component('p-typeahead-select', TypeAheadSelect);
        Vue.component('p-ui-filter', UIFilter);
        Vue.component('p-up-down', UpDown);
        Vue.component('p-vue-table', VueTable);
        Vue.component('p-wizard', Wizard);
        Vue.component('p-wizard-pane', WizardPane);
        Vue.component('p-wizard-tree', WizardTree);
        Vue.component('p-graph', GraphComponent);
        Vue.component('p-graph-container', GraphContainerComponent);
        Vue.component('p-status-icon', StatusIcon);
        Vue.component('p-status-table', StatusTable);
        Vue.component('p-sub-account-info', SubAccountInfo);
        Vue.component('p-datetime', DatetimePicker);
        Vue.component('p-heatmap', Heatmap);
        Vue.component('p-time', Time);
        Vue.component('p-stat-bar', StatBar);
        Vue.component('p-chip', Chip);
        Vue.component('p-chip-list', ChipList);
        Vue.component('p-timescale', Timescale);
        Vue.component('p-toggle-chip', ToggleChip);
        Vue.component('p-toggle-chip-list', ToggleChipList);
        Vue.component('p-tooltip-button-group', TooltipButtonGroup);
        Vue.component('p-snmp-custom-rule', SNMPCustomRule);
        Vue.component('p-vue-tables-pagination', VueTablesPagination);

        Vue.component('p-auth', Auth);

        Vue.component('p-side-nav', SideNav);
        Vue.component('p-secondary-nav', SecondaryNav);

        Vue.component('p-dashboard-switcher', DashboardSwitcher);
        Vue.component('p-dashboard-properties-drawer', DashboardPropertiesDrawer);
        Vue.component('p-metric-module', MetricModule);

        Vue.component('p-template-block', ApplyRule);

        Vue.component('p-monitoring-catalog-2', MonitoringCatalog2);
        Vue.component('p-monitoring-catalog', MonitoringCatalog);
        Vue.component('p-monitoring-catalog-sd-wan', MonitoringCatalogSdWan);

        Vue.component('p-application-status-flyout', ApplicationStatusFlyout);

        Vue.component('p-create-group', CreateGroup);
        Vue.component('p-splash-onboarding', Splash);
        Vue.component('p-onboarding-action-plan', OnboardingActionPlan);
        Vue.component('p-onboarding-path', OnboardingPath);
        Vue.component('p-onboarding-steps', OnboardingSteps);
        Vue.component('p-onboarding-step', OnboardingStep);
        Vue.component('p-onsight-installation', OnSightInstallation);
        Vue.component('p-third-party-path', ThirdPartyPath);

        Vue.component('p-incident-table', IncidentTable);

        Vue.component('p-acknowledge-drawer-upcoming-alert-table-col', AcknowledgeDrawerUpcomingAlertTableCol);
        Vue.component('p-alert-group-drawer', AlertGroupDrawer);

        Vue.component('p-create-server-template', CreateServerTemplate);

        Vue.component('p-extend-maintenance-modal', ExtendMaintenanceModal);
        Vue.component('p-instance-performance', InstancePerformance);
        Vue.component('p-instance-performance-graphs', InstancePerformanceGraphs);
        Vue.component('p-instance-network-port-graphs', InstanceNetworkPortGraphs);
        Vue.component('p-server-tab-table-col', ServerTabTableCol);

        Vue.component('p-policy-wokflow-header', PolicyWorkflowHeader);

        Vue.component('p-role-activities', RoleActivities);

        Vue.component('p-add-sd-wan-checks', AddSdWanChecks);
        Vue.component('p-delete-infra-modal', DeleteInfraModal);
        Vue.component('p-edit-server-group', EditServerGroup);
        Vue.component('p-list-instances-drawer', ListInstancesDrawer);
        Vue.component('p-maintenance', Maintenance);

        Vue.component('p-edit-user', EditUser);
        Vue.component('p-edit-rotating-schedule-header', EditRotatingScheduleHeader);
        Vue.component('p-switch-customer', SwitchCustomer);

        Vue.directive('p-dialog-close', dialogClose);
        Vue.directive('p-dialog-open', dialogOpen);
        Vue.directive('p-drawer-open', drawerOpen);
        Vue.directive('p-drawer-close', drawerClose);
        Vue.directive('p-filterable-element', filterableElement);
        Vue.directive('p-flyout-open', flyoutOpen);
        Vue.directive('p-flyout-trigger', flyoutTrigger);
        Vue.directive('p-hover-text', hoverText);
        Vue.directive('p-is-focused', isFocused);
        Vue.directive('p-on-focusout', onFocusout);
        Vue.directive('p-prompt-open', promptOpen);
        Vue.directive('p-admin-icon', adminIcon);

        Vue.use(PiniaPlugin);

        Vue.use(RouterPlugin);
        Vue.use(PortalVue, {
            portalName: 'p-portal',
            MountingPortalName: 'p-mounting-portal',
        });

        // Remove when all Vue 2
        Vue.directive('el', (el, binding, vnode) => {
            console.debug('Monkey-patching v-el');
            const vm = vnode.componentInstance;
            if (!vm) { return; }
            if (!binding.arg in vm.$refs) {
                vm.$refs[binding] = el;
            }
        });
        Vue.directive('ref', (el, binding, vnode) => {
            console.debug('Monkey-patching v-ref');
            const vm = vnode.componentInstance;
            if (!vm) { return; }
            const parent = vm.$parent;
            if (!parent) { return; }
            if (!binding.arg in parent.$refs) {
                parent.$refs[binding] = vm;
            }
        });

        Vue.mixin({
            methods: {
                compile: function (input) {
                    if (typeof input === 'string') {
                        // TODO
                        return;
                    } else {
                        const newVue = new Vue({
                            el: input,
                            parent: this,
                        });
                        return newVue;
                    }
                },

                $compile: function (input) {
                    return this.compile(input);
                },

                $broadcast: function (name, ...values) {
                    this.eventHub.$emit(name, ...values);
                },

                compileEl: function (el) {
                    if (!el) {
                        return;
                    }

                    if (!(el instanceof HTMLElement)) {
                        return;
                    }

                    const tmp = el.innerHTML;
                    const div = document.createElement("div");

                    el.innerHTML = "";
                    el.appendChild(div);

                    const Component = Vue.extend({
                        template: tmp,
                    });

                    new Component().$mount(div);
                },
            },

            computed: {
                $els() {
                    return this.$refs;
                },
            },

            created() {
                this.eventHub = window.app.eventHub;

                if (typeof this.$options.events === 'object') {
                    for (const [key, handler] of Object.entries(this.$options.events)) {
                        this.eventHub.$on(key, handler.bind(this));
                    }
                }
                for (const mixin of this.$options.mixins || []) {
                    if (mixin.events) {
                        for (const [key, handler] of Object.entries(mixin.events)) {
                            this.eventHub.$on(key, handler.bind(this));
                        }
                    }
                }
            },

            mounted() {
                if (typeof this.$options.vueReady === 'function') {
                    Vue.nextTick(this.$options.vueReady.bind(this));
                }
            },
        });
    }

    setupGlobal() {
        bindGlobalClickListeners();
    }
}
