<template>
    <div
        tabindex="-1"
        class="pa-flyout"
        :class="[ positioning, { isActive: active, 'pa-flyout--rounded': rounded }]"
        v-on:keydown.27="_onKeydown($event)">
        <span class="pa-flyout-box" v-on:mouseleave="_onMouseLeaveFlyout()">
            <div v-if="hover">
                <button
                    ref="trigger"
                    class="pa-flyout-box-btn"
                    type="button"
                    tabindex="-1"
                    v-on:mouseenter="onHover()"
                    v-on:click="hoverOnClick">
                    <slot name="trigger"></slot>
                </button>
            </div>
            <div v-else>
                <button
                    ref="trigger"
                    class="pa-flyout-box-btn"
                    type="button"
                    tabindex="-1"
                    v-on:click="_onClick($event)">
                    <slot name="trigger"></slot>
                </button>
            </div>
            <div
                class="pa-flyout-box-content"
                ref="content"
                :class="[
                    idirection || direction,
                    !noArrow ? 'arrow-' + arrowPercent : '',
                    variant ? `pa-flyout-box-content--${variant}` : '',
                ]"
                :style="{
                    width: width === 'auto'
                        ? 'auto'
                        : width + 'px',
                    transform: noTransform ? 'none' : ''
                }">
                <div v-if="loading" class="pa-loader"></div>

                <svg v-if="stubborn" @click="close" class="pa-icon close">
                    <use xlink:href="#close"></use>
                </svg>

                <div class="content-slot" ref="contentSlot">
                    <slot name="content"></slot>
                </div>

                <div v-if="loadedAsync" ref="asyncContent"></div>

                <div class="pa-flyout-hitbox"></div>

                <div v-if="positioning === 'popper' && !noArrow" class="arrow"></div>
            </div>
        </span>
    </div>
</template>

<script>
    import createScrollLocker from '../utils/scrollLocker';
    import isComponentInModal from '../utils/isComponentInModal';
    import Vue from 'vue';
    import Popper from 'popper.js';

    let COUNT = 0;
    let BODY_TOP = 0;

    const Flyout = Vue.extend({
        events: {
            'flyout-opening': function(flyoutId) {
                if (this.id === flyoutId) {
                    return;
                }
                this.close();
            },
            'flyout:trigger': function(flyoutId, trigger, onOpen) {
                if (this.id !== flyoutId) {
                    return;
                }
                if (onOpen) {
                    onOpen();
                }
                Vue.nextTick(() => {
                    this.open(trigger);
                });
            },
            'flyout:maybeClose': function(flyoutId, event) {
                if (this.id !== flyoutId) {
                    return;
                }
                if (!this.hover) { return; }
                if (!this.$el) { return; }
                const target = event.relatedTarget;
                const flyoutContainsTarget = this.$el.contains(target);

                if (flyoutContainsTarget) {
                    return;
                }
                this.closeWithDelay();
            },
        },

        props: {
            id: {
                type: String,
                'default': function() {
                    return `flyout_${COUNT++}`;
                },
            },
            isActive: {
                type: Boolean,
                'default': false,
            },
            hover: {
                type: Boolean,
                'default': false,
            },
            width: {
                type: [Number, String],
                'default': 200,
            },
            waitTime: {
                type: Number,
                'default': 0,
            },
            hoverTimeout: {
                type: Number,
                'default': 900,
            },
            direction: {
                type: String,
                'default': 'right',
            },
            arrowPercent: {
                type: Number,
                'default': 50,
            },
            leftMargin: {
                type: Number,
                'default': 0,
            },
            positioning: {
                type: String,
                'default': 'fixed',
            },
            hoverOnClick: {
                type: Function,
                'default': () => {},
            },
            onOpen: {
                type: Function,
                'default': () => {},
            },
            onClose: {
                type: Function,
                'default': () => {},
            },
            popperDirection: {
                type: String,
                default: 'right-start',
            },
            url: String,
            dynamicPositioning: Boolean,
            stubborn: Boolean,
            lockScroll: Boolean,
            noTransform: Boolean,
            debug: Boolean,
            rounded: Boolean,
            dynamicHeight: Boolean,
            noArrow: Boolean,
            offset: {
                type: String,
                default: '-10%p, 5px',
            },
            filterSelectors: {
                type: Array,
                'default': () => [],
            },
            variant: {
                type: String,
                default: "",
            },
        },

        data() {
            return {
                waitTimer: null,
                loading: false,
                loadedAsync: false,
                contentUrl: '',
                childVue: null,
                scrollLocker: null,
                mutationObserver: null,
                tempStylesheet: null,
                idirection: null,
                active: this.isActive,
            };
        },

        methods: {
            onHover() {
                if (this.waitTime > 0) {
                    this.waitTimer = setTimeout(this.open, this.waitTime);
                    this.$refs.trigger.addEventListener('mouseleave', (event) => {
                        clearTimeout(this.waitTimer);
                    });
                } else {
                    this.open();
                }
            },
            load(data, callback) {
                this.loading = true;
                this.loadedAsync = true;
                const options = {
                    url: this.contentUrl,
                    method: 'get',
                };
                if (data) {
                    options.data = data;
                }
                return $.ajax(options).then(html => {
                    this._insertBody(html);
                    this.loading = false;
                    if (typeof callback === 'function') {
                        callback();
                    }
                });
            },
            _insertBody(html) {
                this.loadedAsync = true;
                Vue.nextTick(() => {
                    this.$refs.asyncContent.innerHTML = html;

                    Vue.nextTick(() => {
                        const scripts = this.$refs.asyncContent.getElementsByTagName('SCRIPT');
                        for (const origScriptTag of scripts) {
                            origScriptTag.parentNode.removeChild(origScriptTag);
                            const newScriptEl = document.createElement('script');
                            const script = document.createTextNode(origScriptTag.textContent);
                            newScriptEl.appendChild(script);
                            document.head.appendChild(newScriptEl);
                        }
                    });
                });
            },
            open(trigger) {
                if (this.contentUrl) {
                    this.load();
                }
                if (window.app && window.app.rootVue) {
                    this.eventHub.$emit('flyout-opening', this.id);
                }

                if (this.scrollLocker) {
                    this.scrollLocker.on();
                    this.eventHub.$emit('scroll-lock:on', this)
                }

                this.active = true;
                if (this.positioning === 'popper') {
                    let currentTrigger = this.$els.trigger;
                    if (trigger) {
                        currentTrigger = trigger;
                    }

                    const popperConfig = {
                        placement: this.popperDirection,
                        modifiers: {
                            arrow: {
                                element: '.arrow',
                            },
                            offset: {
                                offset: this.offset,
                            },
                            keepTogether: {
                            },
                            preventOverflow: {
                                padding: 20,
                            },
                        },
                    };

                    if (this.$els.content) {
                        this.popper = new Popper(currentTrigger, this.$els.content, popperConfig);
                    }
                } else if (this.positioning === 'fixed') {
                    Vue.nextTick(() => {
                        let content = this.$refs.content;
                        if (this.loadedAsync) {
                            content = this.$refs.asyncContent;
                        }

                        let contentHeight = 0;
                        for (let i = 0; i < content.children.length; i++) {
                            contentHeight +=
                                content.children[i].getBoundingClientRect().height;
                        }
                        // For padding
                        contentHeight += 20;

                        const triggerRect = this.$refs.trigger.getBoundingClientRect();

                        let direction = this.direction;

                        if (this.dynamicPositioning) {
                            switch (direction) {
                                case 'left':
                                    if (triggerRect.left < this.width) {
                                        direction = 'right';
                                    }
                                    break;
                                case 'right':
                                    if (triggerRect.left + triggerRect.width > window.innerWidth - this.width) {
                                        direction = 'left';
                                    }
                                    break;
                                case 'top':
                                    // TODO
                                    break;
                                case 'bottom':
                                    // TODO
                                    break;
                            }
                        }

                        this.idirection = direction;

                        if (direction === 'top') {
                            const left = triggerRect.left + (triggerRect.width / 2);
                            const top = triggerRect.top;
                            this.$refs.content.style.left = `${left}px`;
                            this.$refs.content.style.top = `${top}px`;
                        } else if (direction === 'bottom') {
                            let left = triggerRect.left + (triggerRect.width / 2) + this.leftMargin;
                            let top = triggerRect.top + (triggerRect.height);

                            if (this.noTransform) {
                                left -= this.width / 2;
                            }

                            this.$refs.content.style.left = `${left}px`;
                            this.$refs.content.style.top = `${top}px`;
                        } else if (direction === 'left') {
                            const left = triggerRect.left;
                            const top = triggerRect.top;
                            this.$refs.content.style.left = `${left}px`;
                            this.$refs.content.style.top = `${top}px`;
                        } else if (direction === 'right') {
                            const left = triggerRect.left + triggerRect.width;
                            const top = triggerRect.top + (triggerRect.height / 2);
                            this.$refs.content.style.left = `${left}px`;
                            this.$refs.content.style.top = `${top}px`;
                        }
                    });
                } else if (this.positioning === 'advanced') {
                    Vue.nextTick(() => {
                        let content = this.$refs.content;
                        if (this.loadedAsync) {
                            content = this.$refs.asyncContent;
                        }

                        let contentHeight = 0;
                        for (let i = 0; i < content.children.length; i++) {
                            contentHeight +=
                                content.children[i].getBoundingClientRect().height;
                        }
                        // For padding
                        contentHeight += 20;

                        const triggerLeft = this.$refs.trigger.offsetLeft;
                        const triggerTop = this.$refs.trigger.offsetTop;
                        const triggerRect = this.$refs.trigger.getBoundingClientRect();
                        let left = 0;
                        let top = 0;
                        if (this.direction === 'top') {
                            left = triggerLeft + (triggerRect.width / 2);
                            top = triggerTop - contentHeight - (triggerRect.height / 2) - 5;
                        } else if (this.direction === 'right') {
                            left = triggerLeft + (triggerRect.width / 2) + 20;
                            top = triggerTop - (triggerRect.height / 2) - 10;
                        }
                        this.$refs.content.style.height = `${contentHeight}px`;
                        this.$refs.content.style.left = `${left}px`;
                        this.$refs.content.style.top = `${top}px`;
                    });
                }

                document.body.addEventListener('click', this._onDocumentClick);
                document.body.addEventListener('focus', this._onFocusLeaveFlyout, true);

                if (this.dynamicPositioning || this.positioning === 'popper') {
                    if (!this.mutationObserver) {
                        this.mutationObserver = new MutationObserver(_.throttle(this._onMutate, 50));
                    }
                    let target = this.$refs.contentSlot;
                    if (this.loadedAsync) {
                        target = this.$refs.asyncContent;
                    }
                    if (target) {
                        this.mutationObserver.observe(target, {
                            attributes: true,
                            childList: true,
                            subtree: true,
                        });
                    }
                }

                this.onOpen();
            },

            reposition() {
                if (!this.$refs.content) { return; }
                if (this.positioning === 'popper' && this.popper) {
                    this.popper.scheduleUpdate();
                }
                const contentBox = this.$refs.content.getBoundingClientRect();
                const currentTop = Number(this.$refs.content.style.top.replace('px', ''));
                const contentTop = contentBox.top;
                const contentBottom = contentTop + contentBox.height;

                let difference = 0;
                let newTop = null;
                if (contentTop < BODY_TOP) {
                    difference = BODY_TOP - contentTop;
                    newTop = currentTop + difference + 10;
                } else if (contentBottom > window.innerHeight) {
                    difference = contentBottom - window.innerHeight;
                    newTop = currentTop - difference - 10;
                }

                    this.$refs.content.style.top = `${newTop}px`;

                    if (!this.tempStylesheet) {
                        this.tempStylesheet = document.createElement('STYLE');
                        document.head.appendChild(this.tempStylesheet);
                    }
                    for (let i = 0; i < this.tempStylesheet.sheet.rules.length; i++ ) {
                        this.tempStylesheet.sheet.deleteRule(i);
                    }
                    const triggerBox = this.$refs.trigger.getBoundingClientRect();
                    const offset = triggerBox.top + (triggerBox.height / 2) - contentBox.top;
                    const newPercent = (offset / contentBox.height) * 100;
                    const selector = '.pa-flyout.isActive .pa-flyout-box-content::before,'
                        + ' .pa-flyout.isActive .pa-flyout-box-content::after';
                    this.tempStylesheet.sheet.insertRule(`${selector} { top: ${newPercent}% !important; }`, 0);
            },

            close() {
                if (this.debug) { return; }

                if (this.scrollLocker) {
                    this.scrollLocker.off();
                    this.eventHub.$emit('scroll-lock:off', this);
                }

                this.active = false;

                if (window.app && window.app.flyoutVue) {
                    window.app.flyoutVue.$destroy(true);
                }

                if (this.$refs.asyncContent) {
                    this.$refs.asyncContent.innerHTML = '';
                }

                document.body.removeEventListener('click', this._onDocumentClick);
                document.body.removeEventListener('focus', this._onFocusLeaveFlyout, true);

                if ((this.dynamicPositioning || this.positioning === 'popper') && this.mutationObserver) {
                    this.mutationObserver.disconnect();
                    if (this.tempStylesheet) {
                        document.head.removeChild(this.tempStylesheet);
                        this.tempStylesheet = null;
                    }
                }

                this.onClose();
            },

            closeWithDelay() {
                window.setTimeout(this.close, this.hoverTimeout);
            },

            toggle() {
                const active = !this.active;

                if (active) {
                    this.open();

                    return;
                }

                this.close();
            },

            _onClick($event) {
                this.toggle();
            },

            _onDocumentClick(event) {
                let isWithinCalendarFlyout = false;
                document.querySelectorAll('.flatpickr-calendar').forEach((calendarEl) => {
                    if (calendarEl.contains(event.target)) {
                       isWithinCalendarFlyout = true;
                    }
                });
                if (isWithinCalendarFlyout) {
                    return;
                }
                if (!this.$el) { return; }
                const target = event.target;
                const flyoutContainsTarget = this.$el.contains(target);
                const matchesSelector = false;
                for (let i = 0; i < this.filterSelectors.length; i++) {
                    if (target.matches(this.filterSelectors[i])) {
                        matchesSelector = true;
                        break;
                    }
                }

                if (flyoutContainsTarget || matchesSelector || this.stubborn) {
                    return;
                }

                this.close();
            },

            _onFocusLeaveFlyout(event) {
                return ;
                if (!this.hover) { return; }
                if (!this.$el) { return; }
                const target = event.target;
                const flyoutContainsTarget = this.$el.contains(target);

                if (flyoutContainsTarget) {
                    return;
                }

                this.closeWithDelay();
            },

            _onMouseLeaveFlyout(event) {
                if (!this.hover || this.stubborn) { return; }
                this.closeWithDelay();
            },

            _onKeydown($event) {
                this.close();
            },

            _onMutate(mutationsList) {
                this.reposition();
            },
        },

        vueReady() {
            if (this.url) {
                this.contentUrl = this.url;
            }
            if (this.lockScroll) {
                const inModal = isComponentInModal(this);
                this.scrollLocker = createScrollLocker({ inModal: inModal });
            }
            if (this.positioning !== 'popper') {
                const body = document.querySelector('.pa-page-bd');
                if (body) {
                    BODY_TOP = body.getBoundingClientRect().top;
                }
            }
        },
    });

    export default Flyout;
</script>
