export const BASE_ROUTE = "index";
export const NOT_FOUND_ROUTE = "not-found";
export const PATHNAME_PREFIX = "/onboarding/v2/";
// We have to add this prefix 'obf-option-' on to the ids to make them unique
// so that the radio buttons work with the labels. We dont need them for
// anything else so we remove them when needed.
export const INFRA_OPTION_PREFIX = "obf-option-";
export const INTEGRATION_OPTION_PREFIX = "obf-integ-option-";
export const SAVE_STATUSES = {
    IDLE: "idle",
    SAVED: "saved",
    UNSAVED: "unsaved",
};


export const FQDN_REGEX = /^(http(s)?:\/\/)?[a-z0-9-_]+(\.[a-z0-9-_]+)*(?:\.([a-z]{2,4})|\:(\d{2,5}))(?:\/[a-z0-9-_])*([a-z0-9-_=&\?])*\/?$/i;
export const IPV4_REGEX = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
export const IPV6_REGEX = /^(\w{1,4}\:|\w{1,4}::)+\w{1,4}$/;
export const REGIONS_ORDER = [
    "North America",
    "Europe / Middle East / Africa",
    "Asia Pacific",
    "South America",
    "Middle East",
];
export const JSON_DEFINITION = {
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "VisualEditor0",
            "Effect": "Allow",
            "Action": [
                "cloudwatch:List*",
                "cloudwatch:Describe*",
                "cloudwatch:Get*",
                "support:DescribeServices",
                "tag:GetTagValues",
                "tag:GetTagKeys",
                "tag:GetResources",
                "autoscaling:Describe*",
                "dynamodb:Describe*",
                "dynamodb:List*",
                "ec2:Get*",
                "ec2:Describe*",
                "elasticloadbalancing:Describe*",
                "elasticfilesystem:DescribeFileSystems",
                "elasticmapreduce:List*",
                "elasticmapreduce:Describe*",
                "elasticache:Describe*",
                "elasticache:List*",
                "elasticfilesystem:DescribeTags",
                "es:ListDomainNames",
                "es:ListTags",
                "es:DescribeElasticsearchDomains",
                "ecs:List*",
                "ecs:Describe*",
                "kinesis:Describe*",
                "kinesis:List*",
                "lambda:List*",
                "lambda:GetFunction",
                "rds:Describe*",
                "rds:List*",
                "redshift:DescribeEvents",
                "redshift:DescribeClusters",
                "route53:List*",
                "s3:GetBucketTagging",
                "s3:ListAllMyBuckets",
                "s3:GetBucketLocation",
                "ses:Get*",
                "sns:List*",
                "sqs:ListQueues",
                "sqs:ListQueueTags",
                "sqs:GetQueueAttributes",
                "sns:GetTopicAttributes",
                "fsx:DescribeFileSystems",
                "fsx:ListTagsForResource",
                "directconnect:DescribeConnections",
                "directconnect:DescribeVirtualInterfaces"
            ],
            "Resource": "*"
        }
    ]
};
export const IGNORED_SAVE_STATUS_PATHS = [
    "onsight",
];
