<style lang="scss">
    @import "~styles/core/helpers/_variables.scss";

    #edit-user-wrapper {
        max-height: 80vh;
        overflow: auto;

        .error {
            border-color: #D73C2C;
        }

        .disableCheckbox {
            color: #aaa;
        }

        .field-sub-header {
            font-weight: bold;
        }

        .pa-input {
            height: 40px;
        }

        .locked {
            overflow-y: hidden;
        }

        .pa-label-width {
           width: 200px;
        }

        .pa-hint_error {
            margin-left: 5px;
        }

        .pa-message {
            z-index: 11;
        }
    }

    .toast-top {
        z-index: 1020;
    }

    .tooltip-user-badge {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .tooltip-user-badge .tooltiptext {
        background: white;
        border-radius: 4px;
        color: #333333;
        font-size: 14px;
        transition: 400ms;
        margin: 5px;
        padding: 1rem;
        box-shadow: 0 2px 5px 1px rgba(86, 89, 90, 0.4);
        visibility: hidden;
        width: 17rem;
        text-align: left;
        opacity: 0;
        position: absolute;
        z-index: 1020;
    }

    .tooltip-user-badge:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    #secondary-nav-overflow {
        overflow: visible;
    }

    #warning>path {
        fill: #ffbb00;
    }

    .pa-edit-user-section {
        background-color: white;
        width: 100%;
        max-height: 85vh;

        &.pa-modal-section {
            width: 900px;
        }

        .pa-edit-user-header {
            margin-top: 10px;

            &.pa-modal-header {
                margin-top: -37px;
            }

            .active-tab-name {
                color: #394449;
                font-family: Inter;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 20px;
            }
        }

        .close-button {
            height: 48px;
            width: 48px;
            background-color: #FFFFFF;
            box-shadow: 0 4px 4px 0 rgba(134,134,134,0.5);
            border: none;
            position: relative;
            top: -27px;

            .pa-icon {
                color: #666666;
                fill: #666666;
            }
        }

        .close-button-left {
            left: 876px;
        }

        .button-naked {
            background-color: #FFFFFF;
            border: none;
            cursor: pointer;
        }

        .add-button {
            color: var(--p-blue-500, #3954bf);
        }

        .info-text {
            color: #000000;
            font-family: Inter;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 16.94px;
        }

        .iam-edit-forticloud-link {
            color: #3954BF;
            font-family: Inter;
            font-size: 14px;
            letter-spacing: 0;
            fill: #3954BF !important;
            // line-height: 16.94px;
        }

    //    Permission Tab
        .label-text {
            color: #000000;
            font-family: Inter;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16.94px;
        }

        .pa-no-permission-message {
            box-sizing: border-box;
            height: 58px;
            border: 1.5px solid #F3BB2D;
            background-color: #FFF3CD;
        }

        .warning-text {
            color: #000000;
            font-family: Inter;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
        }

        .pa-permission-roles-header {
            color: #666666;
            font-family: Inter;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16.94px;
        }

        .user-section-error-list {
            margin-left: 30px;
            list-style: initial;
        }
    }

    @media screen and (min-height: 951px) and (max-height: 1200px)  {
        #edit-user-wrapper {
            max-height: 79vh;
        }
    }

    @media screen and (min-height: 881px) and (max-height: 950px) {
        #edit-user-wrapper {
            max-height: 78vh;
        }
    }

    @media screen and (min-height: 0) and (max-height: 880px)  {
        #edit-user-wrapper {
            max-height: 77vh;
        }
    }

</style>

<template>
    <p-flex>
        <p-secondary-nav
            id="secondary-nav-overflow"
            :title-ext="navTitle"
            :context-header="{
                title: !title_name?'New User':title_name,
                titleBadge: access_badge,
                subTitle: lastLoginDate,
                tertiaryTitle: createdOnDate,
                ...(avatarComputed.icon !== 'default-photo' ? {
                        image: avatarComputed.icon,
                    } : {
                        icon: avatarComputed.icon || 'default-photo',
                    })
            }"
            :nav-items="nav_items"
            @item:active="handleItemActive"
        >
        </p-secondary-nav>

        <section class="pa-edit-user-section" :class="isModal ? 'pa-modal-section' : ''">
            <button v-if="isModal" ref="closeBtn" @click="close" class="close-button pa-rounded-full close-button-left">
                <svg class="pa-icon pa-icon_xxxxl">
                    <use xlink:href="#close"></use>
                </svg>
            </button>

            <div class="pa-flex pa-space-between pa-align-center pa-px-20 pa-edit-user-header" :class="isModal ? 'pa-modal-header' : ''">
                <h3 class="active-tab-name">{{activeTabName}}</h3>
                <button ref="saveBtn" @click="submit_form" class="pa-btn pa-btn_blue" :class="isModal ? 'pa-mr-24' : ''">Save</button>
            </div>


            <p-divider></p-divider>


            <div id="edit-user-wrapper">
                <div v-if="error_message" class="pa-message pa-message_error3 pa-message_sticky pa-mx-20 pa-mb-0">
                    <span>{{ error_message }}</span>
                    <ul class="ma-mt-4">
                        <li v-for="error in errorListObject" class='user-section-error-list pa-py-4'>{{error.name}} in {{ error.tabName }} </li>
                    </ul>
                </div>
                <p-prompt id="dynamic_prompt"></p-prompt>

                <div v-if="activeTab=='general'">
                    <div class="pa-section">
                        <ul class="pa-vList pa-vList_x4">
                        <p-text font-size="xs" class="pa-mb-16 pa-pb-12 pa-txt_secondary">
                            *Indicates mandatory fields

                        </p-text>
                            <li v-if="!is_secondary_user">
                                <div class="pa-flex pa-align-center">
                                    <div class="pa-field-hd">
                                        <label for="fullname" class="pa-label pa-label-width" required>Name<span class="required">*</span></label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <input type="text" class="pa-input" id="fullname" placeholder="Enter Name..." v-model="fullname" />
                                    </div>
                                    <p class="pa-hint pa-hint_error">
                                        {{ errors.error__fullname }}
                                    </p>
                                </div>
                            </li>
                            <li v-else>
                                <div class="pa-flex pa-align-center">
                                    <div class="pa-field-hd">
                                        <label class="pa-label pa-label-width" for="secondary_user_email">Email Address*</label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <template v-if="userId">
                                            <h2 class="pa-hdg pa-hdg_2" style="font-size:14px;">{{ secondary_user_email }}</h2>
                                        </template>
                                        <template v-else>
                                            <input type="text" class="pa-input" id="secondary_user_email" v-model="secondary_user_email" :disabled="skip_activation_email" />
                                        </template>
                                    </div>
                                </div>
                                <p class="pa-hint pa-hint_error">
                                    {{ errors.error__secondary_user_email }}
                                </p>

                                <div v-if="!userId" class="pa-flex pa-align-center" style="margin-top:-1.5rem;">
                                    <div class="pa-field-hd">
                                        <label class="pa-label pa-label-width"></label>
                                    </div>
                                    <div class="pa-field-hd">
                                        <label class="pa-option">
                                            <input type="checkbox" class="pa-option-input" v-model="skip_activation_email"/>
                                            <span class="pa-option-icon"></span>
                                            <span class="pa-option-txt">
                                                    Skip invitation email
                                            </span>
                                        </label>
                                        <p class="pa-hint">
                                            If selected, the user will not receive the activation email.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="pa-flex pa-align-center">
                                    <label class="pa-label pa-label-width">Avatar</label>
                                    <div>
                                        <p-application-image-uploader
                                        class="pa-label"
                                        :application="avatarComputed"
                                        >
                                        </p-application-image-uploader>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="pa-flex pa-align-center">
                                    <label for="timezone" class="pa-label pa-label-width">Timezone<span class="required">*</span></label>
                                    <p-select :model.sync="timezone" :optgroups="timezone_options" :searchable="true"></p-select>
                                    <p class="pa-hint pa-hint_error">
                                        {{ errors.error__timezone }}
                                    </p>
                                </div>
                            </li>

                            <li v-if="account_type != 'contact'">
                                <div class="pa-flex pa-align-center">
                                    <label for="dashboards_access" class="pa-label pa-label-width">Dashboard Access</label>
                                    <div class="pa-split" style="margin: 0;">
                                        <p-select :model.sync="dashboard_access_type" defaulttext="Select..." :options="[{value: 'all', label: 'All'}, {value: 'select', label: 'Select'}]"></p-select>
                                        <p-select v-if="dashboard_access_type === 'select'" :model.sync="select_dashboards" :options="dashboard_options" multiple></p-select>
                                    </div>
                                    <p v-if="errors.error__dashboard_access" v-html="errors.error__dashboard_access" class="pa-hint pa-hint_error pa-ml-5"></p>
                                </div>
                            </li>

                            <li v-if="account_type != 'contact'">
                                <div class="pa-flex pa-align-center">
                                    <label for="server_group_access" class="pa-label pa-label-width">Default Dashboard</label>
                                    <p-select :model.sync="default_dashboard" defaulttext="Select..." :options="selectDashboardOptions"></p-select>
                                    <p v-if="errors.error__default_dashboard" v-html="errors.error__default_dashboard" class="pa-hint pa-hint_error pa-ml-5"></p>
                                </div>
                            </li>

                            <li v-if="account_type != 'contact'">
                                <div class="pa-flex pa-align-center">
                                    <label for="server_group_access" class="pa-label pa-label-width">Tags</label>
                                    <p-tags-input :model.sync='tags' :autocomplete="true" input-class="xxl" style="width: 76%"></p-tags-input>
                                </div>
                            </li>

                            <template v-if="showAllowNonSSO">
                                <li>
                                    <div class="pa-flex">
                                        <label for="non_sso_login" class="pa-label pa-label-width">Single Sign-on</label>
                                        <div>
                                            <label class="pa-option">
                                                <input v-model="non_sso_login" type="checkbox" class="pa-option-input" />
                                                <span class="pa-option-icon"></span>
                                                <span class="pa-option-txt">Allow non-SSO Login</span>
                                            </label>
                                            <p class="pa-hint">
                                                Check this to allow sign-in with direct username/password in addition to SSO
                                            </p>
                                        </div>
                                    </div>
                                </li>

                                <li v-if="shouldShowPasswordSetup">
                                    <div class="pa-flex">
                                        <label for="password_setup" class="pa-label pa-label-width">Password Setup</label>
                                        <div>
                                            <label class="pa-option">
                                                <input v-model="password_setup" value="invite" type="radio" class="pa-option-input" />
                                                <span class="pa-option-icon pa-option-icon_radio"></span>
                                                <span class="pa-option-txt">Send Invite Email</span>
                                            </label>

                                            <label class="pa-option" style="margin-left: 20px;">
                                                <input v-model="password_setup" value="password" type="radio" class="pa-option-input" />
                                                <span class="pa-option-icon pa-option-icon_radio"></span>
                                                <span class="pa-option-txt">Specify Password</span>
                                            </label>
                                            <p class="pa-hint">
                                                Would you like to specify the users password or send them an invite email and let them choose a password?
                                            </p>
                                        </div>
                                    </div>
                                </li>

                                <li v-if="shouldShowPassword">
                                    <p class="pa-hint">
                                        <span v-if="userId">
                                            Enter a new password to change the password, otherwise leave blank.
                                        </span>
                                        Passwords must be at least 12 characters long, and contain at least one letter,
                                        number, and special character.
                                    </p>
                                    <div class="pa-flex pa-align-center pa-mb-10">
                                        <label for="password" class="pa-label pa-label-width">Password</label>
                                        <input type="password" class="pa-input text pa-input-label text password-field" placeholder="Password" style="width: 258px;" id="password" v-model="password" />
                                        <p class="pa-hint pa-hint_error">
                                            {{ errors.error__password }}
                                        </p>
                                    </div>
                                    <div class="pa-flex pa-align-center">
                                        <label for="password" class="pa-label pa-label-width">Confirm Password</label>
                                        <input type="password" class="pa-input text pa-input-label text password-field" placeholder="Confirm Password" style="width: 258px;" id="password2" v-model="password2" />

                                    </div>
                                </li>
                            </template>

                            <li v-if="customer_has_external_contacts && account_type == 'contact'">
                                <div class="pa-flex pa-align-center">
                                    <div class="pa-field-hd">
                                        <label for="is_external" class="pa-label pa-label-width">External Contact</label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <label class="pa-option pa-label-width">
                                            <input v-model="is_external" type="checkbox" class="pa-option-input" />
                                            <span class="pa-option-icon"></span>
                                            <span class="pa-option-txt">Is external contact?</span>
                                        </label>
                                        <p class="pa-hint">
                                            An external contact is someone outside of your organization that you wish to send alerts to
                                        </p>
                                    </div>
                                </div>
                            </li>

                            <!-- Forticloud IAM User -->
                            <template v-if="is_forticloud_iam_user">
                                <li>
                                    <div class="pa-flex pa-align-center">
                                        <span class="info-text">IAM Info</span>
                                        <p-divider :width="isModal ? 76 : 84"></p-divider>
                                        <a v-if="isModal" target="_blank" class="iam-edit-forticloud-link" :href=forticloud_redirect>
                                            Edit in Forticloud
                                            <svg class="pa-icon open-in-new iam-edit-forticloud-link">
                                                <use xlink:href="#open-in-new"></use>
                                            </svg>
                                        </a>
                                    </div>
                                </li>
                                <li v-if="iam_user_data.iam_user_name">
                                    <div class="pa-flex pa-align-center">
                                        <label for="non_sso_login" class="pa-label pa-label-width">IAM User Name</label>
                                        <span class="pa-option-txt">  {{ iam_user_data.iam_user_name }}  </span>
                                    </div>
                                </li>

                                <li v-if="iam_user_data.iam_email">
                                    <div class="pa-flex pa-align-center">
                                        <label for="non_sso_login" class="pa-label pa-label-width">IAM Email</label>
                                        <span class="pa-option-txt">  {{ iam_user_data.iam_email }} </span>
                                    </div>
                                </li>

                                <li v-if="iam_user_data.iam_full_name">
                                    <div class="pa-flex pa-align-center">
                                        <label for="non_sso_login" class="pa-label pa-label-width">Full Name</label>
                                        <span class="pa-option-txt">  {{ iam_user_data.iam_full_name }} </span>
                                    </div>
                                </li>

                                <li v-if="iam_user_data.iam_phone">
                                    <div class="pa-flex pa-align-center">
                                        <label for="non_sso_login" class="pa-label pa-label-width">Phone</label>
                                        <span class="pa-option-txt">  {{ iam_user_data.iam_phone }} </span>
                                    </div>
                                </li>

                                <li v-if="iam_user_data.iam_description">
                                    <div class="pa-flex pa-align-center">
                                        <label for="non_sso_login" class="pa-label pa-label-width">Description</label>
                                        <span class="pa-option-txt">  {{ iam_user_data.iam_description }} </span>
                                    </div>
                                </li>
                            </template>
                            <li v-if="customer_key || (fortinet_textkey && serialNumber) || show_mobile_token">
                                <div class="pa-flex pa-align-center">
                                    <span class="info-text">Additional Info</span>
                                    <p-divider :width="isModal ? 79 : 84"></p-divider>
                                </div>
                            </li>
                            <li v-if="customer_key">
                                <div class="pa-field">
                                    <div class="pa-field-hd">
                                        <label for='customer_key' class="pa-label">
                                            Customer Key
                                        </label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <span id="customer-key" class="pa-txt pa-txt_sm" style="line-height: 1.875rem;">{{ customer_key }}</span>
                                        <div style="display: inline-block;">
                                            <a id="copy-customer-key" ref="copyCustomerKey" data-clipboard-target="#customer-key" class="copy-icon">
                                                <svg class="pa-icon middle" style="width: 1rem; height: 1rem;">
                                                    <use xlink:href="#content-copy"></use>
                                                </svg>
                                            </a>
                                            <p-tooltip2 :previous-sibling="true">
                                                <span>Copy</span>
                                                <span slot="clickContent">Copied</span>
                                            </p-tooltip2>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li v-if="fortinet_textkey && serialNumber">
                                <div class="pa-field">
                                    <div class="pa-field-hd">
                                        <label for='customer_key' class="pa-label">
                                        Fortinet Serial Number
                                        </label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <span id="serial-number" class="pa-txt pa-txt_sm" style="line-height: 1.875rem;">{{ serialNumber }}</span>
                                        <div style="display: inline-block;">
                                            <a id="copy-serial-number" ref="copySerialNumber" data-clipboard-target="#serial-number" class="copy-icon">
                                                <svg class="pa-icon middle" style="width: 1rem; height: 1rem;">
                                                    <use xlink:href="#content-copy"></use>
                                                </svg>
                                            </a>
                                            <p-tooltip2 :previous-sibling="true">
                                                <span>Copy</span>
                                                <span slot="clickContent">Copied</span>
                                            </p-tooltip2>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!-- {% if user and user != fmr.get_original_user() %} -->
                            <li v-if="user && customer_indicator">
                                <div class="pa-field">
                                    <div class="pa-field-hd">
                                        <label for='show_subaccount_info' class="pa-label">
                                            Customer Indicator
                                        </label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <label class="pa-option">
                                            <input v-model="show_sub_account_info" name="show_subaccount_info" type="checkbox" :class="show_sub_account_info? 'pa-option-input checked' : 'pa-option-input'"/>
                                            <span class="pa-option-icon"></span>
                                            <span class="pa-option-txt" style="font-size:14px;">
                                                Show current customer indicator
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li v-if="show_mobile_token" style="clear: both;">
                                <div class="pa-field">
                                    <div class="pa-field-hd">
                                        <label for='mobile_token' class="pa-label">
                                            Mobile Token
                                        </label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <p class="pa-txt pa-txt_sm" style="margin-bottom: 0.25rem;">{{ mobile_token }}</p>
                                        <p class="pa-txt pa-txt_xs" style="font-size: 0.75rem;">
                                            <a href="#" @click="generateMobileToken">Regenerate</a>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="activeTab=='permission'">
                    <div class="pa-section pa-pt-16">
                        <div v-if="selected_roles.length <=0" class="pa-no-permission-message pa-mb-20 pa-grid pa-align-center pa-p-12">
                            <svg class="pa-icon pa-icon_l pa-mr-12" style="fill: #fec31e; color: #fec31e">
                                <use xlink:href="#warning"></use>
                            </svg>
                            <span class="warning-text"> User doesn't have any access, choose appropriate permissions below based on the user role. </span>
                        </div>
                        <ul class="pa-vList pa-vList_x2">
                            <li>
                                <div class="pa-flex pa-align-center pa-mb-10">
                                    <span class="info-text">Access Control</span>
                                    <p-divider :width="isModal ? 86 : 89"></p-divider>
                                </div>

                                <p class="pa-hint pa-mb-15 pa-mt-10" >
                                    ACL Roles allow you to grant granular access to the Panopta Control Panel and API. You can read more about the ACL system <a target="_blank" href="https://docs.fortimonitor.forticloud.com/user-guide/343674/access-control">here</a>
                                </p>
                                <p v-if="errors.error__roles" v-html="errors.error__roles" class="pa-hint pa-hint_error"></p>
                                <div>

                                    <p class="pa-permission-roles-header pa-mt-16 pa-mb-10">SYSTEM ROLES</p>
                                    <div class="pa-mb-16">
                                        <label class="pa-option">
                                            <input
                                                v-model="selected_roles"
                                                type="checkbox"
                                                class="pa-option-input"
                                                :value="accountAdminRoleVal" />
                                            <span class="pa-option-icon"></span>
                                            <span class="pa-option-txt label-text">{{ accountAdminRoleLabel }}</span>
                                        </label>
                                    </div>

                                    <div class="pa-grid" v-if="systemRoleOptionsFiltered.length > 0">
                                        <div class="pa-grid-col pa-grid-col_4of12 pa-mb-8" v-for="role in systemRoleOptionsFiltered">
                                            <label class="pa-option">
                                                <input
                                                    v-model="selected_roles"
                                                    type="checkbox"
                                                    class="pa-option-input"
                                                    :value="role.id"
                                                    :disabled="accountDisableRoles" />
                                                <span class="pa-option-icon"></span>
                                                <span class="pa-option-txt label-text">
                                                    <span
                                                        v-if="accountDisableRoles"
                                                        class="disableCheckbox"
                                                    >
                                                        {{ role.name }}
                                                    </span>
                                                    <span v-else>{{ role.name }}</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <p class="pa-permission-roles-header pa-mt-20 pa-mb-10">CUSTOM ROLES</p>
                                    <template v-if="customerRoleOptions.length > 0">
                                        <div class="pa-grid pa-mb-24">
                                            <div class="pa-grid-col pa-grid-col_4of12 pa-mb-8" v-for="role in customerRoleOptions">
                                                <label class="pa-option">
                                                    <input
                                                        v-model="selected_roles"
                                                        type="checkbox"
                                                        class="pa-option-input"
                                                        :value="role.id"
                                                        :disabled="accountDisableRoles" />
                                                    <span class="pa-option-icon"></span>
                                                    <span class="pa-option-txt label-text">
                                                        <span v-if="accountDisableRoles" class="disableCheckbox">
                                                            {{ role.name }}
                                                        </span>
                                                        <span v-else>{{ role.name }}</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                    <p
                                        class="pa-hint pa-mb-24"
                                        style="padding-bottom: 5px;"
                                        v-else
                                    >
                                        There are no custom roles defined on this account.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div for="server_group_access" class="pa-flex pa-align-center pa-mb-10">
                                    <span class="info-text">Instance Group Access</span>
                                    <p-divider :width="isModal ? 80 : 85"></p-divider>
                                </div>
                                <p v-if="errors.error__server_groups" v-html="errors.error__server_groups" class="pa-hint pa-hint_error"></p>
                                <div class="pa-pt-0 pa-mb-24">
                                    <p class="pa-hint pa-mb-16">
                                        Which groups do the user's roles apply to?
                                    </p>
                                    <label class="pa-option">
                                        <input v-model="server_group_access" value="all" type="radio" class="pa-option-input" />
                                        <span class="pa-option-icon pa-option-icon_radio"></span>
                                        <span class="pa-option-txt label-text">All Groups</span>
                                    </label>

                                    <label class="pa-option pa-ml-20 pa-mb-10">
                                        <input v-model="server_group_access" value="selected" type="radio" class="pa-option-input" />
                                        <span class="pa-option-icon pa-option-icon_radio"></span>
                                        <span class="pa-option-txt label-text">Selected Groups</span>
                                    </label>

                                    <div v-if="server_group_access == 'selected'" style="padding: 3px 0 0 25px;">
                                        <div class='controls' style="height: 300px; overflow-y: auto; border: solid 1px #e5e8eb; width: 350px;">
                                            <p-tree
                                                id="tree"
                                                :model="server_groups"
                                                :preselected="preselectedGroups"
                                                url="/util/monitoring_tree?only_groups=1&only_top_level_groups=1"
                                                :select_nodes="true"
                                                :multiselect="true"
                                            ></p-tree>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li v-if="access_level_options.length > 0">
                                <div class="pa-flex pa-align-center pa-mb-16">
                                    <span class="info-text">Sub-Account Access</span>
                                    <p-divider :width="isModal ? 82 : 86"></p-divider>
                                </div>
                                <div>
                                    <p-select :model.sync="access_level" :options="access_level_options"></p-select>
                                </div>
                                <div class="pa-mb-24">
                                    <p class="pa-hint">
                                        What level of access should this user have?
                                    </p>
                                    <p v-if="errors.error__access_level" v-html="errors.error__access_level" class="pa-hint pa-hint_error"></p>
                                </div>
                            </li>

                            <li v-if="access_level_options.length > 0 && access_level != 'customer'">
                                <div for="non_sso_login" class="pa-flex pa-align-center pa-mb-16">
                                    <span class="info-text">Sub-Account ACL Roles</span>
                                    <p-divider :width="isModal ? 79 : 84"></p-divider>
                                </div>

                                <p v-if="errors.error__brand_roles" v-html="errors.error__brand_roles" class="pa-hint pa-hint_error"></p>
                                <p class="pa-permission-roles-header pa-mt-16 pa-mb-10">SYSTEM ROLES</p>
                                <div class="pa-mb-16">
                                    <label class="pa-option">
                                        <input
                                            v-model="selected_brand_roles"
                                            type="checkbox"
                                            class="pa-option-input"
                                            :value="accountAdminRoleVal" />
                                        <span class="pa-option-icon"></span>
                                        <span class="pa-option-txt label-text">{{ accountAdminRoleLabel }}</span>
                                    </label>
                                </div>
                                <div class="pa-grid">
                                    <div class="pa-grid-col pa-grid-col_4of12 pa-mb-8" v-for="role in systemRoleOptionsFiltered">
                                        <label class="pa-option">
                                            <input
                                                v-model="selected_brand_roles"
                                                type="checkbox"
                                                class="pa-option-input"
                                                :value="role.id"
                                                :disabled="subAccountDisableRoles" />
                                            <span class="pa-option-icon"></span>
                                            <span class="pa-option-txt label-text">
                                                <span v-if="subAccountDisableRoles" class="disableCheckbox">{{ role.name }}</span>
                                                <span v-else>{{ role.name }}</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <p class="pa-permission-roles-header pa-mt-20 pa-mb-10">CUSTOM ROLES</p>
                                <template v-if="customerRoleOptions.length > 0">
                                    <p v-if="errors.error__brand_roles" v-html="errors.error__brand_roles" class="pa-hint pa-hint_error"></p>
                                    <div class="pa-grid pa-mb-24">
                                        <div class="pa-grid-col pa-grid-col_4of12 pa-mb-8" v-for="role in customerRoleOptions">
                                            <label class="pa-option">
                                                <input
                                                    v-model="selected_brand_roles"
                                                    type="checkbox"
                                                    class="pa-option-input"
                                                    :value="role.id"
                                                    :disabled="subAccountDisableRoles" />
                                                <span class="pa-option-icon"></span>
                                                <span class="pa-option-txt label-text">
                                                    <span v-if="subAccountDisableRoles" class="disableCheckbox">{{ role.name }}</span>
                                                    <span v-else>{{ role.name }}</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </template>

                                <p class="pa-hint pa-mb-24" style="padding-bottom: 5px;" v-else>There are no custom roles defined on this account.</p>
                            </li>

                            <!-- Having a primary user means this is a secondary user from another customer. -->
                            <li v-if="secondary_user && userId && canDeleteUser">
                                <div for="remove_user" class="pa-flex pa-align-center pa-mb-16">
                                    <span class="info-text">Remove External User</span>
                                    <p-divider :width="isModal ? 81 : 85"></p-divider>
                                </div>
                                <div class="pa-mb-24">
                                    <button class="pa-btn pa-btn_secondary"
                                        v-p-prompt-open
                                        target="dynamic_prompt"
                                        :callback="deleteUser"
                                        title="Confirm Remove"
                                        body="Are you sure you want to remove this user?"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </li>

                            <!-- {% if user.has_secondary_users or user.is_secondary %} -->
                            <li v-if="has_secondary_users || secondary_user" style="margin-top:20px;">
                                <div class="pa-field">
                                    <div class="pa-field-hd">
                                        <label for="memberships" class="pa-label">Account Memberships</label>
                                    </div>
                                    <div class="pa-field-bd">
                                        <table class="pa-table dataTable" style="border: 1px solid #ddd; max-height: 200px; overflow-y: scroll;">
                                            <tbody>
                                                <tr v-for="account in user_accounts">
                                                    <td>
                                                        <p class="pa-hdg pa-hdg_2" style="font-size: 14px; margin-bottom: 5px;">{{ account.name }} {{account.is_active}}</p>
                                                        <p class="pa-txt" style="font-size:12px;">{{ account.account_type }}</p>
                                                    </td>
                                                    <td><a href="#" @click="viewAccount(account)" class="pa-btn pa-btn_naked" style="padding: 1px;">View</a>
                                                        <span v-if="account.account_type != 'Primary Account'">|
                                                            <button
                                                            style="padding: 1px;"
                                                            class="pa-btn pa-btn_naked"
                                                            v-p-prompt-open
                                                            target="dynamic_prompt"
                                                            title="Leave Account"
                                                            body="Are you sure you want to leave the account?"
                                                            :href="'/users/users/LeaveSecondaryAccount?user_id='+account.user_id">Leave</button>
                                                    </span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </li>
                            <!-- {% if user and slack_app_configured %} -->
                            <li v-if="user && slack_app_configured">
                                <div class="pa-flex pa-align-center pa-mb-10">
                                    <span class="info-text">Slack Integration</span>
                                    <p-divider :width="isModal ? 86 : 89"></p-divider>
                                </div>
                                <div v-if="slack_users.length" transition="slack-name">
                                    <label class="pa-label" style="margin-bottom: 5px;">Exisiting Slack Users</label>
                                    <ul class="pa-vList" style="margin-bottom: 10px;">
                                        <li v-for="user in slack_users">
                                            <div>
                                                <span class="pa-txt">{{ user[1] }}</span>
                                                <svg @click="removeSlack(user[0])" class="pa-icon" style="cursor: pointer; float: right;"><use xlink:href="#close"></use></svg>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <button v-else @click="openSlackModal" type="button" class="pa-btn pa-btn_secondary" transition="link-slack">Link Slack User</button>
                            </li>



                            <!-- {% if has_password_section  %} -->
                            <li v-if="has_password_section" title="Password">
                                <ul v-if="show_password_fields" class="pa-vList pa-vList_x2">
                                    <!-- {% if show_password_fields  %} -->
                                    <p class="pa-hint pa-pb-10">Enter a new password to change the password, otherwise leave blank.
                                        Passwords must be at least 12 characters long, and contain at least one letter, number,
                                        and special character.</p>
                                    <label class="pa-label" style="margin-bottom: 5px;">Current {{ password_label }}</label>
                                    <div>
                                        <input
                                            data-lpignore="true"
                                            type="password"
                                            name="current_password"
                                            v-model="current_password"
                                            id="current_password"
                                            ref="current_password"
                                            placeholder="Current Password"
                                            class="pa-input pa-input-text text"/>
                                            <div v-if="errors['error__current_password']" class="pa-hint pa-hint_error">
                                                {{ errors['error__current_password'] }}
                                            </div>
                                    </div>
                                    <div class="pa-pt-12" style="margin-top: 0px;">
                                        <label class="pa-label" style="margin-bottom: 5px;">New {{ password_label }}</label>
                                        <span>
                                            <input
                                                data-lpignore="true"
                                                type="password"
                                                name="password"
                                                v-model="password"
                                                id="password"
                                                placeholder="Password"
                                                class="pa-input pa-input-text text"
                                                style="margin-top: 0px;"
                                            >
                                            <div v-if="errors['error__password']" class="pa-hint pa-hint_error">
                                                {{ errors['error__password'] }}
                                            </div>
                                        </span>
                                        <span>
                                            <input
                                                data-lpignore="true"
                                                type="password"
                                                name="password2"
                                                v-model="password2"
                                                id="password2"
                                                placeholder="Confirm Password"
                                                class="pa-input pa-input-label text"
                                                style="margin-top: 8px;"
                                            >
                                            <div v-if="errors['error__password2']" class="pa-hint pa-hint_error">
                                                {{ errors['error__password2'] }}
                                            </div>
                                        </span>
                                    </div>
                                </ul>
                                <!-- {% if password_url  %} -->
                                <div v-if="password_url" class="pa-pt-16">
                                    <label class="pa-label" style="margin-bottom: 5px;">FortiCloud SSO Password</label>
                                    <p class="pa-txt_sm">To update your FortiCloud password, click
                                        <a :href="password_url" target='_blank'>here</a>
                                    </p>
                                </div>
                            </li>



                            <!-- {% if user.customer.brand.textkey == "panopta" %} -->
                            <li v-if="panopta_brand">
                                <div class="pa-flex pa-align-center pa-mb-10">
                                    <span class="info-text">Email Preferences</span>
                                    <p-divider :width="isModal ? 86 : 89"></p-divider>
                                </div>
                                <ul class="pa-vList pa-vList_x2">
                                    <!-- {% for email_pref in email_preferences %} -->
                                    <li v-for="email_pref in email_preferences" style="margin-bottom:20px;">
                                        <label class="pa-option">
                                            <input name="email_options" :value=email_pref.list_id type="checkbox" :class="email_pref.enabled? 'pa-option-input enabled':'pa-option-input'"/>
                                            <span class="pa-option-icon"></span>
                                            <span class="pa-option-txt" style="font-size:16px;">
                                                {{ email_pref.list_name }}
                                            </span>
                                        </label>
                                        <p class="pa-option-description">{{ email_pref.list_description }}</p>
                                    </li>
                                </ul>
                            </li>

                            <!-- <div class="editmetric-drawer-footer" slot="footer">
                                <button v-el:save-btn ref="saveBtn" @click="save" class="pa-btn pa-btn_blue" style="width: 75%; height: 48px;">Save</button>
                            </div> -->

                            <p-modal v-ref:slack-modal id="slack-modal" ref="slackModal" :on-close="undoSafariHack">
                                <form v-if="!slackCodeSent" ref="slackLinkForm" id='slack_link_form' key="slackLinkForm" method="POST" class="pa-panel" style="width: 500px;" onkeypress="return event.key != 'Enter';">
                                    <div class="pa-panel-hd">
                                        <h2 class="pa-hdg pa-hdg_3" slot="header">Link Slack User</h2>
                                    </div>

                                    <div class="pa-panel-bd">
                                        <div v-if="slackError" class="pa-message pa-message_error">
                                            {{ slackError }}
                                        </div>

                                        <p>
                                            Enter the information below, then follow the instructions sent to you on Slack.
                                        </p>

                                        <input type="hidden" name="user_id" :value="userId" />
                                        <div class="pa-field">
                                            <div class="pa-field-hd">
                                                <label for="slack-team" class="pa-label">Slack Team</label>
                                            </div>
                                            <div class="pa-field-bd">
                                                <div class="pa-select">
                                                    <select name="team" class="pa-select-menu">
                                                        <option v-for="opt in slackTeams" :value="opt.value">{{opt.label}}</option>
                                                    </select>
                                                    <span class="pa-select-icon"></span>
                                                </div>
                                            </div>
                                            <div class="pa-field-ft">
                                                <p class="pa-hint">
                                                    The team for which the user belongs.
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pa-field">
                                            <div class="pa-field-hd">
                                                <label for="slack-email" class="pa-label">Slack Email</label>
                                            </div>
                                            <div class="pa-field-bd">
                                                <input type="text" id="slack-email" name="slack_email" class="pa-input" />
                                            </div>
                                            <div class="pa-field-ft">
                                                <p class="pa-hint">
                                                    The email address used for this Slack account
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="pa-panel-ft">
                                        <ul class="pa-split">
                                            <li>
                                            </li>
                                            <li>
                                                <button class="pa-btn pa-btn_cancel" type="button" v-p-dialog-close target="slack-modal">Close</button>
                                                <button @click="startSlackLink" class="pa-btn" type="button" value="save">Proceed</button>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                                <form v-else ref="slackConfirmForm" id='slack_confirm_form' key="slackCofirmModal" method="POST" class="pa-panel" style="width: 400px;">
                                    <div class="pa-panel-hd">
                                        <h2 class="pa-hdg pa-hdg_3" slot="header">Link Slack User</h2>
                                    </div>

                                    <div class="pa-panel-bd">
                                        <div style="text-align: center; width: 70%; margin: 0 auto 20px;">
                                        <span class="pa-social-logo pa-social-logo_contact-channel-slack"></span>
                                        </div>

                                        <p>
                                            Enter the confirmation code you received.
                                        </p>

                                        <input type="hidden" name="user_id" :value="userId" />
                                        <div class="pa-field">
                                            <div class="pa-field-hd">
                                                <label for="slack-code" class="pa-label">Confirmation Code</label>
                                            </div>
                                            <div class="pa-field-bd">
                                                <input type="text" id="slack-code" name="slack_code" class="pa-input" />
                                            </div>
                                            <div class="pa-field-ft">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="pa-panel-ft">
                                        <ul class="pa-split">
                                            <li>
                                            </li>
                                            <li>
                                                <button class="pa-btn pa-btn_cancel" type="button" v-p-dialog-close target="slack-modal">Close</button>
                                                <button @click="finishSlackLink" class="pa-btn" type="button" value="save">Finish</button>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </p-modal>
                            <p-modal id="login_again"
                                header-icon-color="pa-icon_white"
                            >
                                <div class="pa-panel">
                                    <div class="pa-panel-hd">
                                        <h2 class="pa-hdg pa-hdg_3" slot="header">Login Again</h2>
                                    </div>
                                    <div class="pa-panel-bd" style="text-align: center;">
                                        <div class="pa-field pa-txt_sm pa-py-16" style="color:#394449;">
                                            You will need to login again.
                                        </div>
                                    </div>
                                    <div class="pa-panel-ft">
                                        <ul class="pa-split">
                                            <li></li>
                                            <li>
                                                <a
                                                    href="/login"
                                                    class="pa-btn pa-btn_blue"
                                                >
                                                Login Again
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </p-modal>

                        </ul>
                    </div>
                </div>

                <div v-if="activeTab=='alerting'">
                    <div class="pa-section pa-pt-16">
                        <p
                            class="pa-message pa-message_info"
                            v-if="!contactId && secondary_user"
                        >
                            This user's contact information can only be edited from their primary account
                        </p>
                        <ul class="pa-vList pa-vList_x2">
                            <p-text font-size="xs" class="pa-mb-16 pa-pb-12 pa-txt_secondary">
                                *Indicates mandatory fields
                            </p-text>
                            <li>
                                 <div class="pa-flex pa-align-center pa-mb-20">
                                    <span class="info-text">Contact Info</span>
                                    <p-divider :width="isModal ? 89 : 91"></p-divider>
                                </div>
                                <p for="emailAddress" class="pa-permission-roles-header pa-mt-16 pa-mb-10">Email Addresses<span class="required">*</span></p>
                                <div class="pa-hint field-sub-header" style="font-weight: normal; padding-left: 75%;">Alert Types</div>
                                <div class="pa-mt-3" v-for="em in email_addresses">
                                    <ul class="pa-hList pa-hList_x2" style="flex-flow:space-between">
                                        <input
                                            type="text"
                                            class="pa-input text pa-input-label text"
                                            v-bind:class="{ error: em.error}"
                                            placeholder="Address"
                                            style="width: 40%;"
                                            v-model="em.address"
                                            :disabled="is_secondary_user"/>
                                        <input type="text" class="pa-input text pa-input-label text"
                                                        placeholder="Label (optional)" style="width: 30%;" v-model="em.label" :disabled="is_secondary_user"/>
                                        <p-select :model="em.alert_types" :options="email_alert_type_options" :multiple="true" size="xxxxs" :disabled="is_secondary_user"></p-select>
                                        <button v-if="!secondary_user" v-on:click="delete_email_address(em)" style="margin-left: 2px;" class="button-naked">
                                            <svg class="pa-icon" style="width: 8px; vertical-align: middle">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#remove"></use>
                                            </svg>
                                        </button>
                                    </ul>
                                </div>
                                <div class="pa-mb-24" v-if="!is_secondary_user">
                                    <p v-if="errors.error__email_addresses" v-html="errors.error__email_addresses" class="pa-hint pa-hint_error"></p>
                                    <p class="pa-hint">
                                        <button @click.prevent="add_email_address" class="button-naked add-button">
                                            <svg style="vertical-align: top;" class="pa-icon">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#plus-circle-outline"></use>
                                            </svg> Add address
                                        </button>
                                    </p>
                                </div>
                            </li>

                            <li>
                                <p for="phone_numbers" class="pa-permission-roles-header pa-mt-16 pa-mb-10">Phone Numbers</p>
                                <div class="pa-mt-3" v-for="ph in phone_numbers">
                                    <ul class="pa-hList pa-hList_x2" style="flex-flow:space-between">
                                        <input type="text" class="pa-input text pa-input-label text"
                                                        :class="{ error: ph.error}"
                                                        placeholder="Phone number" style="width: 40%;" v-model="ph.number" :disabled="is_secondary_user"/>
                                        <input type="text" class="pa-input text pa-input-label text"
                                                        placeholder="Label (optional)" style="width: 30%;" v-model="ph.label" :disabled="is_secondary_user"/>
                                        <p-select :model="ph.alert_types" :options="alert_type_options" :multiple="true" size="xxxxs" :disabled="is_secondary_user"></p-select>
                                        <button v-if="!secondary_user" v-on:click="delete_phone_number(ph)" style="margin-left: 2px;" class="button-naked">
                                            <svg class="pa-icon" style="width: 8px; vertical-align: middle">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#remove"></use>
                                            </svg>
                                        </button>
                                    </ul>
                                </div>
                                <div class="pa-mb-24" v-if="!is_secondary_user">
                                    <p v-if="errors.error__phone_numbers" v-html="errors.error__phone_numbers" class="pa-hint pa-hint_error"></p>
                                    <p class="pa-hint">
                                        <button v-on:click="add_phone_number()" class="button-naked add-button">
                                            <svg style="vertical-align: top;" class="pa-icon">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#plus-circle-outline"></use>
                                            </svg> Add phone number
                                        </button>
                                    </p>
                                </div>
                            </li>

                            <li v-if="customer_has_legacy_alerts">
                                <p for="fullname" class="pa-permission-roles-header pa-mt-16 pa-mb-10">Twitter</p>
                                <div class="pa-mt-3" v-for="h in twitter_handles">
                                    <ul class="pa-hList pa-hList_x2" style="flex-flow:space-between">
                                        <input type="text" class="pa-input text pa-input-label text password-field"
                                                        placeholder="Twitter handle" style="width: 40%;" v-model="h.handle" :disabled="is_secondary_user"/>
                                        <input type="text" class="pa-input text pa-input-label text password-field"
                                                        placeholder="Label (optional)" style="width: 30%;" v-model="h.label" :disabled="is_secondary_user"/>
                                        <p-select :model="h.alert_types" :options="alert_type_options" :multiple="true" size="xxxxs" :disabled="is_secondary_user"></p-select>
                                        <button v-on:click="delete_twitter_handle(h)" style="margin-left: 2px;" class="button-naked">
                                            <svg class="pa-icon" style="width: 8px; vertical-align: middle">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#remove"></use>
                                            </svg>
                                        </button>
                                    </ul>
                                </div>
                                <div class="pa-mb-24" v-if="!is_secondary_user">
                                    <p class="pa-hint">
                                        <button v-on:click="add_twitter_handle()" class="button-naked add-button">
                                            <svg style="vertical-align: top;" class="pa-icon">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#plus-circle-outline"></use>
                                            </svg> Add Twitter handle
                                        </button>
                                    </p>
                                    <p class="pa-hint pa-hint_error">
                                        {{ errors.error__twitter_handles }}
                                    </p>
                                </div>
                            </li>

                            <li v-if="customer_has_legacy_alerts">
                                <p for="googleChat" class="pa-permission-roles-header pa-mt-16 pa-mb-10">Google Chat</p>
                                <div class="pa-mt-3" v-for="h in gchat_handles">
                                    <ul class="pa-hList pa-hList_x2" style="flex-flow:space-between">
                                        <input type="text" class="pa-input text pa-input-label text password-field"
                                                        placeholder="Google chat handle" style="width: 40%;" v-model="h.handle" :disabled="is_secondary_user"/>
                                        <input type="text" class="pa-input text pa-input-label text password-field"
                                                        placeholder="Label (optional)" style="width: 30%;" v-model="h.label" :disabled="is_secondary_user"/>
                                        <p-select :model="h.alert_types" :options="alert_type_options" :multiple="true" size="xxxxs" :disabled="is_secondary_user"></p-select>
                                        <button v-on:click="delete_gchat_handle(h)" style="margin-left: 2px;" class="button-naked">
                                            <svg class="pa-icon" style="width: 8px; vertical-align: middle">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#remove"></use>
                                            </svg>
                                        </button>
                                    </ul>
                                </div>
                                <div class="pa-mb-24" v-if="!is_secondary_user">
                                    <p class="pa-hint">
                                        <button v-on:click="add_gchat_handle()" class="button-naked add-button">
                                            <svg style="vertical-align: top;" class="pa-icon">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#plus-circle-outline"></use>
                                            </svg> Add Google chat handle
                                        </button>
                                    </p>
                                    <p class="pa-hint pa-hint_error">
                                        {{ errors.error__gchat_handles }}
                                    </p>
                                </div>
                            </li>

                            <li>
                                <p for="mobileDevices" class="pa-permission-roles-header pa-mt-16 pa-mb-10">Mobile Devices</p>
                                <div class="pa-mt-3" v-for="device in mobile_devices">
                                    <ul class="pa-hList" style="flex-flow:space-between">
                                        <svg class="pa-icon" style="vertical-align: text-top; padding-bottom: 5px;">
                                            <use v-if="device.type == 'push.gcm' || device.device_platform == 'android'" xlink:href="#android"></use>
                                            <use v-else xlink:href="#apple"></use>
                                        </svg>

                                        <span v-html="device.label" class="pa-txt"></span>

                                        <button v-on:click="delete_mobile_device(device)" style="margin-left: 8px;" class="button-naked">
                                            <svg class="pa-icon" style="width: 8px; vertical-align: middle">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#remove"></use>
                                            </svg>
                                        </button>
                                    </ul>
                                </div>
                                <p class="pa-hint pa-mb-24">
                                    Install the mobile app on additional devices by going to the
                                    <a href="https://apps.apple.com/us/app/fortimonitor/id1571216228" target="_new">iOS App Store</a>
                                    or <a href="https://play.google.com/store/apps/details?id=org.nativescript.fortimonitor" target="_new">Google Play</a>.
                                </p>
                            </li>

                            <li v-if="contactId">
                                <div class="pa-flex pa-align-center pa-mb-10 pa-mt-32">
                                    <span class="info-text">Alert Timelines</span>
                                    <p-divider :width="isModal ? 86 : 89"></p-divider>
                                </div>
                                <div class="pa-section pa-p-0">
                                    <p-table2
                                        source="server"
                                        :data-url="`/users/users/get_notification_schedule_list?contact_id=${contactId}`"
                                        paging
                                        filtering
                                        info
                                        :page-length="25"
                                        :column-renderers="{1: 'link'}"
                                    >
                                        <table slot="table" class="pa-table" id="alert-timelint-table">
                                            <thead>
                                                <tr class="pa-table-row pa-table-row_hd">
                                                    <th>Alert Timeline</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </p-table2>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </p-flex>
</template>

<script>
import Vue from "vue";
import _ from 'lodash';
import ApplicationImageUploader from '../applications/components/ApplicationImageUploader.vue';

export default Vue.extend({
    components: {
        "p-application-image-uploader": ApplicationImageUploader,
    },
    props: {
        csrfToken: String,
        accountAdminRoleVal: String,
        accountAdminRoleLabel: String,
        iSystemRoleOptions: String,
        iCustomerRoleOptions: String,
        iPreselectedGroups: String,
        iConfigData: String,
        canDeleteUser: Boolean,
        iContactId: String,
        iPendingId: String,
        iUserId: String,
        user: String,
        lastLogin: String,
        createdOn: String,
        isModal: Boolean,
        serialNumber: String,
    },
    data: function() {
        return {
            systemRoleOptions: [],
            customerRoleOptions: [],
            preselectedGroups: [],
            configData: null,
            selected_roles: [],
            selected_brand_roles: [],
            access_level_options: [],
            show_instance_drawer: true,
            instance_drawer_open: false,
            error_message: "",
            error__email_addresses: "",
            fullname: null,
            title_name: null,
            timezone: null,
            brand: null,
            brand_type: null,
            creating: null,
            timezone_options: [],
            account_type: null,
            selected_brand_roles: null,
            customer_has_legacy_alerts: null,
            customer_has_sso: null,
            customer_has_external_contacts:null,
            is_external: null,
            non_sso_login: null,
            show_password_reset: null,
            password_setup: "",
            password: "",
            password2: "",
            current_password: "",
            access_level: null,
            access_badge: null,
            access_level_options: [],
            server_group_access: null,
            server_groups: null,
            tags: [],
            default_dashboard: null,
            dashboard_options: [],
            dashboard_access_type: null,
            select_dashboards: null,
            email_addresses: [],
            show_email_prefs: false,
            email_preferences: {},
            phone_numbers: [],
            twitter_handles: [],
            gchat_handles: [],
            mobile_devices: [],
            alert_type_options: [],
            email_alert_type_options: [],
            secondary_user: null,
            secondary_user_email: null,
            skip_activation_email: null,
            errors: {},
            contactId: null,
            pendingId: null,
            userId: null,
            activeTab: 'general',
            activeTabName: 'General',
            is_forticloud_iam_user: null,
            last_login: null,
            created_on: null,
            fortinet_textkey: null,
            customer_key: null,
            show_mobile_token: false,
            mobile_token: '**********',
            nav_items: [],
            user_accounts: [],
            customer_indicator: false,
            has_secondary_users: false,
            slackTeams: [],
            slack_users: [],
            slack_app_configured: false,
            slackError: '',
            show_sub_account_info: false,
            slackCodeSent: false,
            panopta_brand: false,
            avatar: JSON.parse(this.iConfigData).avatar,
            errorListObject: [],
            errorListData: [
                {
                    name: 'error__fullname',
                    displayError: 'Missing Name',
                    tabName: 'General',
                },
                {
                    name: 'error__secondary_user_email',
                    displayError: '',
                    tabName: 'General',
                },
                {
                    name: 'error__timezone',
                    displayError: 'Timezone not selected',
                    tabName: 'General',
                },
                {
                    name: 'error__dashboard_access',
                    displayError: '',
                    tabName: 'General',
                },
                {
                    name: 'error__default_dashboard',
                    displayError: '',
                    tabName: 'General',
                },
                {
                    name: 'error__password',
                    displayError: '',
                    tabName: 'General',
                },
                {
                    name: 'error__current_password',
                    displayError: '',
                    tabName: 'Permissions',
                },
                {
                    name: 'error__roles',
                    displayError: '',
                    tabName: 'Permissions',
                },
                {
                    name: 'error__server_groups',
                    displayError: '',
                    tabName: 'Permissions',
                },
                {
                    name: 'error__access_level',
                    displayError: '',
                    tabName: 'Permissions',
                },
                {
                    name: 'error__brand_roles',
                    displayError: '',
                    tabName: 'Permissions',
                },
                {
                    name: 'error__email_addresses',
                    displayError: '',
                    tabName: 'Alerting',
                },
                {
                    name: 'error__phone_numbers',
                    displayError: '',
                    tabName: 'Alerting',
                },
                {
                    name: 'error__twitter_handles',
                    displayError: '',
                    tabName: 'Alerting',
                },
                {
                    name: 'error__gchat_handles',
                    displayError: '',
                    tabName: 'Alerting',
                },
            ]
        };
    },
    events: {
        'save-edit-page-icon'(value) {
            this.avatar = {"icon": value};
            this.eventHub.$emit('edit-page-field-save-complete', 'icon');
        },

        'delete-edit-page-icon'(value) {
            this.avatar.icon = {"icon": "default-photo"};
            this.eventHub.$emit('edit-page-field-save-complete', 'icon');
            window.app.rootVue.$broadcast('prompt:close', 'dynamic_prompt');
        }
    },
    methods: {

        deleteUser: function() {
            $.ajax({
                url: "/users/users/deleteContact",
                method: "POST",
                context: this,
                data: {
                    "id_list": this.contact_id,
                    "csrf_token": this.csrf_token
                },
            }).done((data) => {
                if (data.success) {
                    window.app.toastManager.showToast({
                        type: 'success',
                        icon: 'check',
                        text: 'User successfully deleted',
                    });
                    window.location = "/users";
                } else {
                    if(data.msg) {
                        window.app.toastManager.showToast({
                            type: 'error',
                            text: data.msg,
                        });
                    } else {
                        window.location = "/users";
                    }
                }
            });
        },

        handleItemActive: function(data) {
            this.activeTab = data.id;
            this.activeTabName = this.updateActiveTabName(data);
        },

        updateActiveTabName: function(data) {
            if (data.id == 'general' && this.secondary_user) {
                return this.userId ? 'Edit External User' : 'Invite External User';
            } else {
                return data.text;
            }
        },

        close: function() {
            this.$root.$broadcast('dialog:close', 'dynamic_modal');
        },

        add_email_address: function() {
            this.email_addresses.push({"id": "", "address": "", "label": "", "alert_types": ["1", "2", "4", "8", "16"]});
        },

        delete_email_address: function(e) {
            this.email_addresses.splice(this.email_addresses.indexOf(e), 1);
        },

        add_phone_number: function() {
            this.phone_numbers.push({"id": "", "number": "", "label": "", "alert_types": ["1", "2", "4", "8"]});
        },

        delete_phone_number: function(e) {
            this.phone_numbers.splice(this.phone_numbers.indexOf(e), 1);
        },

        add_twitter_handle: function() {
            this.twitter_handles.push({"id": "", "handle": "", "label": "", "alert_types": ["1", "2", "4", "8"]});
        },

        delete_twitter_handle: function(e) {
            this.twitter_handles.splice(this.twitter_handles.indexOf(e), 1);
        },

        add_gchat_handle: function() {
            this.gchat_handles.push({"id": "", "handle": "", "label": "", "alert_types": ["1", "2", "4", "8"]});
        },

        delete_gchat_handle: function(e) {
            this.gchat_handles.splice(this.gchat_handles.indexOf(e), 1);
        },

        delete_mobile_device: function(e) {
            this.mobile_devices.splice(this.mobile_devices.indexOf(e), 1);
        },

        onFinish: function() {
            $("#edit-contact-form").submit();
            return false;
        },

        validateAccountInfo: function() {

            $('.pa-hint:contains(Passwords must)').removeClass('red-hint');
            var valid = false; //this.requiredFilled();
            var specify_pass = $("#create_type_specify_password").prop("checked");
            var sso_pass = $("#sso_password_login").prop("checked");
            var editing = typeof $("#contact_id").val() !== 'undefined';
            var roles = this.selected_roles;

            if (specify_pass || (editing && sso_pass)) {
                var letter = /[a-zA-Z]/;
                var number = /[0-9]/;
                var special = /[^a-zA-Z0-9]/;
                var pass1 = $("input[name=password]").val();
                var pass2 = $("input[name=password2]").val();
                if (pass1.length < 8 ||
                    !letter.test(pass1) ||
                    !number.test(pass1) ||
                    !special.test(pass1) ||
                    pass1 != pass2) {
                    valid = false;
                    if (pass1.length && pass1 == pass2) {
                        $('.pa-hint:contains(Passwords must)').addClass('red-hint');
                    }
                }else{
                    valid = true;
                }
            }
            if (this.hasAccountTypeDataFilledIn(roles) &&
                    (this.server_group_access == 'all' ||
                    (this.server_groups && this.server_groups.length>0))) {
                valid = true;
            }
            return valid;
        },

        hasAccountTypeDataFilledIn: function(roles) {
            //standard user needs to have a name and a role.
            if(this.$data.fullname && this.$data.fullname.length > 0 && roles.length > 0) {
                return true;
            }

            //external user needs to have a secondary email and a role.
            if(this.$data.secondary_user_email && this.$data.secondary_user_email.length > 0 && roles.length) {
                return true;
            }

            //alert only user just needs a name.
            if(this.$data.fullname && this.$data.fullname.length > 0 && this.account_type == 'contact') {
                return true;
            }

            return false;

        },

        submit_form: function() {
            $(".pa-wizard-row-col_ft .pa-btn", $("#modal-container")).text("Saving...").prop("disabled", true);
            var data = Object.assign({}, this.$data);
            delete data.timezone_options;
            delete data.dashboard_options;
            delete data.nav_items;
            delete data.user_nav_items;
            if (data.account_type == 'contact') {
                data.password = '';
                data.password2 = '';
                data.password_setup = 'invite';
            }
            data.skip_activation_email = this.skip_activation_email;
            data.csrf_token = this.csrfToken;
            data.contact_id = this.contactId;
            data.pending_id = this.pendingId;
            data.user_id = this.userId;
            data.edit_self_user = this.isModal;
            this.resetFormErrors();
            $.ajax({url: "/users/users/editUser",
                type: "POST",
                context: this,
                data: data})
            .done(function(data) {
                // A big of a hack, but due to changing in TG1 -> TG2
                // session management we will get back a document of our
                // redirect to the root page automatically. If the response
                // is an HTML string lets consider it a password change. (orig. Matt Holmes)
                try {
                    if(data.startsWith("<!DOCTYPE html PUBLIC")) {
                    this.$broadcast('dialog:open', 'login_again');
                    }
                } catch (error) {
                    //do nothing
                }

                if (data.success) {
                    if(data.login_again) {
                        this.$broadcast('dialog:open', 'login_again');
                    }
                    this.error_message = '';
                    let newOrOldText = this.contactId ? 'updated' : 'created';
                    window.app.toastManager.showToast({
                        type: 'success',
                        icon: 'check',
                        text: `User successfully ${newOrOldText}`,
                    });
                    let toast_box = document.getElementsByClassName('pa-toast-box');
                    toast_box[0].classList.add('toast-top');
                    window.app.toastManager.popQueuedToasts();
                    setTimeout(() => {
                        window.location = "/users";
                    }, 1500)
                } else {
                    // Form submission failed, set error messages and re-enable save button
                    for (var v in this._data) {
                        if (v.indexOf("error__") == 0) {
                            this.errors[v] = "";
                        }
                    }

                    // Set new errors for email address field
                    for (var i=0; i<data.error_data.invalid_email_addresses.length; i++) {
                        key = "email_addresses[" + data.error_data.invalid_email_addresses[i] + "]['error']";
                        this.key = true;
                    }

                    // Set new errors for phone numbers field
                    for (var i=0; i<data.error_data.invalid_phone_numbers.length; i++) {
                        key = "phone_numbers[" + data.error_data.invalid_phone_numbers[i]+ "]['error']";
                        this.errors[key] = true;
                    }

                    // Set error messages for individual fields
                    for (var key in data.errors) {
                        this.errors[key] = data.errors[key];
                        let errorData = this.errorListData.find(item => item.name === key)
                        if(errorData.name == "error__password" && this.$refs.current_password) {
                            errorData.tabName = "Permissions"
                        }
                        this.errorListObject.push({
                            name: errorData.displayError ? errorData.displayError : data.errors[key],
                            tabName: errorData.tabName,
                        })
                    }

                    if (data.error_message) {
                        this.error_message = data.error_message;
                    } else {
                        this.error_message = "Please enter all mandatory/invalid fields to continue, Following mandatory fields are missing:";
                    }
                    $(".pa-wizard-row-col_ft .pa-btn", $("#modal-container")).text("{{ action | capitalize}} Metric").prop("disabled", false);
                }
            }).fail(function(data) {
                console.log("ERROR2", data);

                // Error in the Ajax request, re-enable the button and display a message
                this.error_message = "There was an error saving your data, please try again or contact support if this continues.";
                $(".pa-wizard-row-col_ft .pa-btn", $("#modal-container")).text("{{ action | capitalize}} Metric").prop("disabled", false);
            });
            return true;
        },

        resetFormErrors() {
            this.errorListObject = [];
            for (var i=0; i<this.email_addresses.length; i++) {
                    this.errors["email_addresses[" + i + "]['error']"] = false;
                }
            for (var i=0; i<this.phone_numbers.length; i++) {
                    this.errors["phone_numbers[" + i + "]['error']"] = false;
                }

            this.errors = {};
        },

        generateMobileToken: function() {
            $.ajax({
                url: '/userconfig/generateMobileToken',
                context: this,
            }).done(function(data) {
                if (data.success) {
                    this.mobile_token = data.new_token;
                } else {
                    console.error(data);
                }
            });
        },

        removeSlack: function(contactChannelId) {
            var payload = {
                contact_channel_id: contactChannelId,
            };
            $.ajax({url: "/userconfig/removeSlack",
                method: 'GET',
                data: payload,
                context: this,
            })
                .done(function(data) {
                    if (!data.success) {
                        console.log("Could not delete slack link.");
                    } else {
                        var newUsers = this.slack_users.filter(function(d) {
                            return d[0] !== contactChannelId;
                        }.bind(this));
                        this.slack_users = newUsers;
                    }
                });
        },

        openSlackModal: function() {
            this.slackCodeSent = false;
            this.slackError = '';
            $.ajax({
                url: '/userconfig/getSlackTeams',
                context: this,
            }).done(function(data) {
                this.slackTeams = data.teams;
                this.$refs.slackModal.open();
            });

            // Hack for safari rendering modal wrong
            $("#dynamic-drawer").css({ overflow: 'visible' });
        },

        startSlackLink: function() {
            var form = $(this.$els.slackLinkForm);
            var formData = form.serialize();
            $.ajax({
                url: '/userconfig/linkSlack',
                data: formData,
                context: this,
            }).done(function(data) {
                console.log(data);
                if (!data.success && data.message) {
                    this.slackError = data.message;
                    return;
                } else if (!data.success) {
                    this.slackError = 'There was a problem connecting with Slack. ' +
                        'Please try again later, or contact support.';
                    return;
                }
                this.slackCodeSent = true;
            });
        },

        finishSlackLink: function() {
            var form = $(this.$els.slackConfirmForm);
            var formData = form.serialize();
            $.ajax({
                url: '/userconfig/confirmSlackUser',
                data: formData,
                context: this,
            }).done(function(data) {
                console.log(data);
                if (!data.success) {
                    this.slackError = data.slack_error;
                    this.slackCodeSent = false;
                } else {
                    this.slack_users.push(data.new_user);
                    this.$refs.slackModal.close();
                }
            });
        },

        undoSafariHack: function() {
            $("#dynamic-drawer").css({ overflow: '' });
        },

        viewAccount: function(account) {
            window.location.href = account.account_link;
        },

        leaveSecondaryAccount: function() {
            $('#self-form').submit(function(event) {
                event.preventDefault();
            });
            window.location="/users/users/LeaveSecondaryAccount";
        },

    },
    computed: {
        navTitle() {
            if (this.creating) {
                return {};
            } else if (!this.userId) {
                return {
                    "Created On": this.createdOnDate
                };
            }
            return {
                "Last Login": this.lastLoginDate,
                // "Last Modified": this.lastModifiedDate,    WILL ADD WHEN AVAILABLE
                "Created On": this.createdOnDate
            };
        },
        avatarComputed() {
            if (this.avatar) {
                return this.avatar;
            } else {
                return {"icon": "default-photo"};
            }
        },
        accessBadge() {
            if(this.access_badge) {
                return this.access_badge
            } else {
                return null;
            }

        },
        lastLoginDate() {
            if(this.last_login) {
                return this.last_login;
            } else if(this.creating) {
                return '';
            } else {
                return '—';
            }
        },
        createdOnDate() {
            if(this.created_on) {
                return this.created_on;
            } else if(this.creating) {
                return '';
            } else {
                return '—';
            }
        },
        systemRoleOptionsFiltered() {
            return this.systemRoleOptions.filter((role) => role.id !== Number(this.accountAdminRoleVal));
        },
        accountDisableRoles() {
            return this.selected_roles.indexOf(this.accountAdminRoleVal) !== -1;
        },
        subAccountDisableRoles() {
            return this.selected_brand_roles.indexOf(this.accountAdminRoleVal) != -1;
        },
        groupColRenders: function() {
            if(this.canDeleteGroup) {
                return {0: 'checkbox', 3:'list', 4: 'menu'};
            } else {
                return {0: 'hidden', 3:'list', 4: 'menu'};
            }
        },

        is_secondary_user: function() {
            return Boolean(this.secondary_user);
        },

        shouldShowPasswordSetup() {
            let show = true;
            if (this.customer_has_sso && !this.non_sso_login) {
                show = false;
            };
            if (this.pendingId) {
                show = false;
            };
            if (this.is_secondary_user) {
                show = false;
            };
            if (this.brand_type == 'whitelabel' && this.brand != 'panopta') {
                show = false;
            }
            if (this.userId) {
                this.password_setup = 'password';
                show = false;
            }
            return show;
        },

        shouldShowPassword() {
            return true;
            let show = true;
            if (this.customer_has_sso && !this.non_sso_login) {
                show = false;
            }
            if (this.password_setup != 'password'){
                show = false;
            }
            if (this.is_secondary_user) {
                show = false;
            }
            return show;
        },

        showAllowNonSSO() {
            if (this.account_type == 'user'
                && this.customer_has_sso
                && !this.secondary_user
            ) {
                return true;
            }
            return false;
        },

        selectDashboardOptions() {
            if (this.dashboard_access_type === 'all') {
                return this.dashboard_options;
            }
            return this.dashboard_options.filter(function(o) {
                return o.value === '0'
                    || this.select_dashboards.includes(o.value);
            }.bind(this));
        },
    },

    watch: {
        // Account Admin roles trump all others
        selected_roles: function() {
            if (this.selected_roles.indexOf(this.accountAdminRoleVal) != -1 && this.selected_roles.length > 1) {
                this.selected_roles = [this.accountAdminRoleVal];
            }
        },
        selected_brand_roles: function() {
            if (this.subAccountDisableRoles && this.selected_brand_roles.length > 1) {
                this.selected_brand_roles = [this.accountAdminRoleVal];
            }
        },
    },

    mounted() {
        this.systemRoleOptions = JSON.parse(this.iSystemRoleOptions);
        this.customerRoleOptions = JSON.parse(this.iCustomerRoleOptions);
        this.preselectedGroups = JSON.parse(this.iPreselectedGroups);
        this.contactId = JSON.parse(this.iContactId);
        this.pendingId = JSON.parse(this.iPendingId);
        this.userId = JSON.parse(this.iUserId);
        let configData = JSON.parse(this.iConfigData);
        this.fullname = configData.fullname;
        this.title_name = this.fullname;
        this.timezone = configData.timezone,
        this.brand = configData.brand;
        this.brand_type = configData.brand_type;
        this.creating = configData.creating;
        this.timezone_options = configData.timezone_options;
        this.account_type = configData.account_type;
        this.selected_roles = configData.selected_roles;
        this.selected_brand_roles = configData.selected_brand_roles;
        this.customer_has_legacy_alerts = configData.customer_has_legacy_alerts;
        this.customer_has_sso = configData.customer_has_sso;
        this.customer_has_external_contacts = configData.customer_has_external_contacts;
        this.is_external = configData.is_external;
        this.non_sso_login = configData.non_sso_login;
        this.show_password_reset = configData.show_password_reset;
        this.password_setup = configData.password_setup;
        this.password = configData.password;
        this.password2 = configData.password2;
        this.access_level = configData.access_level;
        this.access_badge = configData.access_badge;
        this.access_level_options = configData.access_level_options;
        this.server_group_access = configData.server_group_access;
        this.server_groups = configData.server_groups;
        this.tags = configData.tags;
        this.default_dashboard = configData.default_dashboard;
        this.dashboard_options = configData.dashboard_options;
        this.dashboard_access_type = configData.dashboard_access_type;
        this.select_dashboards = configData.select_dashboards;
        this.email_addresses = configData.email_addresses;
        this.show_email_prefs = configData.show_email_prefs;
        this.email_preferences = configData.email_preferences;
        this.phone_numbers = configData.phone_numbers;
        this.twitter_handles = configData.twitter_handles;
        this.gchat_handles = configData.gchat_handles;
        this.mobile_devices = configData.mobile_devices;
        this.alert_type_options = configData.alert_type_options;
        this.email_alert_type_options = configData.email_alert_type_options;
        this.secondary_user = configData.secondary_user;
        this.secondary_user_email = configData.secondary_user_email || configData.email_address_external_user;
        this.is_forticloud_user = configData.is_forticloud_user;
        this.is_forticloud_iam_user = configData.is_forticloud_iam_user;
        this.iam_user_data = configData.iam_user_data;
        this.forticloud_redirect = configData.forticloud_redirect;
        this.last_login = configData.last_login;
        this.created_on = configData.created_on;
        this.fortinet_textkey = configData.fortinet_textkey;
        this.customer_key = configData.customer_key;
        this.show_mobile_token = configData.show_mobile_token;
        this.nav_items = configData.nav_items;
        this.user_accounts = configData.user_accounts;
        this.customer_indicator = configData.customer_indicator;
        this.has_secondary_users = configData.has_secondary_users;
        this.slack_users = configData.slack_users;
        this.slack_app_configured = configData.slack_app_configured;
        this.show_sub_account_info = configData.show_sub_account_info;
        this.panopta_brand = configData.panopta_brand;
        this.password_url = configData.password_url;
        this.password_label = configData.password_label;
        this.show_password_fields = configData.show_password_fields;
        this.has_password_section = configData.has_password_section;
    },
});
</script>
