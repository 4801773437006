<style>
 #metric_tags {
     width: 100%;
 }
 .separator {
     border-top: solid 0.5px #dddddd;
     border-bottom: 0;
     border-left: 0;
     border-right: 0;
 }
</style>

<template>
    <div class="module" :class="{'open': open}">
        <div v-show="!isReady || metricOptions.length">
            <div class="pa-field pa-field_vertical">
                <div class="pa-field-hd">
                    <svg v-show="!isReady" class="pa-icon pa-icon_xs spin-8step"><use xlink:href="#spinner-spin"></use></svg>
                </div>
                <div class="pa-field-bd">
                    <p-typeahead-select
                        ref="metricselect"
                        :force-in-modal="true"
                        :disabled="!isReady"
                        :model="selectedMetrics"
                        :optgroups="metricOptions"
                        placeholder="Add metrics by searching within this box - you can search by metric (e.g., CPU % Used) or category (CPU)"
                    >
                    </p-typeahead-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    const InstanceModule = Vue.extend({
        inject: {
            dashboard: {
                default: () => ({}),
            },
        },

        data() {
            return {
                isReady: false,
                available_metrics: {},
                metricOptions: [],
                app_textkeys: [],
                metrics: {},
                unit: '',
                secondaryFilterOptions: [
                    {
                        label: 'No Filter',
                        value: 'none',
                    },
                    {
                        label: 'By Tags',
                        value: 'tags',
                    },
                    {
                        label: 'By Option String',
                        value: 'optionString',
                    },
                ],
            };
        },

        props: {
            options: {
                type: Object,
                default: function() {
                    return {};
                },
            },
            open: {
                type: Boolean,
                default: true,
            },
            selectedMetrics: {
                type: Array,
                default: function() {
                    return [];
                }
            },
            tags: {
                type: Array,
                default: function() {
                    return [];
                }
            },
            tagsType: {
                type: String,
                default: 'any'
            },
            optionString: {
                type: String,
                default: ''
            },
            secondaryFilter: {
                type: String,
                default: 'none',
            }
        },

        computed: {
            filterTags: function() {
                return this.secondaryFilter === 'tags';
            },
            filterOptionString: function() {
                return this.secondaryFilter === 'optionString';
            },
            sameUnit: function() {
                if (!this.options.sameUnit || this.unit === '') { return this.metricOptions; }
                const newOptions = {};
                for (var key in this.metricOptions) {
                    if (!this.metricOptions.hasOwnProperty(key)) {
                        continue;
                    }
                    const newOpts = [];
                    this.metricOptions[key].forEach(s => {
                        const newSubOpts = [];
                        s.options.forEach(m => {
                            if (this.unit === 'network' && m.check_method === 'network') {
                                newSubOpts.push(m);
                            } else if (m.unit === this.unit) {
                                newSubOpts.push(m);
                            } else {
                                newSubOpts.push(Object.assign({ disabled: true }, m));
                            }
                        });
                        newOpts.push({
                            label: s.label,
                            options: newSubOpts,
                        });
                    });
                    newOptions[key] = newOpts;
                }
                return newOptions;
            },
            headerText: function() {
                if (!this.metricOptions.length) {
                    return '';
                }
                let metricCategories = [];
                this.$refs.metricselect.modelLabels.forEach(l => {
                    if (!l.label) {
                        return;
                    }
                    const newLabel = l.label.split(':')[0];
                    if (!metricCategories.includes(newLabel)) {
                        metricCategories.push(newLabel);
                    }
                });
                if (metricCategories.length) {
                    metricCategories = metricCategories.join(', ');
                    return `(${metricCategories})`;
                } else {
                    return '';
                }
            },
            allTags: function() {
                if (this.dashboard.isReseller) {
                    return [];
                } else if (this.$root.customerConfig) {
                    return this.$root.customerConfig.allTags;
                } else {
                    return [];
                }
            },
        },

        events: {
            'config:update_metrics': function() {
                this.getAvailableMetrics(() => {
                    Vue.nextTick(() => {
                        this.isReady = true;
                    });
                });
            },
            'config:wait_for_metrics': function() {
                // const button = this.$refs.app_select.$refs.button;
                // button.disabled = true;
            },
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },

            getAvailableMetrics(callback) {
                // First grab our copy from local storage (if exists)
                const localAvailableMetrics =
                    window.app.localStorageManager.getObject('metrics.available', {});

                const payload = {
                };
                // Include all the keys from our local copy, minus the actual options
                Object.entries(localAvailableMetrics).forEach(e => {
                    const key = e[0];
                    const val = e[1];
                    if (e[0] !== 'options') {
                        payload[key] = val;
                    }
                });

                $.ajax({
                    url: '/dashboardv2/getAvailableMetrics',
                    method: 'GET',
                    context: this,
                    data: payload,
                })
                 .done(function(data) {
                     if (!data.success) { return; }

                     if (data.new_value) {
                         this.metricOptions = data.metric_options.options;
                         // Store the new copy
                         window.app.localStorageManager
                             .setObject('metrics.available', data.metric_options);
                     } else if (localAvailableMetrics) {
                         this.metricOptions = localAvailableMetrics.options;
                     } else {
                         console.error('Cannot get metrics');
                         this.metricOptions = [];
                     }

                     // Call the callback first
                     if (typeof callback !== 'undefined') {
                         callback();
                     }
                 });
            },

            sortOptions(opts) {
                // sort() works in place
                // we don't want infinite loop
                const sorted = opts.slice();
                sorted.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                return sorted;
            },
        },

        watch: {
            'metrics': {
                handler: function(value) {
                    if (!this.metricOptions) { return; }
                    if (this.unit === '') {
                        for (var key in this.metrics) {
                            if (this.metrics[key].length) {
                                const metric = this.metrics[key][0];
                                const subcats = this.metricOptions[key];
                                if (!subcats) { continue; }
                                for (let i = 0; i < subcats.length; i++) {
                                    const options = subcats[i].options;
                                    for (let j = 0; j < options.length; j++) {
                                        if (options[j].textkey === metric) {
                                            if (options[j].check_method === 'network') {
                                                this.unit = 'network';
                                                return;
                                            }
                                            this.unit = options[j].unit;
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        // Check if empty again
                        for (var key in this.metrics) {
                            if (this.metrics[key].length) {
                                return;
                            }
                        }
                        this.unit = '';
                    }
                },
                deep: true,
            },
            'tags': function(value, prev) {
                /* this.sendConfig(); */
            },
            'tagsType': function(value) {
                /* this.sendConfig(); */
            },
            'optionString': function(value) {
                /* this.sendConfig(); */
            },
            'secondaryFilter': function(value) {
                if (!this.isReady) { return; }
                // Changing filter type
                // Clear out the models
                this.isReady = false;
                this.tags = [];
                this.optionString = '';
                Vue.nextTick(() => {
                    this.isReady = true;
                    /* this.sendConfig(); */
                });
            }
        },

        vueReady() {
            this.getAvailableMetrics(() => {
                const selectedMetrics = this.options.metric_textkeys;
                Vue.nextTick(() => {
                    this.isReady = true;
                });
            });
        },
    });

    export default InstanceModule;
</script>
