<style lang="scss">
#onboarding-catalog {
    
    &.pa-card2 {
        max-width: 1000px;
        height: calc(100vh - 100px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        & > .pa-card-header {
            height: 75px;

            display: flex;
            align-items: center;

            border-bottom: 1px solid #E6E6E6;
        }

        & > .pa-card-body {
            height: 100%;
            overflow-y: auto;
        }

        & > .pa-card-footer {
            height: 50px;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            border-top: 1px solid #E6E6E6;
        }
    }

    .pa-expando-hd {
        border: none;
        background: transparent;
        padding: 0;
    }
    .pa-expando-hd-wrapper {
        background: transparent;
        border: none;
        border-bottom: 1px solid #e1e4e6;
        margin: 2px 0 0;
        padding: 0.625rem 0 0.625rem 0;
    }
    .pa-expando-hd-wrapper .pa-expando-hd-icon {
        right: 1.625rem;
    }
    .obf-catalog-card {
        display: inline-block;
        border: 1px solid transparent;
        border-color: #E6E6E6;
        box-shadow: 0 2px 4px 1px #E6E6E6;
        max-height: 205px;
        height: 100%;
        background-color: white;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        text-align: left;

        .pa-btn.pa-btn_naked_blue {
            border: 1px solid #2c4163 ;
            background-color: white ;
            color: #2c4163 ;

            &:hover {
                background-color: #2c4163 ;
                color: white ;
            }
        }

        &--selected {
            border-color: "transparent";
            box-shadow: 0 0 0 1px #3954bf;

            .pa-btn.pa-btn_naked_blue {
                background-color: #2c4163;
                color: white;
            }
        }

        &_icon {
            height: 40px;
            width: 40px;
        }

        h4 {
            color: #3c3d3e;
        }

        p {
            color: #656f83;
        }

        .description-truncate {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .third-party-disabled {
        background-color: rgb(128 128 128 / 21%);
        cursor: default;
        color: rgba(128, 128, 128, 0.655);
    }

    .third-party-text-disabled {
        color: rgba(128, 128, 128, 0.655) !important;

        & > div {
            margin: auto;
        }
    }
}
.fortinet {
    .obf-catalog-card {
        &--selected {
            border-color: #243746;
            box-shadow: none;
        }
    }
}
</style>

<template>
    <p-card
        id="onboarding-catalog"
        class="pa-txt_sm"
        header-class="pa-px-16 pa-py-16"
        body-class="pa-px-16"
        footer-class="pa-py-10"
    >
        <div slot="card-header">
            <h2 class="pa-txt_medium pa-txt_24">
                {{
                    isNewUser
                        ? "What needs monitoring?"
                        : "Infrastructure and Resource Catalog"
                }}
            </h2>

            <h3 v-if="isNewUser" class="pa-txt_gey-700 pa-txt_17 pa-pt-8">
                Select infrastructure you'd like to monitor and continue to configure
            </h3>
        </div>

        <div>

            <p-input
                v-if="false"
                placeholder="Search Infrastructure types"
                v-model="infrastructureTypesSearch"
                class="pa-mb-32"
            >
            </p-input>

            <p
                v-if="filteredInfrastructureTypes.length === 0 && infrastructureTypesSearch !== ''"
                class="pa-m-0 pa-py-32 pa-text-grey-600 pa-txt_centered"
            >
                No infrastructure matching search term "{{ infrastructureTypesSearch }}"
            </p>

            <p-expando :is-active="true" title="Infrastructure" body-class="pa-p-0">
                <p-row v-if="filteredInfrastructureTypes.length > 0" class="pa-mb-32 pa-pt-16">
                    <p-column
                        :key="index"
                        v-for="(option, index) in filteredInfrastructureTypes"
                        cols="4"
                        class="pa-p-8"
                    >
                        <label
                            :for="`${INFRA_OPTION_PREFIX}${infrastructureTypes[option.title].id}`"
                            :class="{
                                'pa-p-16': true,
                                'obf-catalog-card': true,
                                'obf-catalog-card--selected': option.isSelected,
                            }"
                        >
                            <input
                                type="checkbox"
                                :id="`${INFRA_OPTION_PREFIX}${infrastructureTypes[option.title].id}`"
                                :value="`${INFRA_OPTION_PREFIX}${infrastructureTypes[option.title].id}`"
                                v-model="selectedInfrastructureTypes"
                                class="hidden-checkbox"
                            />
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div class="pa-pb-16">
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="12" class="pa-p-0">
                                            <img
                                                v-if="option.logoType === 'image'"
                                                :src="option.logo"
                                                :height="option.id === 'onsight' ? 'auto' : 40"
                                                :width="option.id === 'onsight' ? 50 : 40"
                                                class="pa-img"
                                            />
                                            <p-icon
                                                v-else
                                                class="obf-catalog-card_icon"
                                                :icon="option.logo"
                                                :style="{
                                                    height: option.logo === 'new-obf_UNIX'
                                                    ? '20px'
                                                    : '40px',
                                                    width: option.logoWidth ? option.logoWidth + 'px' : '40px'
                                                }"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        {{ option.title }}
                                    </h4>
                                    <p :title="option.description" class="pa-txt_xs pa-m-0 description-truncate">
                                        {{ option.description }} 
                                    </p>
                                </div>
                                <div>
                                    <p-button v-if="option.url"
                                        variant="naked_blue" @click="goToUrl(option.url)">
                                        {{
                                            option.isSelected
                                            ? 'Selected'
                                            : 'Select'
                                        }}
                                    </p-button>
                                    <p-button v-else variant="naked_blue" @click="toggleInfrastructureType(option)">
                                        {{
                                            option.isSelected
                                            ? 'Selected'
                                            : 'Select'
                                        }}
                                    </p-button>
                                </div>
                            </p-flex>
                        </label>
                    </p-column>
                    <p-column v-if="catalogConfig.show_kubernetes" cols="4" class="pa-p-8">	
                        <a class="pa-p-16 obf-catalog-card" target="_blank" href="https://docs.fortimonitor.forticloud.com/user-guide/33284/kubernetes">	
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="new-obf_Kubernetes"
                                                :style="{
                                                    height: '40px',
                                                    width: '40px',
                                                }"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Kubernetes
                                    </h4>
                                    <p 
                                        title="Deploy the OnSight collector and agent into your Kubernetes cluster to get visibility into namespace, node, pod and container performance."
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                            Deploy the OnSight collector and agent into your Kubernetes cluster to get visibility into namespace, node, pod and container performance.
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                </p-row>
            </p-expando>

            <p-expando v-if="catalogConfig.show_config" :is-active="true" title="Config" body-class="pa-p-0">
                <p-row class="pa-mb-32 pa-pt-16">
                    <p-column v-if="catalogConfig.show_template && catalogConfig.has_server_template" cols="4" class="pa-p-8">
                        <a 
                            class="pa-p-16 obf-catalog-card"
                            type="button"
                            @click="openCreateTemplateModal()"
                        >
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="template-2"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Template
                                    </h4>
                                    <p 
                                        title="Standardize your monitoring by defining your monitoring rules to be used across your infrastructure"
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                            Standardize your monitoring by defining your monitoring rules to be used across your infrastructure
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                    <p-column v-if="catalogConfig.show_group" cols="4" class="pa-p-8">
                        <a class="pa-p-16 obf-catalog-card" type="button" v-p-dialog-open href="/config/EditServerGroup" target="dynamic_modal">
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="instance-group"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Instance Group
                                    </h4>
                                    <p 
                                        title="Logical grouping of your instances that also provides the power of default templates"
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                            Logical grouping of your instances that also provides the power of default templates
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                    <p-column v-if="catalogConfig.show_user" cols="4" class="pa-p-8">
                        <a class="pa-p-16 obf-catalog-card" href="/users/users/EditUser">
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="user"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        New Users
                                    </h4>
                                    <p 
                                        title="Add a new user to your account"
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                            Add a new user to your account
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                    <p-column v-if="catalogConfig.show_tags_attributes" cols="4" class="pa-p-8">
                        <a class="pa-p-16 obf-catalog-card" href="/config/BulkUpload">
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="user"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Tags & Attributes
                                    </h4>
                                    <p
                                        title="Bulk upload tags & attributes from an xlsx file"
                                        class="pa-txt_xs pa-pb-16 pa-m-0"
                                    >
                                        Bulk upload tags & attributes from an xlsx file
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                </p-row>
            </p-expando>

            <p-expando v-if="catalogConfig.show_alerting || catalogConfig.show_reporting" :is-active="true" title="Alerting & Reporting" body-class="pa-p-0">
                <p-row class="pa-mb-32 pa-pt-16">
                    <p-column v-if="catalogConfig.show_alert_timeline" cols="4" class="pa-p-8">
                        <a class="pa-p-16 obf-catalog-card" v-p-dialog-open href="/config/notification/EditNotificationScheduleDetails" target="dynamic_modal">
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="escalate-alerts"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Alert Timeline
                                    </h4>
                                    <p 
                                        title="Define the notifications events to be taken throughout the lifetime of an incident"
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                            Define the notifications events to be taken throughout the lifetime of an incident
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                    <p-column v-if="catalogConfig.show_compound" cols="4" class="pa-p-8">
                        <a class="pa-p-16 obf-catalog-card" @click="addCompoundService">
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="compound-service-2"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Compound Metric
                                    </h4>
                                    <p
                                        title="Monitor any number of metrics as a single item"
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                        Monitor any number of metrics as a single item
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                    <p-column cols="4" class="pa-p-8">
                        <button class="pa-p-16 obf-catalog-card" type="button" @click="toggleCreateDashboard">
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="dashboards-2"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Dashboard
                                    </h4>
                                    <p
                                        title="Create a new dashboard to visualize your monitoring data"
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                            Create a new dashboard to visualize your monitoring data
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </button>
                    </p-column>
                    <p-column v-if="catalogConfig.show_report" cols="4" class="pa-p-8">
                        <a class="pa-p-16 obf-catalog-card" :href="catalogConfig.report_link">
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div>
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="10" class="pa-p-0">
                                            <p-icon
                                                class="obf-catalog-card_icon"
                                                icon="reporting"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                        <p-column cols="2" class="pa-p-0">
                                            <p-icon
                                                icon="checkmark-outline"
                                                color="blue"
                                                size="xxxxl"
                                                :style="{ visibility: 'hidden' }"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 class="pa-txt_medium pa-txt_16 pa-pb-8">
                                        Report
                                    </h4> 
                                    <p
                                        title="Select from a set of pre-defined report types to create a one-off or recurring report"
                                        class="pa-txt_xs pa-pb-16 pa-m-0">
                                        Select from a set of pre-defined report types to create a one-off or recurring report   
                                    </p>
                                </div>
                                <div>
                                    <p-button variant="naked_blue">Select</p-button>
                                </div>
                            </p-flex>
                        </a>
                    </p-column>
                </p-row>
            </p-expando>

            <p-expando v-if="showThirdParty" :is-active="true" title="Third Party Integration" body-class="pa-p-0">
                <p-row v-if="filteredThirdPartyIntegrations.length > 0" class="pa-mb-32 pa-pt-16">
                    <p-column
                        :key="index"
                        v-for="(option, index) in filteredThirdPartyIntegrations"
                        cols="4"
                        class="pa-p-8"
                    >
                        <label
                            :for="`${INTEGRATION_OPTION_PREFIX}${thirdPartyTypes[option.title].id}`"
                            :class="{
                                'pa-p-16': true,
                                'obf-catalog-card': true,
                                'obf-catalog-card--selected': option.isSelected,
                                'third-party-disabled': integrationDisabled(option),
                            }"
                        >
                            <input
                                type="checkbox"
                                :id="`${INTEGRATION_OPTION_PREFIX}${thirdPartyTypes[option.title].id}`"
                                :value="`${INTEGRATION_OPTION_PREFIX}${thirdPartyTypes[option.title].id}`"
                                v-model="selectedThirdPartyTypes"
                                class="hidden-checkbox"
                                :disabled="integrationDisabled(option)"
                            />
                            <p-flex space-between dir="column" style="height: 100%;">
                                <div class="pa-pb-16">
                                    <p-row justify-between align-center class="pa-m-0 pa-pb-16">
                                        <p-column cols="12" class="pa-p-0">
                                            <img
                                                v-if="option.logoType === 'image'"
                                                :src="option.logo"
                                                :height="option.id === 'onsight' ? 'auto' : 40"
                                                :width="option.id === 'onsight' ? 50 : 40"
                                                class="pa-img"
                                            />
                                            <p-icon
                                                v-else
                                                class="obf-catalog-card_icon"
                                                :icon="option.logo"
                                                :style="{
                                                    height: option.logo === 'new-obf_UNIX'
                                                    ? '20px'
                                                    : '40px',
                                                    width: option.logoWidth ? option.logoWidth + 'px' : '40px'
                                                }"
                                                color="grey-600"
                                            >
                                            </p-icon>
                                        </p-column>
                                    </p-row>
                                    <h4 :class="{
                                        'pa-txt_medium pa-txt_16': true,
                                        'pa-pb-3': option.author != '',
                                        'pa-pb-8': option.author == '',
                                        'third-party-text-disabled': integrationDisabled(option)
                                    }">
                                        {{ option.title }}
                                    </h4>
                                    <h2 class="pa-txt_sm pa-txt_8 pa-pb-8">
                                        {{ option.author }}
                                    </h2>
                                    <p :title="option.description" class="pa-txt_xs pa-m-0 description-truncate">
                                        {{ option.description }} 
                                    </p>
                                </div>
                                <div :class="{
                                    'third-party-text-disabled': integrationDisabled(option),
                                    'pa-flex': integrationDisabled(option)
                                }">
                                    <p-button v-if="option.url"
                                        variant="naked_blue" @click="goToUrl(option.url)">
                                        {{
                                            option.isSelected
                                            ? 'Selected'
                                            : 'Select'
                                        }}
                                    </p-button>
                                    <p-button
                                        v-else 
                                        :disabled="integrationDisabled(option)" 
                                        variant="naked_blue" 
                                        @click="toggleThirdPartyType(option)"
                                    >
                                        {{
                                            option.isSelected
                                            ? 'Selected'
                                            : 'Select'
                                        }}
                                    </p-button>
                                    <div v-if="integrationDisabled(option)">
                                        Max Instances Reached
                                    </div>
                                </div>
                            </p-flex>
                        </label>
                    </p-column>
                </p-row>
            </p-expando>

        </div>

        <template v-if="isNewUser" slot="card-footer">
            <p-button
                :disabled="!hasSelectedInfrastructure"
                class="pa-mr-24"
                @click="handleSubmit"
            >
                Configure Infrastructure
            </p-button>
        </template>
        <p-create-server-template
            ref="createTemplateModal"
            v-if="usingCreateTemplate"
        >
        </p-create-server-template>
    </p-card>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { INFRA_OPTION_PREFIX } from "../shared/constants/onboarding";
import { INTEGRATION_OPTION_PREFIX } from "../shared/constants/onboarding";

export default Vue.extend({
    props: {
        goToRoute: {
            type: Function,
            default: () => {},
        },
        infrastructureTypes: {
            type: Object,
            default: () => {},
        },
        thirdPartyTypes: {
            type: Object,
            default: () => {},
        },
        catalogConfig: {
            type: Object,
            default: () => {},
        },
        isNewUser: {
            type: Boolean,
            default: false,
        },
        onSubmit: {
            type: Function,
            default: () => {},
        },
    },
    data: () => ({
        infrastructureTypesSearch: "",
        selectedInfrastructureTypes: [],
        selectedThirdPartyTypes: [],
        integration_metadata: null,
        INFRA_OPTION_PREFIX,
        INTEGRATION_OPTION_PREFIX,
        usingCreateTemplate: false,
    }),
    watch: {
        hasSelectedInfrastructure(hasSelectedInfrastructure) {
            if (hasSelectedInfrastructure && !this.isNewUser) {
                this.handleSubmit();
            }
        },
        hasSelectedThirdPartyIntegration(hasSelectedThirdPartyIntegration) {
            if (hasSelectedThirdPartyIntegration && !this.isNewUser) {
                this.handleSubmit();
            }
        },
    },
    computed: {
        selectedInfrastructure() {
            const selected = this.selectedInfrastructureTypes.map(
                (selected) => selected.replace(INFRA_OPTION_PREFIX, "")
            );
            const _selected = [];

            Object.values(this.mappedInfrastructureTypes).forEach((infraType) => {
                if (selected.includes(infraType.id)) {
                    _selected.push(infraType);
                }
            });

            return _selected;
        },
        selectedThirdParty() {
            const selected = this.selectedThirdPartyTypes.map(
                (selected) => selected.replace(INTEGRATION_OPTION_PREFIX, "")
            );
            const _selected = [];

            Object.values(this.mappedIntegrationTypes).forEach((integration) => {
                if (selected.includes(integration.id)) {
                    _selected.push(integration);
                }
            });

            return _selected;
        },
        hasSelectedInfrastructure() {
            return (
                this.selectedInfrastructureTypes &&
                this.selectedInfrastructureTypes.length &&
                this.selectedInfrastructureTypes.length > 0
            );
        },
        hasSelectedThirdPartyIntegration() {
            return (
                this.selectedThirdPartyTypes &&
                this.selectedThirdPartyTypes.length &&
                this.selectedThirdPartyTypes.length > 0
            );
        },
        filteredInfrastructureTypes() {
            return Object.values(this.mappedInfrastructureTypes)
                .filter(this.filterFromHidden)
                .filter(this.filterFromSearch) || [];
        },
        mappedInfrastructureTypes() {
            const mappedInfrastructureTypes = {};

            if (!!this.infrastructureTypes && !_.isEmpty(this.infrastructureTypes)) {
                Object.keys(this.infrastructureTypes).forEach((title) => {
                    if (
                        !this.infrastructureTypes[title].component ||
                        ["index", "action-plan", "not-found"].includes(this.infrastructureTypes[title].id)
                    ) {
                        return;
                    }

                    const defaultDescription = "Connect your cloud infrastructure with FortiMonitor to get a single-pane view of your entire infrastructure.";
                    const description = this.infrastructureTypes[title].description || defaultDescription;
                    const isSelected = this.selectedInfrastructureTypes.includes(`${INFRA_OPTION_PREFIX}${this.infrastructureTypes[title].id}`);
                    const isHidden = this.checkIfHidden(this.infrastructureTypes[title].id);

                    mappedInfrastructureTypes[title] = {
                        ...this.infrastructureTypes[title],
                        title,
                        description,
                        isHidden,
                        isSelected,
                    };
                });
            }

            let sortable = [];

            for (let key in mappedInfrastructureTypes) {
                let value = mappedInfrastructureTypes[key];
                sortable.push([key, value.order, value]);
            }

            sortable.sort((a, b) => a[1] - b[1]);

            let sorted = {};

            sortable.forEach((item) => {
                let key = item[0];
                let value = item[2];

                sorted = {
                    ...sorted,
                    [key]: value,
                };
            });

            return sorted;
        },
        filteredThirdPartyIntegrations() {
            return Object.values(this.mappedIntegrationTypes)
                .filter(this.filterFromHidden) || [];
        },
        mappedIntegrationTypes() {
            const mappedIntegrationTypes = {};

            if (!!this.thirdPartyTypes && !_.isEmpty(this.thirdPartyTypes)) {
                Object.keys(this.thirdPartyTypes).forEach((title) => {
                    if (
                        !this.thirdPartyTypes[title].component ||
                        ["index", "action-plan", "not-found"].includes(this.thirdPartyTypes[title].id)
                    ) {
                        return;
                    }

                    const defaultDescription = "Connect your third-party instances with FortiMonitor to get a single-pane view of your entire infrastructure.";
                    const description = this.thirdPartyTypes[title].description || defaultDescription;
                    const isSelected = this.selectedThirdPartyTypes.includes(`${INTEGRATION_OPTION_PREFIX}${this.thirdPartyTypes[title].id}`);
                    const isHidden = this.checkIfHidden(this.thirdPartyTypes[title].id);

                    mappedIntegrationTypes[title] = {
                        ...this.thirdPartyTypes[title],
                        title,
                        description,
                        isHidden,
                        isSelected,
                    };
                });
            }

            let sortable = [];

            for (let key in mappedIntegrationTypes) {
                let value = mappedIntegrationTypes[key];
                sortable.push([key, value.order, value]);
            }

            sortable.sort((a, b) => a[1] - b[1]);

            let sorted = {};

            sortable.forEach((item) => {
                let key = item[0];
                let value = item[2];

                sorted = {
                    ...sorted,
                    [key]: value,
                };
            });

            return sorted;
        },
        searchTerm() {
            return this.infrastructureTypesSearch.trim().toLowerCase();
        },
        hasSearchTerm() {
            return !!this.searchTerm;
        },
        showThirdParty() {
            return !_.isEmpty(this.thirdPartyTypes);
        },
        integrationDisabled() {
            return (integration) => {
                return integration.max_instances_reached;
            };
        }
    },
    methods: {
        handleSubmit() {
            let data = {}
            if(this.hasSelectedInfrastructure){
                data["selectedInfrastructureTypes"] = this.selectedInfrastructure;
            } else if(this.hasSelectedThirdPartyIntegration) {
                data["selectedInfrastructureTypes"] = this.selectedThirdParty;
            }

            this.onSubmit(data);
        },
        toggleInfrastructureType(option) {
            const value = `${INFRA_OPTION_PREFIX}${this.infrastructureTypes[option.title].id}`;
            this.selectedInfrastructureTypes = _.xor(this.selectedInfrastructureTypes, [value]);
        },
        toggleThirdPartyType(option) {
            const value = `${INTEGRATION_OPTION_PREFIX}${this.thirdPartyTypes[option.title].id}`;
            this.selectedThirdPartyTypes = _.xor(this.selectedThirdPartyTypes, [value]);
        },
        toggleCreateDashboard() {
            window.app.rootVue.$broadcast('dashboard-properties-drawer:open');
        },
        checkIfHidden(infraId) {
            const config = {
                "aws": !(
                    this.catalogConfig.show_cloud
                ),
                "gcp": !(
                    this.catalogConfig.show_cloud
                ),
                "vmware": !(
                    this.catalogConfig.show_vmware
                ),
                "network-device-basic": !(
                    this.catalogConfig.show_network_device
                ),
                "network-device-advanced": !(
                    this.catalogConfig.show_network_device
                ),
                "linux-panopta": !(
                    this.catalogConfig.show_instance
                ),
                "linux-fm": !(
                    this.catalogConfig.show_instance
                ),
                "windows-server-panopta": !(
                    this.catalogConfig.show_instance
                ),
                "windows-server-fm": !(
                    this.catalogConfig.show_instance
                ),
                "windows-endpoint-panopta": !(
                    this.catalogConfig.show_instance
                ),
                "windows-endpoint-fm": !(
                    this.catalogConfig.show_instance
                ),
                "synthetic": !(
                    this.catalogConfig.show_instance
                ),
                "mac-dem-agent": !(
                    this.catalogConfig.show_mac_agent
                ),
                "azure": !(
                    this.catalogConfig.show_cloud &&
                    this.catalogConfig.has_azure
                ),
                "meraki": !(
                    this.catalogConfig.show_cloud &&
                    this.catalogConfig.show_meraki
                ),
                "onsight": !(
                    this.catalogConfig.show_onsight
                ),
                "fabric": !(
                    this.catalogConfig.create_fabric
                ),
                "sdwan": !(
                    this.catalogConfig.show_sdwan &&
                    this.catalogConfig.show_onsight
                ),
                "website": !(
                    this.catalogConfig.show_instance
                ),
            };

            if (!_.has(config, infraId)) {
                return false;
            }

            const isHidden = config[infraId];

            return isHidden;
        },
        addCompoundService() {
            window.location.href = '/report/EditCompoundMetric';
        },
        goToUrl(url) {
            window.app.rootVue.$broadcast('dialog:close', 'nav_modal');
            window.location.href = url;
        },
        filterFromHidden(option) {
            return !option.isHidden;
        },
        openCreateTemplateModal: function() {
            this.usingCreateTemplate = true;
            
            Vue.nextTick(() => {
                this.$refs.createTemplateModal.initData();
                this.eventHub.$emit('dialog:open', 'create_server_template_modal');
            });
        },
        filterFromSearch(option) {
            const searchValues = [option.title, option.description]
                .map(searchValue => searchValue.trim().toLowerCase())
                .filter(searchValue => searchValue);
            const isFilteredFromSearch = searchValues.some(searchValue =>
                searchValue.includes(this.searchTerm)
            );

            return isFilteredFromSearch;
        },
    },
});
</script>
