import { deepSearchItems } from "../../shared/utils/deepSearchItems";

export const matchesWindowUrl = (href, to) => {

    // Prevents marking the incident main nav menu as active
    // when the user is on the incidents sub-tab.
    if (href && href.includes("incidents") &&
        (window.location.href.includes("application") ||
            window.location.href.includes("Appliance"))) {
        return false;
    }

    return (
        (href && window.location.href.includes(href)) ||
        (to && window.location.pathname === to)
    );
};

export const getActiveNavItem = (navItems) => {
    const [result] = deepSearchItems(navItems, "active", (_, v) => v === true);
    return result;
};

export const getOpenNavItemMenus = (navItems) => {
    const openNavItemIds = new Set();
    const openNavItems = [];

    for (let i = 0; i < navItems.length; i++) {
        const navItem = navItems[i];
        const hasActiveChildItem = navItem.items
            ? navItem.items.some(item => {
                const hasActiveSecondaryItem = item.secondary_nav_items
                    ? item.secondary_nav_items.some(secondaryItem => secondaryItem.active)
                    : false;

                return item.active || hasActiveSecondaryItem;
            })
            : false;

        if (
            (
                navItem.open ||
                hasActiveChildItem
            ) &&
            !openNavItemIds.has(navItem.id)
        ) {
            openNavItemIds.add(navItem.id);
            openNavItems.push(navItem);
        }
    }

    return openNavItems;
};
