import { requests } from "../requests";

export const getCustomerSupportData = () => {
    return requests.get('/onboarding/getCustomerSupportData');
};

export const getTemplates = ({ device_type }) => {
    return requests.get('/onboarding/getTemplates', { params: { device_type } });
};

export const checkPrivateIPs = ({ fqdns }) => {
    return requests.post('/onboarding/checkPrivateIPs', null, { params: { fqdns } });
};

export const validateFqdns = ({ fqdns }) => {
    return requests.post('/onboarding/validateFqdns', null, { params: { fqdns } });
};

export const addNewServers = ({
    public_ips,
    private_ips,
    label,
    tags,
    parent_group,
    new_group_name,
    templates,
    monitoring_location,
    private_location,
    notification_timeline,
    device_type,
    new_obf,
    new_customer,
}) => {
    return requests.post('/onboarding/addNewServers', null, {
        params: {
            public_ips,
            private_ips,
            label,
            tags,
            parent_group,
            new_group_name,
            templates,
            monitoring_location,
            private_location,
            notification_timeline,
            device_type,
            new_obf,
            new_customer,
        }
    });
};

export const getIntegrationDetails = ({ textkey }) => {
    return requests.get('/onboarding/getIntegrationDetails', { params: { textkey } });
};

export const getIntegrationConfigMetadata = ({ integration_id }) => {
    return requests.get('/onboarding/getIntegrationConfigMetadata', { params: { integration_id } });
};

export const submitIntegrationConfigData = ({ submit_data }) => {
    return requests.post('/onboarding/submitIntegrationConfigs', submit_data);
};

export const submitThirdPartySelections = ({ submit_data }) => {
    return requests.post('/onboarding/submitThirdPartySelections', submit_data);
};

export const getDiscoveryId = ({ integration_config_id, agent_command_id }) => {
    return requests.get('/onboarding/getDiscoveryId', { params: { integration_config_id, agent_command_id } });
};

export const getDevicePorts = ({ server_id }) => {
    return requests.get('/onboarding/getDevicePorts', { params: { server_id } });
};

export const getThirdPartyAsyncTaskStatus = ({ async_task_id }) => {
    return requests.get('/onboarding/getThirdPartyAsyncTaskStatus', { params: { async_task_id } });
};

export const createServerGroup = (name, parent_group) => {
    return requests.post('/onboarding/addNewGroup', { name, parent_group });
};