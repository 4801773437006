<style lang="scss">
    .onboarding-steps {
        position: relative;
        background: white;

        &:before {
            height: 1px;
            background-color: #d5d8df;
            width: 100%;
            content: "";
            position: absolute;
            top: 50%;
            bottom: 0px;
        }

        .onboarding-steps-item {
            position: relative;
            margin: 0;
            background-color: white;
            padding: 0 8px;

            .onboarding-steps-vertical-line {
                background-color: #d5d8df;
                width: 2px;
                content: "";
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 5px;
                transform: translateY(-55%);
            }

            &:first-child {
                padding-left: 0;

                .onboarding-steps-vertical-line {
                    height: 12px;
                    transform: translateY(50%);
                }
            }

            &:last-child:not(:first-child) {
                padding-right: 0;
            }

            .onboarding-steps-symbol-wrapper {
                z-index: 1;

                .onboarding-steps-symbol {
                    border: 2px solid transparent;
                    border-radius: 18px;
                    width: 18px;
                    height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;

                    .timeline-circle {
                        width: 14px;
                        height: 14px;
                        border-radius: 14px;
                        background-color: #d5d8df;
                    }
                }
            }

            &.active {
                .onboarding-steps-symbol-wrapper {
                    .onboarding-steps-symbol {
                        border-color: #3954bf;
                        .timeline-circle {
                            background-color: #3954bf;
                        }
                    }
                }
            }

            &.active,
            &.finished {

                .onboarding-steps-vertical-line {
                    background-color: #3954bf;
                }
            }
        }
    }
</style>

<template>
    <p-flex dir="column" :data-current-step="currentStep">
        <div v-if="showSteps">
            <p-divider class="pa-m-0"></p-divider>
            <div class="pa-px-24" style="height: 48px;">
                <p-flex as="ul" class="onboarding-steps" space-between>
                    <li
                        :key="stepName"
                        v-for="(stepName, index) in stepNames"
                        class="onboarding-steps-item pa-flex pa-align-center"
                        :class="{
                            active: currentStep === index,
                            finished: currentStep > index,
                        }"
                        :data-step="index"
                        :data-active="currentStep === index ? 'active' : 'inactive'"
                        :data-finished="currentStep > index ? 'finished' : 'inprogress'"
                    >
                        <p-button
                            unstyled
                            no-base-class
                            class="pa-text-grey-800 pa-txt_xs pa-txt_medium pa-py-12"
                            :class="{
                                'pa-clickable': !(currentStep === index || !(index < currentStep)),
                                'pa-cursor-default': (currentStep === index || !(index < currentStep)),
                            }"
                            :disabled="currentStep === index"
                            @click="handleClick(index)"
                        >
                            <p-flex align-center>
                                <div v-if="false" class="onboarding-steps-vertical-line"></div>
                                <div class="onboarding-steps-symbol-wrapper--circle onboarding-steps-symbol-wrapper">
                                    <div class="onboarding-steps-symbol">
                                        <div v-if="currentStep <= index" class="timeline-circle"></div>
                                        <p-icon v-else icon="checkmark-solid" color="green" size="lg"></p-icon>
                                    </div>
                                </div>
                                <div class="onboarding-steps-name pa-ml-8">
                                    <p class="pa-m-0 pa-txt_medium pa-text-grey-800 pa-txt_sm">
                                        {{ stepName }}
                                    </p>
                                </div>
                            </p-flex>
                        </p-button>
                    </li>
                </p-flex>
            </div>
        </div>
        <p-divider class="pa-m-0"></p-divider>
        <slot></slot>
    </p-flex>
</template>

<script>
import Vue from "vue";

const STEP_COMPONENT_NAME = "p-onboarding-step";

export default Vue.extend({
    data() {
        return {
            steps: [],
            currentStep: 0,
        };
    },
    props: {
        stepNames: {
            type: Array,
            default: [],
        },
        disableBackStep: Boolean,
        disableForwardStep: Boolean,
        preserveStepState: Boolean,
    },
    events: {
        "decrement-step"() {
            this.decrementStep();
        },
        "increment-step"() {
            this.incrementStep();
        },
        "change-step"(data) {
            this.changeStep(data);
        },
        "add-step"(item) {
            this.addStep(item);
        },
        "remove-step"(item) {
            this.removeStep(item);
        },
    },
    watch: {
        currentStep(curr, prev) {
            this.handleStepChange(curr, prev);
        },
    },
    computed: {
        isLastStep() {
            return this.currentStep === this.stepCount - 1;
        },
        stepCount() {
            return this.steps.length;
        },
        showSteps() {
            return this.stepNames.length > 1;
        }
    },
    methods: {
        changeStepFromDialog(data) {
            window.app.rootVue.$broadcast("dialog:close", "dynamic_prompt");
            this.changeStep(data);
        },
        handleClick(step) {
            if ((this.currentStep === step || !(step < this.currentStep))) {
                return;
            }

            window.app.rootVue.$broadcast(
                "prompt:load",
                {
                    id: "dynamic_prompt",
                    title: "Go Back",
                    body: "Are you sure you want to go back to this step? Any previously data entered in subsequent steps will be lost.",
                    callback: this.changeStepFromDialog.bind(this, { nextStep: step }),
                }
            )

            window.app.rootVue.$broadcast("dialog:open", "dynamic_prompt");
        },
        clearStepState({ currentStep, previousStep }) {
            this.steps.forEach((stepComponent) => {
                if (stepComponent.step > currentStep) {
                    this.eventHub.$emit("reset-step-state", { step: stepComponent.step });
                }
            });
        },
        handleStepChange(curr, prev) {
            const data = {
                currentStep: curr,
                previousStep: prev,
            };
            const isGoingBack = prev > curr;

            this.$emit("on-step-change", data);

            if (isGoingBack) {
                this.$emit("on-step-back", data);

                if (!this.preserveStepState) {
                    this.clearStepState(data);
                }
            } else {
                this.$emit("on-step-forward", data);
            }

            this.updateChildrensCurrentStep(curr);
        },
        decrementStep() {
            if (!this.disableBackStep) {
                this.currentStep--;
            }
        },
        incrementStep() {
            if (!this.disableForwardStep) {
                this.currentStep++;
            }
        },
        changeStep(data) {
            const isForward = data.nextStep > this.currentStep;
            let canChange = true;

            if (isForward && this.disableForwardStep) {
                canChange = false;
            } else if (!isForward && this.disableBackStep) {
                canChange = false;
            }

            if (canChange) {
                this.currentStep = data.nextStep;
            }
        },
        updateChildrensCurrentStep(currentStep) {
            this.steps.forEach(step => {
                step.currentStep = currentStep;
            });
        },
        getStepVnodes() {
            const vnodes = [];

            this.$slots.default.forEach((vnode) => {
                const isStepTag = vnode.tag && vnode.tag.includes(STEP_COMPONENT_NAME);
                const childIsStepTag = (
                    vnode.componentInstance &&
                    vnode.componentInstance.$children &&
                    vnode.componentInstance.$children[0] &&
                    vnode.componentInstance.$children[0].$vnode.tag.includes(STEP_COMPONENT_NAME)
                );

                if (isStepTag) {
                    vnodes.push(vnode);
                } else if (childIsStepTag) {
                    vnodes.push(vnode.componentInstance.$children[0].$vnode);
                }
            });

            return vnodes;
        },
        removeStep(item) {
            if (!item) {
                return;
            }

            const vnodes = this.getStepVnodes();

            this.steps = this.steps.filter((step) => vnodes.indexOf(step.$vnode) !== -1);

            vnodes.forEach((vnode, index) => {
                vnode.componentInstance.id = `step_${index}`;
                vnode.componentInstance.step = index;
            });

            // Update step nums
            for (let i = 0; i < this.steps.length; i++) {
                this.steps[i].step = i;
            }
        },
        addStep(item) {
            if (!item) {
                return;
            }

            const vnodes = this.getStepVnodes();
            const index = vnodes.indexOf(item.$vnode);

            if (index > -1) {
                item.id = `step_${index}`;
                item.step = index;
                this.addStepAtIndex(index, item);
            }
        },
        addStepAtIndex(index, item) {
            this.steps.splice(index, 0, item);
        },
    },
});
</script>
